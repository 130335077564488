import { ColumnDef } from '@tanstack/react-table';
import ImagePreview from 'components/ImagePreview';
import DescriptionWrapper from 'components/DescriptionWrapper';

function GetColumns(): ColumnDef<OnboardScreen, string>[] {
  return [
    {
      header: 'Id',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Tytuł',
      accessorKey: 'title',
      cell: (info) => info.getValue() || '-',
    },
    {
      header: 'Opis',
      accessorKey: 'description',
      cell: (info) => (
        <DescriptionWrapper>{info.getValue()}</DescriptionWrapper>
      ),
    },
    {
      header: 'Zdjęcie',
      accessorKey: 'image.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
