import { useQuery } from 'react-query';
import { API, APIRoutes, DEFAULT_STALE_TIME, EXTENDED_STALE_TIME } from 'api';
import { OptionType } from 'types/common';

const fetchEvents = async (queryString: string) => {
  const { data: response } = await API.get(APIRoutes.events.index(queryString));
  return {
    data: response?.data,
    totals: response?.meta?.pagination?.objects,
  };
};

const fetchEvent = async (id: number) => {
  const { data } = await API.get(APIRoutes.events.byId(id));
  return data;
};

const fetchEventPathways = async (id: number, queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.events.pathways.index(id, queryString),
  );
  return {
    data: response?.data,
    totals: response?.meta?.pagination?.objects,
  };
};

const fetchEventPathway = async (eventId: number, id: number) => {
  const { data } = await API.get(APIRoutes.events.pathways.byId(eventId, id));
  return data;
};

const fetchAttractionFacilities = async (id: number, queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.events.attractionFacilities.index(id, queryString),
  );
  return {
    data: response?.data,
    totals: response?.meta?.pagination?.objects,
  };
};

const fetchEventAttractionFacility = async (eventId: number, id: number) => {
  const { data } = await API.get(
    APIRoutes.events.attractionFacilities.byId(eventId, id),
  );
  return data;
};

const fetchEventsList = async (queryString: string) => {
  const {
    data: { data: events },
  } = await API.get(APIRoutes.events.index(`?${queryString}`));

  return events?.map((attraction: EventItem) => ({
    label: attraction.name,
    value: attraction.id,
  }));
};

const fetchMobileEventAgendaDays = async (id: number) => {
  const {
    data: { data: days },
  } = await API.get(APIRoutes.events.mobile.agenda.days(id));

  return days?.map((day: AgendaDay) => ({
    label: `${day.weekday} - ${day.date}`,
    value: day.date,
  }));
};

const fetchMobileEventAgenda = async (id: number, queryString: string) => {
  const {
    data: { data },
  } = await API.get(APIRoutes.events.mobile.agenda.index(id, queryString));
  return data;
};

const fetchMobileEvents = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.events.mobile.index());
  return data;
};

const fetchMobileEventPathways = async (id: number) => {
  const {
    data: { data },
  } = await API.get(APIRoutes.events.mobile.pathways(id));
  return data;
};

const useGetEvents = (queryString: string) =>
  useQuery<EventsResponse>(
    ['events', queryString],
    () => fetchEvents(queryString),
    { staleTime: DEFAULT_STALE_TIME },
  );

const useGetEvent = (id: number, enabled = true, isPreview = false) =>
  useQuery<EventResponse>(['event', id, isPreview], () => fetchEvent(+id), {
    enabled,
    staleTime: isPreview ? EXTENDED_STALE_TIME : 0,
  });

const useGetEventPathways = (id: number, queryString: string, enabled = true) =>
  useQuery<PathwaysResponse>(
    ['eventPathways', queryString, id],
    () => fetchEventPathways(+id, queryString),
    {
      enabled,
      staleTime: EXTENDED_STALE_TIME,
    },
  );

const useGetEventPathway = (eventId: number, id: number) =>
  useQuery<PathwayResponse>(['eventPathway', eventId, id], () =>
    fetchEventPathway(+eventId, +id),
  );

const useGetEventAttractionFacilities = (id: number, queryString: string) =>
  useQuery<AttractionFacilitiesResponse>(
    ['eventAttractionFacilities', id, queryString],
    () => fetchAttractionFacilities(+id, queryString),
    { staleTime: EXTENDED_STALE_TIME },
  );

const useGetEventAttractionFacility = (eventId: number, id: number) =>
  useQuery<AttractionFacilityResponse>(
    ['eventAttractionFacility', eventId, id],
    () => fetchEventAttractionFacility(eventId, id),
  );

const useGetEventsList = (queryString: string, enabled = true) =>
  useQuery<OptionType[]>(
    ['eventsList', queryString],
    () => fetchEventsList(queryString),
    {
      staleTime: EXTENDED_STALE_TIME,
      enabled,
    },
  );

const useGetMobileEventAgendaDays = (id: number, enabled: boolean) =>
  useQuery<OptionType[]>(
    ['agendaDays', id],
    () => fetchMobileEventAgendaDays(id),
    {
      staleTime: EXTENDED_STALE_TIME,
      enabled,
    },
  );

const useGetMobileEventAgenda = (
  id: number,
  queryString: string,
  enabled: boolean,
) =>
  useQuery<Attraction[]>(
    ['agenda', id, queryString],
    () => fetchMobileEventAgenda(+id, queryString),
    { staleTime: EXTENDED_STALE_TIME, enabled },
  );

const useGetMobileEvents = (enabled: boolean) =>
  useQuery<EventItem[]>(['mobileEvents'], fetchMobileEvents, {
    enabled,
    staleTime: DEFAULT_STALE_TIME,
  });

const useGetMobileEventPathways = (id: number, enabled = true) =>
  useQuery<Pathway[]>(
    ['mobileEventPathways', id],
    () => fetchMobileEventPathways(+id),
    {
      enabled,
      staleTime: DEFAULT_STALE_TIME,
    },
  );

export {
  useGetEvents,
  useGetEvent,
  useGetEventPathways,
  useGetEventPathway,
  useGetEventAttractionFacilities,
  useGetEventAttractionFacility,
  useGetMobileEvents,
  useGetEventsList,
  useGetMobileEventAgendaDays,
  useGetMobileEventAgenda,
  useGetMobileEventPathways,
};
