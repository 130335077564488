/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { Box, Flex, FormControl, HStack, Stack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { debounce } from 'lodash';
import Card from 'components/Card';
import DropzoneField from 'components/Form/Dropzone';
import Input from 'components/Form/Input';
import { SectionTextBox, SectionTitle } from 'components/Form/SectionText';
import Textarea from 'components/Form/Textarea';
import DateInput from 'components/Form/Date';
import LoadingSpinner from 'components/FormSpinner';
import { FormBodyProps } from 'types/common';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import TicketsSection from 'components/Content/Sections/TicketsSection';
import HoursKindSection from 'components/Schedule/HoursKindSection';
import VenuesSelect from 'components/Select/VenuesSelect';
import { ATTRACTION_KIND } from 'pages/Attractions/Form/constants';
import VenueAttractionsSelect from 'components/Select/VenueAttractionsSelect';
import Editor from 'components/Form/Editor';

function FormBody({ isLoading }: FormBodyProps) {
  const [venues, setVenues] = useState<number[]>([]);

  const { control } = useFormContext();
  const venueIds = useWatch({
    control,
    name: 'venueIds',
  });

  const updateVenueIds = useCallback(
    debounce((ids) => {
      setVenues(ids);
    }, 700),
    [],
  );

  useEffect(() => {
    updateVenueIds(venueIds);
  }, [venueIds]);

  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <LoadingSpinner />}
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <Stack spacing={8}>
            <FormControl as="fieldset">
              <SectionTitle as="legend">Zdjęcie</SectionTitle>
              <DropzoneField
                name="imagesAttributes[0].image"
                title="Dodaj zdjęcie w formacie .png, .jpg"
              />
            </FormControl>
            <SectionTextBox title="Informacje" />
            <Input
              label="Nazwa imprezy"
              placeholder="Wpisz nazwę"
              name="name"
            />
            <FormControl as="fieldset">
              <SectionTitle as="legend">Data wydarzenia</SectionTitle>
              <HStack spacing={2} align="start">
                <DateInput name="dateFrom" label="Data od" showError />
                <DateInput name="dateTo" label="Data do" showError />
              </HStack>
            </FormControl>
            <VenuesSelect name="venueIds" label="Obiekt powiązany" isMulti />
            <Editor name="description" label="Opis" placeholder="Wpisz opis" />
            <Textarea
              name="shortDescription"
              label="Krótki opis imprezy"
              placeholder="Wpisz opis"
            />
            <TicketsSection prepareFieldName={(name: string) => name} />
            <HoursKindSection prepareFieldName={(name: string) => name} />
            <Textarea
              name="pathwaysDescription"
              label="Opis ścieżki zwiedzania (opcjonalnie)"
              placeholder="Wpisz opis"
            />
            <SectionTextBox
              title="Atrakcje w ramach imprezy"
              subtitle="Przełącz zakładki w podglądzie, aby zweryfikować"
            />
            <VenueAttractionsSelect
              label="Wybierz atrakcje w ramach imprezy"
              placeholder="Wybierz atrakcje"
              name="attractionFacilitiesAttributes"
              venueIds={venues}
              kind={ATTRACTION_KIND.CONSTANT}
              isMulti
            />
          </Stack>
        </Box>
      </Card>
    </Flex>
  );
}

export default FormBody;
