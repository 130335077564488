const AUTH_PREFIX = 'ec1-auth';

const CLIENT_KEY = `${AUTH_PREFIX}_client`;
export const TOKEN_KEY = `${AUTH_PREFIX}_token`;
export const UID_KEY = 'ec1-cms-uid';
export const TOKEN_TYPE_KEY = 'ec1-cms-token-type';
export const LANGUAGE_KEY = 'ec1-cms-language';

export const setCredentials = ({
  client,
  accessToken,
  tokenType,
  uid,
  acceptLanguage,
}: Credentials) => {
  localStorage.setItem(CLIENT_KEY, client);
  localStorage.setItem(TOKEN_KEY, accessToken);
  localStorage.setItem(TOKEN_TYPE_KEY, tokenType);
  localStorage.setItem(UID_KEY, uid);
  localStorage.setItem(LANGUAGE_KEY, acceptLanguage);
};

export const getCredentials = () => {
  const client = localStorage.getItem(CLIENT_KEY) || '';
  const accessToken = localStorage.getItem(TOKEN_KEY) || '';
  const tokenType = localStorage.getItem(TOKEN_TYPE_KEY) || '';
  const uid = localStorage.getItem(UID_KEY) || '';
  const acceptLanguage = localStorage.getItem(LANGUAGE_KEY) || '';
  return {
    client,
    accessToken,
    tokenType,
    uid,
    acceptLanguage,
  };
};
