import {
  FormControl,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  InputLeftElement,
} from '@chakra-ui/react';
import { PatternFormat } from 'react-number-format';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';

type MaskedInputProps = ChakraInputProps & {
  name: string;
  label?: string | React.ReactNode;
  defaultValue?: number | string;
  rightElement?: React.ReactNode;
  leftElement?: React.ReactNode;
  type?: 'text' | 'password' | 'tel';
  format: string;
};

function MaskedInput({
  label,
  name,
  isRequired,
  rightElement,
  leftElement,
  defaultValue,
  type,
  isDisabled,
  format,
  value,
  ...rest
}: MaskedInputProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={Boolean(errorMessage)}
      isDisabled={isDisabled}
    >
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup>
        {leftElement && (
          <InputLeftElement pointerEvents="none">
            {leftElement}
          </InputLeftElement>
        )}

        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field: { ref, ...props } }) => (
            <PatternFormat
              getInputRef={ref}
              customInput={ChakraInput}
              type={type}
              paddingLeft={leftElement ? '40px' : '-moz-initial'}
              paddingRight={rightElement ? '40px' : 0}
              format={format}
              {...rest}
              {...props}
            />
          )}
        />
        {rightElement && (
          <InputRightElement pointerEvents="none">
            {rightElement}
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

MaskedInput.defaultProps = {
  label: '',
  type: 'text',
  defaultValue: null,
  rightElement: null,
  leftElement: null,
};

export default MaskedInput;
