/* eslint-disable react-hooks/exhaustive-deps, react/no-array-index-key */
import { Box, VStack, Text, TabPanel } from '@chakra-ui/react';
import { debounce, isEmpty, isNumber } from 'lodash';
import qs from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetMobileEventPathways } from 'api/events';
import PathwayCard from 'components/Cards/PathwayCard';
import LoadingSpinner from 'components/FormSpinner';
import { useGetAttractions } from 'api/attractions';
import AttractionsCarousel from 'components/Previews/AttractionsCarousel';
import EmptySection from 'components/EmptySection';

interface DetailsTabProps {
  eventId: number;
  formValues: EventItem;
  isActive: boolean;
}

function DetailsTab({ eventId, formValues, isActive }: DetailsTabProps) {
  const [attractionIds, setAttractionIds] = useState<number[]>([]);

  const updateVenueIds = useCallback(
    debounce((ids) => {
      setAttractionIds(ids);
    }, 700),
    [],
  );

  useEffect(() => {
    updateVenueIds(formValues?.attractionFacilitiesAttributes);
  }, [formValues?.attractionFacilitiesAttributes]);

  const { isFetching: isFetchingPathways, data: pathways = [] } =
    useGetMobileEventPathways(eventId, isNumber(eventId) && isActive);

  const attractionsQueryString = useMemo(
    () =>
      qs.stringify(
        {
          page_size: 'all',
          'q[id_in]': attractionIds,
        },
        { arrayFormat: 'bracket' },
      ),
    [attractionIds],
  );

  const {
    isFetching: isFetchingAttractions,
    data: { data: attractions } = { data: [] },
  } = useGetAttractions(
    `?${attractionsQueryString}`,
    !isEmpty(attractionIds) && isActive,
  );

  return (
    <TabPanel>
      {(isFetchingPathways || isFetchingAttractions) && <LoadingSpinner />}
      <VStack spacing={8}>
        <Box w="100%">
          <AttractionsCarousel label="ATRAKCJE" attractions={attractions} />
        </Box>
        <Box w="100%">
          <Text w="100%" variant="h2" mb={4}>
            WYBIERZ ŚCIEŻKĘ ZWIEDZANIA
          </Text>
          <Text fontSize="17px" lineHeight="24px" w="100%" mb={6}>
            {formValues?.pathwaysDescription || 'Tu pojawi się opis'}
          </Text>
          {isEmpty(pathways) ? (
            <EmptySection text="Aby dodać ścieżki zwiedzania, skorzystaj z menu funkcjonalnego, które jest wyświetlone w ostatniej kolumnie tabeli “Imprezy”" />
          ) : (
            <VStack w="100%" spacing={4}>
              {pathways?.map((pathway, index) => (
                <Box
                  w="100%"
                  border="solid 1px"
                  borderRadius="8px"
                  key={`pathway_card_${index}`}
                >
                  <PathwayCard
                    name={pathway?.name}
                    attractions={Number(pathway?.pathwayPoints?.length)}
                    duration={Number(pathway?.duration)}
                  />
                </Box>
              ))}
            </VStack>
          )}
        </Box>
      </VStack>
    </TabPanel>
  );
}

export default DetailsTab;
