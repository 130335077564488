import {
  FormControl,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';

type TextareaProps = ChakraTextareaProps & {
  name: string;
  label?: string | React.ReactNode;
  defaultValue?: number | string;
};

function Textarea({
  label,
  name,
  isRequired,
  defaultValue,
  isDisabled,
  ...rest
}: TextareaProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={Boolean(errorMessage)}
      isDisabled={isDisabled}
    >
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <ChakraTextarea
        {...register(name)}
        defaultValue={defaultValue}
        {...rest}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

Textarea.defaultProps = {
  label: '',
  defaultValue: null,
};

export default Textarea;
