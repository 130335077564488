import * as yup from 'yup';
import { ERROR_MESSAGES } from 'utils/form';

export const BODY_SCHEMA = yup.object({
  email: yup
    .string()
    .trim()
    .email(ERROR_MESSAGES.invalidEmail)
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  firstName: yup
    .string()
    .trim()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  lastName: yup
    .string()
    .trim()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
});

export const CREATE_SCHEMA = yup
  .object({
    password: yup
      .string()
      .trim()
      .required(ERROR_MESSAGES.required)
      .validatePassword()
      .validateMaxStringLength(),
    passwordConfirmation: yup
      .string()
      .trim()
      .required(ERROR_MESSAGES.required)
      .oneOf([yup.ref('password')], ERROR_MESSAGES.invalidPasswordConfirmation)
      .validateMaxStringLength(),
  })
  .concat(BODY_SCHEMA);

export const EDIT_SCHEMA = yup
  .object({
    password: yup
      .string()
      .trim()
      .validatePassword()
      .validateMaxStringLength()
      .nullable()
      .transform((value) => value || null),
    passwordConfirmation: yup
      .string()
      .trim()
      .validateMaxStringLength()
      .nullable()
      .oneOf([yup.ref('password')], ERROR_MESSAGES.invalidPasswordConfirmation)
      .when('password', {
        is: (password: string) => password?.length > 0,
        then: (schema) => schema.required(ERROR_MESSAGES.required),
      })
      .transform((value) => value || null),
  })
  .concat(BODY_SCHEMA);

export const DEFAULT_VALUES = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  passwordConfirmation: '',
};
