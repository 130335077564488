import { IconButton } from '@chakra-ui/react';
import { IconChevronLeft } from '@tabler/icons-react';
import SwiperCore from 'swiper';

interface SliderNavigationProps {
  sliderRef: SwiperCore | undefined;
}

function SliderNavigation({ sliderRef }: SliderNavigationProps) {
  return (
    <>
      <IconButton
        icon={<IconChevronLeft />}
        onClick={() => sliderRef?.slidePrev()}
        variant="ghost"
        aria-label="Previous slide"
      />
      <IconButton
        icon={<IconChevronLeft />}
        onClick={() => sliderRef?.slideNext()}
        variant="ghost"
        transform="rotate(180deg)"
        aria-label="Next slide"
      />
    </>
  );
}

export default SliderNavigation;
