/* eslint-disable react/no-array-index-key */
import 'swiper/css';
import { Box, VStack, Text, Center, HStack } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { IconCalendarEvent } from '@tabler/icons-react';
import { useGetMobileVenues } from 'api/venues';
import VenueSlide from 'components/Content/Previews/CarouselPreview/VenueSlide';
import { useGetMobileEvents } from 'api/events';
import EventSlide from 'components/Content/Previews/CarouselPreview/EventSlide';
import LoadingSpinner from 'components/FormSpinner';
import { PreviewComponentProps } from 'types/common';

function CarouselPreview({
  formValues,
  isDashboardPreview,
  isEditMode,
}: PreviewComponentProps) {
  const linkParameters =
    formValues?.linkParametersAttributes || formValues?.linkParameters;
  const linkType = linkParameters?.[0]?.linkType || '';
  const showEvents = linkType.includes('events');
  const showVenues = linkType.includes('venues');

  const { isFetching: isFetchingIncomingEvents, data: events = [] } =
    useGetMobileEvents(showEvents);
  const { isFetching: isFetchingVenues, data: venues = [] } =
    useGetMobileVenues(showVenues);

  const slides = useMemo(() => {
    if (showEvents) {
      return events;
    }
    if (showVenues) {
      return venues;
    }
    return [];
  }, [showEvents, showVenues, events, venues]);

  return (
    <>
      {(isFetchingIncomingEvents || isFetchingVenues) && <LoadingSpinner />}
      {isEmpty(slides) && isDashboardPreview ? null : (
        <VStack spacing={4} w="100%">
          {(isDashboardPreview || isEditMode) && !formValues?.title ? null : (
            <Text variant="h2" w="100%">
              {formValues.title ||
                'Tu pojawi się nagłówek karuzeli (opcjonalnie)'}
            </Text>
          )}
          <Box w="100%">
            {!isEmpty(slides) && (
              <Swiper slidesPerView={1.1} spaceBetween={16}>
                {slides?.map((field: any, index) => {
                  const Slide = showEvents ? EventSlide : VenueSlide;
                  return (
                    <SwiperSlide
                      key={`image_${index}`}
                      style={{
                        height: 'auto',
                      }}
                    >
                      <Slide element={field} />
                    </SwiperSlide>
                  );
                })}
                {showEvents && (
                  <SwiperSlide
                    style={{
                      height: 'auto',
                    }}
                  >
                    <Center h="100%" border="solid 1px" borderRadius="8px">
                      <HStack spacing={2} align="start">
                        <IconCalendarEvent />
                        <Text w="100%" fontSize="17px" lineHeight="24px">
                          ZOBACZ WSZYSTKIE IMPREZY
                        </Text>
                      </HStack>
                    </Center>
                  </SwiperSlide>
                )}
              </Swiper>
            )}
          </Box>
        </VStack>
      )}
    </>
  );
}

export default CarouselPreview;
