import { HStack, Text } from '@chakra-ui/react';
import { IconAlertTriangle } from '@tabler/icons-react';

type WarningBadgeProps = {
  label: string;
};

function WarningBadge({ label }: WarningBadgeProps) {
  return (
    <HStack
      w="100%"
      bgColor="complementary.warning"
      border="solid 1px"
      px={4}
      py={2}
      borderRadius="8px"
    >
      <IconAlertTriangle size={24} />
      <Text w="100%" fontSize={13} lineHeight="16px">
        {label}
      </Text>
    </HStack>
  );
}

export default WarningBadge;
