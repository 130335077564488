/* eslint-disable react/no-array-index-key */
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { serialize } from 'object-to-formdata';
import HeaderNavigation from 'components/HeaderNavigation';
import { FORM_MAX_WIDTH } from 'utils/constants';
import FooterButtons, { FOOTER_HEIGHT } from 'components/FooterButtons';
import FormBody from 'pages/Venues/Form/components/FormBody';
import { BODY_SCHEMA, DEFAULT_VALUES } from 'pages/Venues/Form/constants';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';
import transformErrors from 'utils/api';
import LoadingIndicator from 'components/LoadingIndicator';
import parseDefaultValues from 'pages/Venues/Form/Edit/parseDefaultValues';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';
import ComponentPreview from 'components/PreviewWrapper';
import { prepareSchedulesAttributes } from 'components/Schedule/constants';
import { useGetVenue } from 'api/venues';
import FormWrapper from 'components/FormWrapper';

function VenueEdit() {
  const { id = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const methods = useForm<Venue>({
    mode: 'onChange',
    resolver: yupResolver(BODY_SCHEMA),
    defaultValues: DEFAULT_VALUES,
  });

  const { handleSubmit, setError, reset } = methods;

  const { data: { data: venue } = {}, isFetching } = useGetVenue(+id);

  const { mutate: editVenue, isLoading: isEditing } = useMutation({
    mutationFn: (data: Venue) => {
      const snakePayload = convertKeysToSnakeCase(
        prepareSchedulesAttributes(data),
      );
      const formData = serialize(
        snakePayload,
        SERIALIZER_OPTIONS,
        new FormData(),
        'venue',
      );
      return API.put(APIRoutes.venues.byId(+id!), formData);
    },
    mutationKey: 'venuesMutation',
    onSuccess: () => {
      toast.success('Zmiany w obiekcie zostały zastosowane');
      navigate(ROUTES.venues.base);
      queryClient.invalidateQueries('venues');
    },
    onError: ({ errors }) => {
      const transformedErrors = transformErrors(errors, null);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      toast.error('Wystąpił problem podczas edycji obiektu');
    },
  });

  const onSubmit = handleSubmit(async (data: Venue) => editVenue(data));

  useEffect(() => {
    if (venue) reset({ ...DEFAULT_VALUES, ...parseDefaultValues(venue) });
  }, [venue, reset]);

  const isLoading = isFetching || isEditing;

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: 'Obiekty',
          to: ROUTES.venues.base,
        }}
        crumbs={[{ to: '', label: 'Edytuj obiekt' }]}
      />
      {isFetching && <LoadingIndicator />}
      <FormWrapper {...methods}>
        <Flex gap={4} mb={4} alignItems="flex-start" pb={FOOTER_HEIGHT}>
          <Box
            w="100%"
            as="form"
            id="venue-editor"
            onSubmit={onSubmit}
            maxW={FORM_MAX_WIDTH}
          >
            <FormBody isLoading={isLoading} />
          </Box>
          <ComponentPreview kind="venue" isEditMode />
        </Flex>
      </FormWrapper>
      <FooterButtons
        isLoading={isLoading}
        formId="venue-editor"
        onCancel={() => navigate(-2)}
      />
    </Box>
  );
}

export default VenueEdit;
