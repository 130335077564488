import { Box, Flex, Stack } from '@chakra-ui/react';
import Card from 'components/Card';
import LoadingSpinner from 'components/FormSpinner';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import { PAGE_KIND_OPTIONS } from 'pages/ContentPages/Form/constants';
import { FormBodyProps } from 'types/common';

function FormBody({ isLoading }: FormBodyProps) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <LoadingSpinner />}
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <Stack spacing={8}>
            <Input name="name" label="Nazwa" placeholder="Wpisz nazwę" />
            <Select
              label="Typ strony"
              name="kind"
              options={PAGE_KIND_OPTIONS}
            />
          </Stack>
        </Box>
      </Card>
    </Flex>
  );
}

export default FormBody;
