const ROUTES = {
  auth: {
    login: '/login',
    reset: '/reset-password',
    change: '/change-password',
  },
  dashboard: {
    base: '/dashboard',
    edit: '/dashboard/:dashboardId?/edit',
    containers: {
      create: '/dashboard/:dashboardId?/containers/create',
      edit: '/dashboard/:dashboardId?/containers/:id/edit',
    },
  },
  users: {
    base: '/users',
    create: '/users/create',
    edit: '/users/:id/edit',
  },
  splashScreens: {
    base: '/splash-screens',
    create: '/splash-screens/create',
    edit: '/splash-screens/:id/edit',
  },
  onboardScreens: {
    base: '/onboard-screens',
    create: '/onboard-screens/create',
    edit: '/onboard-screens/:id/edit',
  },
  venues: {
    base: '/venues',
    create: '/venues/create',
    edit: '/venues/:id/edit',
    pathways: {
      base: '/venues/:id/pathways',
      create: '/venues/:id/pathways/create',
      edit: '/venues/:venueId/pathways/:id/edit',
    },
    plans: {
      base: '/venues/:id/plans',
      create: '/venues/:id/plans/create',
      edit: '/venues/:venueId/plans/:id/edit',
    },
  },
  news: {
    base: '/news',
    create: '/news/create',
    edit: '/news/:id/edit',
  },
  attractions: {
    base: '/attractions',
    create: '/attractions/create',
    edit: '/attractions/:id/edit',
  },
  pathways: {
    base: '/pathways',
    create: '/pathways/create',
    edit: '/pathways/:id/edit',
  },
  events: {
    base: '/events',
    create: '/events/create',
    edit: '/events/:id/edit',
    pathways: {
      base: '/events/:id/pathways',
      create: '/events/:id/pathways/create',
      edit: '/events/:eventId/pathways/:id/edit',
    },
    attractionFacilities: {
      base: '/events/:id/attraction-facilities',
      create: '/events/:id/attraction-facilities/create',
      edit: '/events/:eventId/attraction-facilities/:id/edit',
    },
  },
  contentPages: {
    base: '/content-pages',
    create: '/content-pages/create',
    edit: '/content-pages/:id/edit',
  },
};

export default ROUTES;
