import * as yup from 'yup';
import { CONTENT_CONTAINER_SCHEMA } from 'components/Content/constants';
import { ContentContainer } from 'types/contentComponent';

export const BODY_SCHEMA = yup.object({
  contentContainersAttributes: yup.array().of(CONTENT_CONTAINER_SCHEMA),
});

export const DEFAULT_VALUES = {
  kind: '',
};

export const prepareDashboardContainer = (container: ContentContainer) => {
  const data = { ...container };

  const imagesAttributes = container?.imagesAttributes?.[0];
  if (container?.kind === 'container' && imagesAttributes) {
    if (imagesAttributes?.image) {
      imagesAttributes.position = 0;
    } else {
      delete data?.imagesAttributes;
    }
  }

  return data;
};
