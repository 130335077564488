import { useQuery } from 'react-query';
import { API, APIRoutes, DEFAULT_STALE_TIME } from 'api';

const fetchNews = async (queryString: string) => {
  const { data: response } = await API.get(APIRoutes.news.index(queryString));
  return {
    data: response?.data,
    totals: response?.meta?.pagination?.objects,
  };
};

const fetchNewsItem = async (id: number) => {
  const { data } = await API.get(APIRoutes.news.byId(id));
  return data;
};

const useGetNews = (queryString: string) =>
  useQuery<NewsResponse>(['news', queryString], () => fetchNews(queryString), {
    staleTime: DEFAULT_STALE_TIME,
  });

const useGetNewsItem = (id: number) =>
  useQuery<NewsItemResponse>(['newsItem', id], () => fetchNewsItem(id));

export { useGetNews, useGetNewsItem };
