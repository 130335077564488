import omit from 'lodash/omit';
import { filter, map, pick } from 'lodash';
import { parseSchedulesAttributes } from 'components/Schedule/constants';
import { ATTRACTION_KIND } from 'pages/Attractions/Form/constants';

const PARSED_VALUES: string[] = ['images', 'schedules', 'venues'];

const parseDefaultValues = (event: EventItem) => {
  const result: Record<string, any> = { ...omit(event, PARSED_VALUES) };

  result.imagesAttributes = event?.images;
  result.schedulesAttributes = event?.schedules;
  result.dateFrom = event?.dateFrom ? new Date(event?.dateFrom) : null;
  result.dateTo = event?.dateTo ? new Date(event?.dateTo) : null;
  result.venueIds = event.venues.map((venue) => venue.id);

  const constantAttractionFacilities = filter(event?.attractionFacilities, {
    attraction: { kind: ATTRACTION_KIND.CONSTANT },
  });

  result.attractionFacilities = map(
    constantAttractionFacilities,
    (attracitonFacility) =>
      pick(attracitonFacility, ['id', 'position', 'attractionId']),
  );

  result.attractionFacilitiesAttributes = map(
    result.attractionFacilities,
    'attractionId',
  );

  return parseSchedulesAttributes(result);
};

export default parseDefaultValues;
