import { Text } from '@chakra-ui/react';
import { IconArrowLeft } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

interface BackButtonProps {
  backPath: string;
}

function BackButton({ backPath }: BackButtonProps) {
  return (
    <Text as={Link} to={backPath} variant="navigationHistory" mb={2}>
      <IconArrowLeft />
      Powrót
    </Text>
  );
}

export default BackButton;
