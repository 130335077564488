/* eslint-disable func-names */
import * as yup from 'yup';

const LIMITS = {
  minPasswordLength: 7,
  maxStringLength: 255,
};

const ERROR_MESSAGES = {
  required: 'Pole jest wymagane',
  minPasswordLength: `Hasło musi zawierać przynajmniej ${LIMITS.minPasswordLength} znaków`,
  invalidLink: 'Link musi rozpoczynać się od "https://"',
  invalidUrl: 'Wprowadzony link jest nieprawidłowy',
  invalidPassword:
    'Hasło musi zawierać przynajmniej jedną wielką literę (A-Z), małą literę (a-z), cyfrę (0-9) oraz znak specjalny (@$!%*?&)',
  invalidPasswordConfirmation: 'Hasła nie są takie same. Spróbuj ponownie',
  maxStringLength: `Maksymalna długość tekstu to ${LIMITS.maxStringLength} znaków`,
  invalidFormat: 'Nieprawidłowy format',
  dateToLaterThanFrom: 'Data końcowa musi być późniejsza od początkowej',
  invalidEmail: 'Niepoprawny format adresu email',
  minAttractionsLimit: 'Musisz wybrać przynajmniej jedną atrakcję',
  invalidNumberType: 'Wprowadź liczbę',
};

const LINK_PARAMETERS_SCHEMA = {
  linkType: yup.string().nullable().required(ERROR_MESSAGES.required),
  linkParamValue: yup
    .string()
    .trim()
    .nullable()
    .when('linkType', {
      is: (linkType: string) => linkType?.includes('param_value'),
      then: (schema) =>
        schema
          .required(ERROR_MESSAGES.required)
          .validateMaxStringLength()
          .when('linkType', {
            is: (linkType: string) => linkType?.includes('external_link'),
            then: (typeSchema) => typeSchema.validateLink(),
          }),
      otherwise: () => yup.string().nullable().notRequired(),
    }),
};

yup.addMethod<yup.StringSchema>(
  yup.string,
  'validateMaxStringLength',
  function () {
    return this.max(LIMITS.maxStringLength, ERROR_MESSAGES.maxStringLength);
  },
);

yup.addMethod<yup.StringSchema>(yup.string, 'validateLink', function () {
  return this.matches(/https:\/\//, ERROR_MESSAGES.invalidLink).url(
    ERROR_MESSAGES.invalidLink,
  );
});

yup.addMethod<yup.StringSchema>(yup.string, 'validatePassword', function () {
  return this.min(
    LIMITS.minPasswordLength,
    ERROR_MESSAGES.minPasswordLength,
  ).matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&].*$/,
    ERROR_MESSAGES.invalidPassword,
  );
});

export { LIMITS, ERROR_MESSAGES, LINK_PARAMETERS_SCHEMA };
