import type { DeepPartial, Theme } from '@chakra-ui/react';

const extendedColors = {
  complementary: {
    primary: '#ffd540',
    error: '#f02b02',
    success: '#49d16f',
    black: '#0d0d0d',
    lightBlack: '#191919',
    gray: '#e5e5e5',
    lightGray: '#f2f2f2',
    disabled: '#b2b2b2',
    previewGray: '#808080',
    warning: '#FFC700',
  },
};

const overridenChakraColors: DeepPartial<Theme['colors']> = {};

export default {
  ...overridenChakraColors,
  ...extendedColors,
};
