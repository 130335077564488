/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import qs from 'query-string';
import { API, APIRoutes, EXTENDED_STALE_TIME } from 'api';
import { LinkType } from 'types/common';

const fetchLinkTypes = async () => {
  const queryString = qs.stringify({
    placement: 'dashboard',
  });
  const {
    data: { data: linkTypes },
  } = await API.get(APIRoutes.linkTypes(`?${queryString}`));
  return linkTypes;
};

const useGetLinkTypes = () =>
  useQuery<LinkType[]>(['linkTypes'], () => fetchLinkTypes(), {
    staleTime: EXTENDED_STALE_TIME,
  });

export { useGetLinkTypes };
