import Editor from 'components/Form/Editor';

interface DescriptionSectionProps {
  prepareFieldName: (name: string) => string;
}

function DescriptionSection({ prepareFieldName }: DescriptionSectionProps) {
  return (
    <Editor
      name={prepareFieldName('content')}
      label="Pole opis"
      placeholder="Wpisz swój opis"
    />
  );
}

export default DescriptionSection;
