/* eslint-disable react/no-array-index-key */
import { Box, VStack, Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import Previews from 'components/Content/Previews/constants';
import { PreviewComponentProps } from 'types/common';

function ContentPagePreview({ formValues }: PreviewComponentProps) {
  return (
    <Box padding="24px 16px 32px 16px">
      <Text variant="h2" w="100%" mb={8}>
        {formValues?.name || 'tu pojawi się nazwa'}
      </Text>
      <VStack spacing={2}>
        {formValues?.contentContainersAttributes?.map(
          (container: any, index: number) => {
            const PreviewComponent: FunctionComponent<any> =
              Previews[container.kind];
            if (PreviewComponent) {
              return (
                <PreviewComponent
                  formValues={container}
                  key={`$container_${index}`}
                />
              );
            }
            return null;
          },
        )}
      </VStack>
    </Box>
  );
}

export default ContentPagePreview;
