/* eslint-disable react/no-array-index-key */
import 'swiper/css';
import 'swiper/css/effect-fade';
import { useMemo, useState } from 'react';
import SwiperCore, { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Box,
  TabPanel,
  AspectRatio,
  IconButton,
  VStack,
  HStack,
  Text,
} from '@chakra-ui/react';
import { isEmpty, isNumber } from 'lodash';
import qs from 'query-string';
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import LoadingSpinner from 'components/FormSpinner';
import { useGetVenuePlans } from 'api/venues';
import useImage from 'utils/useImage';
import { SlideProps } from 'types/previews';
import EmptySection from 'components/EmptySection';

interface PlansTabProps {
  venueId: number;
  isActive: boolean;
}

function PlanSlide({ element }: SlideProps<Plan>) {
  const imagePreview = useImage(element?.plan);

  return (
    <Box
      w="100%"
      h="100%"
      bgColor="complementary.whiteGrey"
      bgImage={imagePreview}
      bgSize="cover"
      bgRepeat="no-repeat"
      bgPosition="50% 50%"
      position="relative"
    />
  );
}

function PlansTab({ venueId, isActive }: PlansTabProps) {
  const [swiperRef, setSwiperRef] = useState<SwiperCore>();
  const [activeIndex, setActiveIndex] = useState(0);
  const queryString = qs.stringify({
    page_size: 'all',
  });
  const isQueryEnabled = isNumber(venueId) && isActive;

  const { isFetching: isFetchingPlans, data: { data: plans } = { data: [] } } =
    useGetVenuePlans(venueId, `?${queryString}`, isQueryEnabled);

  const currentFloor = useMemo(
    () => plans?.[activeIndex]?.position,
    [activeIndex, plans],
  );

  return (
    <TabPanel>
      {isFetchingPlans && <LoadingSpinner />}
      {isEmpty(plans) ? (
        <EmptySection text="Aby dodać plany skorzystaj z menu funkcjonalnego, które jest wyświetlone w ostatniej kolumnie tabeli “Obiekty”" />
      ) : (
        <VStack w="100%" spacing={2}>
          <AspectRatio w="100%" ratio={1}>
            <Swiper
              direction="vertical"
              onSwiper={setSwiperRef}
              modules={[EffectFade]}
              effect="fade"
              onRealIndexChange={(slide) => {
                setActiveIndex(slide.realIndex);
              }}
            >
              {plans?.map((plan, index) => (
                <SwiperSlide key={`image_${index}`}>
                  <PlanSlide element={plan} />
                </SwiperSlide>
              ))}
            </Swiper>
          </AspectRatio>
          <Box w="100%">
            <HStack spacing={2} justify="space-between" align="end">
              <Box>
                <Text fontSize="17px" fontWeight={600} lineHeight="24px" mb={2}>
                  Wybierz piętro
                </Text>
                <Text variant="h2">Piętro {currentFloor}</Text>
              </Box>
              <HStack spacing={4}>
                <IconButton
                  icon={<IconChevronUp />}
                  onClick={() => swiperRef?.slideNext()}
                  variant="outlined"
                  aria-label="Next slide"
                />
                <IconButton
                  icon={<IconChevronDown />}
                  onClick={() => swiperRef?.slidePrev()}
                  variant="outlined"
                  aria-label="Previous slide"
                />
              </HStack>
            </HStack>
          </Box>
        </VStack>
      )}
    </TabPanel>
  );
}

export default PlansTab;
