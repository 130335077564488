/* eslint-disable react/no-array-index-key */
import { VStack, Text } from '@chakra-ui/react';
import { DAYS } from 'components/Schedule/ScheduleFieldset';

type SchedulePreviewProps = {
  days: Schedule[];
};

function SchedulePreview({ days }: SchedulePreviewProps) {
  return (
    <VStack spacing={1} align="left">
      {days?.map((day, index: number) => (
        <Text key={`${day}_${index}`}>
          <Text as="span" fontWeight={600}>
            {DAYS[index]}
          </Text>{' '}
          {day.closed ? 'nieczynne' : day.hours}
        </Text>
      ))}
    </VStack>
  );
}

export default SchedulePreview;
