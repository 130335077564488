import { Stack, FormControl } from '@chakra-ui/react';
import Input from 'components/Form/Input';
import DropzoneField from 'components/Form/Dropzone';
import { SectionTextBox, SectionTitle } from 'components/Form/SectionText';
import Textarea from 'components/Form/Textarea';
import LinkCreator from 'components/LinkCreator';

interface ContainerSectionProps {
  prepareFieldName: (name: string) => string;
}

function ContainerSection({ prepareFieldName }: ContainerSectionProps) {
  return (
    <Stack spacing={8}>
      <Input
        name={prepareFieldName('title')}
        label="Nagłówek kontenera"
        placeholder="Wpisz nagłówek"
      />
      <SectionTextBox title="Treść kontenera" />
      <FormControl as="fieldset">
        <SectionTitle as="legend">Zdjęcie</SectionTitle>
        <DropzoneField
          name={prepareFieldName('imagesAttributes[0].image')}
          title="Dodaj zdjęcie w formacie .png, .jpg"
        />
      </FormControl>
      <Input
        name={prepareFieldName('header')}
        label="Nagłówek"
        placeholder="Wpisz nagłówek"
      />
      <Textarea
        name={prepareFieldName('content')}
        label="Opis"
        placeholder="Wpisz opis"
      />
      <Input
        name={prepareFieldName('buttonText')}
        label="Nazwa przycisku"
        placeholder="Wpisz nazwę"
      />
      <LinkCreator prepareFieldName={prepareFieldName} component="container" />
    </Stack>
  );
}

export default ContainerSection;
