import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

const StyledVideo = styled.video`
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: auto;
  object-fit: cover;
`;

interface VideoPlayerProps {
  src: any;
}

function VideoPlayer({ src }: VideoPlayerProps) {
  const [videoPreview, setVideoPreview] = useState<string | undefined>();

  useEffect(() => {
    if (!src || (!src?.url && !src?.path)) {
      setVideoPreview(undefined);
      return;
    }

    if (!src.url) {
      setVideoPreview(URL.createObjectURL(src));
      return;
    }

    setVideoPreview(src.url);
  }, [src]);

  return <StyledVideo src={videoPreview} loop autoPlay controls />;
}

export default VideoPlayer;
