import { Box, Flex, FormControl, HStack, Stack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import Card from 'components/Card';
import Textarea from 'components/Form/Textarea';
import DropzoneField from 'components/Form/Dropzone';
import LoadingSpinner from 'components/FormSpinner';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { SectionTitle } from 'components/Form/SectionText';
import Input from 'components/Form/Input';
import DateInput from 'components/Form/Date';
import { FormBodyProps } from 'types/common';
import VenuesSelect from 'components/Select/VenuesSelect';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import VenueAttractionsSelect from 'components/Select/VenueAttractionsSelect';
import { NEWSABLE_TYPE } from 'pages/News/Form/constants';
import VenueEventsSelect from 'components/Select/VenueEventsSelect';
import ExternalError from 'components/Form/ExternalError';
import { ATTRACTION_KIND } from 'pages/Attractions/Form/constants';
import Editor from 'components/Form/Editor';

const NEWSABLE_TYPE_OPTIONS = [
  {
    label: 'Impreza',
    value: NEWSABLE_TYPE.EVENT,
  },
  {
    label: 'Atrakcja',
    value: NEWSABLE_TYPE.ATTRACTION,
  },
];

function FormBody({ isLoading }: FormBodyProps) {
  const { control, setValue } = useFormContext();
  const [venueId, newsableType] = useWatch({
    control,
    name: ['venueId', 'newsableType'],
  });

  useEffect(() => {
    if (!venueId) {
      setValue('newsableType', '');
      setValue('newsableId', null);
    }
  }, [setValue, venueId]);

  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <LoadingSpinner />}
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <Stack spacing={8}>
            <FormControl as="fieldset">
              <SectionTitle as="legend">Zdjęcie</SectionTitle>
              <DropzoneField
                name="imagesAttributes[0].image"
                title="Dodaj zdjęcie w formacie .png, .jpg"
              />
            </FormControl>
            <Input name="title" label="Tytuł" placeholder="Wpisz tytuł" />
            <Input
              name="author"
              label="Autor"
              placeholder="Wpisz imię i nazwisko"
            />
            <Stack spacing={4} w="100%">
              <VenuesSelect name="venueId" label="Obiekt powiązany" />
              {venueId && (
                <>
                  <Box>
                    <HStack spacing={0} gap={1} wrap="wrap">
                      {NEWSABLE_TYPE_OPTIONS.map((kind) => (
                        <RadioBoxControl
                          id={`hours-kind-${kind.value}`}
                          key={kind.value}
                          label={kind.label}
                          name="newsableType"
                          value={kind.value}
                          onClickCallback={() => setValue('newsableId', null)}
                          group
                          isClearable
                        />
                      ))}
                    </HStack>
                    <ExternalError name="newsableType" />
                  </Box>
                  {newsableType === NEWSABLE_TYPE.EVENT && (
                    <VenueEventsSelect name="newsableId" venueId={venueId} />
                  )}
                  {newsableType === NEWSABLE_TYPE.ATTRACTION && (
                    <VenueAttractionsSelect
                      label="Atrakcja / wydarzenie powiązane"
                      placeholder="Wybierz atrakcję / wydarzenie"
                      kind={ATTRACTION_KIND.TEMPORARY}
                      name="newsableId"
                      venueIds={venueId}
                    />
                  )}
                </>
              )}
            </Stack>
            <DateInput name="date" label="Data publikacji" showError />
            <Editor name="description" label="Opis" placeholder="Wpisz opis" />
            <Textarea
              name="shortDescription"
              label="Krótki opis aktualności"
              placeholder="Wpisz opis"
            />
          </Stack>
        </Box>
      </Card>
    </Flex>
  );
}

export default FormBody;
