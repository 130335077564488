import { Button as ChakraButton, Icon, Text } from '@chakra-ui/react';
import { TablerIconsProps } from '@tabler/icons-react';

interface ButtonProps {
  label?: string;
  icon?: (props: TablerIconsProps) => JSX.Element;
}

function Button({ label, icon }: ButtonProps) {
  return (
    <ChakraButton
      variant="outlined"
      backgroundColor="none"
      border="solid 1px"
      borderColor="complementary.black"
      whiteSpace="unset"
      w="100%"
      h="auto"
      gap={2}
    >
      <Text
        fontSize="17px"
        lineHeight="24px"
        textTransform="uppercase"
        noOfLines={2}
      >
        {label}
      </Text>
      {icon && <Icon as={icon} boxSize="24px" />}
    </ChakraButton>
  );
}

Button.defaultProps = {
  label: '',
  icon: null,
};

export default Button;
