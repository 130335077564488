import { useEffect, useState } from 'react';
import { convertKeysToCamelCase, convertKeysToSnakeCase } from 'utils/helpers';
import { useAuth } from 'context/AuthContext';
import { API } from 'api';

const AxiosInterceptor = ({ children }: { children: any }) => {
  const { logout } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const requestInterceptor = API.interceptors.request.use((config: any) => {
      const newConfig = { ...config };
      newConfig.url = config.url;
      if (newConfig.headers['Content-Type'] === 'multipart/form-data')
        return newConfig;
      if (config.params) {
        newConfig.params = convertKeysToSnakeCase(config.params);
      }
      if (config.data) {
        newConfig.data = convertKeysToSnakeCase(config.data);
      }
      return newConfig;
    });

    const responseInterceptor = API.interceptors.response.use(
      (response: any) => {
        if (response.error) {
          response.error = convertKeysToCamelCase(response.error);
        }
        if (response.data) {
          response.data = convertKeysToCamelCase(response.data);
        }
        return response;
      },
      async (error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          return logout();
        }
        return Promise.reject(convertKeysToCamelCase(error.response.data));
      },
    );

    setIsInitialized(true);

    return () => {
      API.interceptors.request.eject(requestInterceptor);
      API.interceptors.response.eject(responseInterceptor);
      setIsInitialized(false);
    };
  }, [logout]);

  return isInitialized && children;
};

export default AxiosInterceptor;
