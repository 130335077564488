import { VStack } from '@chakra-ui/react';
import useImage from 'utils/useImage';
import MediaPlaceholder from 'components/MediaPlaceholder';
import { SlideProps, Element } from 'types/previews';

function Slide({ element }: SlideProps<Element>) {
  const imagePreview = useImage(element?.image);

  return (
    <VStack
      w="100%"
      h="100%"
      alignItems="center"
      justifyContent="center"
      bgColor="complementary.whiteGrey"
      bgImage={imagePreview}
      bgSize="cover"
      bgRepeat="no-repeat"
      bgPosition="50% 50%"
      position="relative"
      borderRadius="8px"
    >
      {!imagePreview && (
        <MediaPlaceholder text="Tu pojawi się dodane zdjęcie" />
      )}
    </VStack>
  );
}

export default Slide;
