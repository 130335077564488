import { Box, Flex, FormControl, HStack, Stack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import Card from 'components/Card';
import Select from 'components/Form/Select';
import LoadingSpinner from 'components/FormSpinner';
import DropzoneField from 'components/Form/Dropzone';
import { FormBodyProps } from 'types/common';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { SectionTitle } from 'components/Form/SectionText';
import Input from 'components/Form/Input';
import Textarea from 'components/Form/Textarea';
import DateInput from 'components/Form/Date';
import TicketsSection from 'components/Content/Sections/TicketsSection';
import MaskedInput from 'components/Form/MaskedInput';
import HoursKindSection from 'components/Schedule/HoursKindSection';
import {
  ATTRACTION_KIND,
  DEFAULT_VALUES,
} from 'pages/Attractions/Form/constants';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';
import NumberInput from 'components/Form/NumberInput';
import VenueSelect from 'pages/Attractions/Form/components/VenueSelect';
import Editor from 'components/Form/Editor';
import PushSection from 'pages/Attractions/Form/components/PushSection';

const ATTRACTION_KIND_OPTIONS = [
  {
    label: 'Wydarzenie/atrakcja stała',
    value: ATTRACTION_KIND.CONSTANT,
  },
  {
    label: 'Wydarzenie czasowe',
    value: ATTRACTION_KIND.TEMPORARY,
  },
];

function FormBody({ isLoading }: FormBodyProps) {
  const { control, reset } = useFormContext();
  const attractionKind = useWatch({
    control,
    name: 'kind',
  });

  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <LoadingSpinner />}
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <Stack spacing={8}>
            <Select
              label="Typ atrakcji"
              name="kind"
              options={ATTRACTION_KIND_OPTIONS}
              onChangeCallback={(kind) =>
                reset({ ...DEFAULT_VALUES[kind], kind })
              }
            />
            {attractionKind && (
              <Stack spacing={8}>
                <FormControl as="fieldset">
                  <SectionTitle as="legend">Zdjęcie atrakcji</SectionTitle>
                  <DropzoneField
                    name="imagesAttributes[0].image"
                    title="Dodaj zdjęcie w formacie .png, .jpg"
                  />
                </FormControl>
                <Input
                  name="name"
                  label="Nazwa atrakcji"
                  placeholder="Wpisz nazwę"
                />
                <Editor
                  name="description"
                  label="Opis atrakcji"
                  placeholder="Wpisz opis"
                />
                <VenueSelect />
                <Textarea
                  name="pathwaysDescription"
                  label="Opis ścieżki zwiedzania (opcjonalnie)"
                  placeholder="Wpisz opis"
                />
                {attractionKind === ATTRACTION_KIND.TEMPORARY && (
                  <>
                    <FormControl as="fieldset">
                      <SectionTitle as="legend">
                        Czas trwania wydarzenia
                      </SectionTitle>
                      <MaskedInput
                        name="duration"
                        label="Podaj czas trwania"
                        placeholder="HH:MM"
                        format="##:##"
                      />
                    </FormControl>
                    <FormControl as="fieldset">
                      <SectionTitle as="legend">Data wydarzenia</SectionTitle>
                      <HStack spacing={2} align="start">
                        <DateInput
                          name="activeFrom"
                          label="Data od"
                          showError
                        />
                        <DateInput name="activeTo" label="Data do" showError />
                      </HStack>
                    </FormControl>
                    <TicketsSection prepareFieldName={(name: string) => name} />
                    <HoursKindSection
                      prepareFieldName={(name: string) => name}
                    />
                    <ToggleCheckbox label="Cykliczność" name="cyclic" />
                  </>
                )}
                <NumberInput
                  name="poiId"
                  label="Id w Mapsted"
                  placeholder="Wpisz id"
                />
                <ToggleCheckbox label="Aktywność" name="active" />
                <PushSection prepareFieldName={(name: string) => name} />
              </Stack>
            )}
          </Stack>
        </Box>
      </Card>
    </Flex>
  );
}

export default FormBody;
