const FormError = {
  parts: ['text'],
  baseStyle: {
    text: {
      color: 'complementary.error',
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: 400,
      letterSpacing: '0.004em',
      textAlign: 'left',
      marginTop: 1,
    },
  },
};

export default FormError;
