import { useCallback, memo, useState, useEffect } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import {
  Button,
  FormControl,
  Text,
  FormErrorMessage,
  Box,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { IconTrash } from '@tabler/icons-react';

interface DropzoneProps {
  name: string;
  onChange: (...event: any[]) => void;
  acceptVideo: boolean;
  acceptAudio: boolean;
  error: FieldError | undefined;
  title?: string;
  isRemovable?: boolean;
}

function Dropzone({
  name,
  onChange,
  acceptVideo,
  acceptAudio,
  error,
  title,
  isRemovable,
}: DropzoneProps) {
  const [file, setFile] = useState<File | null>(null);
  const { setValue, getValues } = useFormContext();
  const currentValue = getValues(name);

  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      setValue(name, acceptedFiles[0], { shouldValidate: true });
    },
    [name, setValue],
  );

  useEffect(() => {
    if (currentValue?.url) setFile(null);
  }, [currentValue]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      ...(!acceptVideo &&
        !acceptAudio && {
          'image/jpeg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
        }),
      ...(acceptVideo && { 'video/mp4': ['.mp4'] }),
      ...(acceptAudio && { 'audio/mpeg': ['.mp3'] }),
    },
  });

  return (
    <FormControl isInvalid={Boolean(error)} pb="10px" {...getRootProps()}>
      <Box
        border={`1px #0d0d0d ${isDragActive ? 'dashed' : 'solid'}`}
        borderRadius="4px"
        textAlign="center"
        borderColor={error ? 'complementary.error' : '#0d0d0d'}
        minHeight={184}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <input {...getInputProps({ onChange })} />
        {file ? (
          <VStack spacing={2}>
            <Text>{file.name}</Text>
            {isRemovable && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setFile(null);
                  setValue(name, null, { shouldValidate: true });
                }}
                aria-label="Delete image"
                variant="solidSecondary"
                icon={<IconTrash />}
                isRound
              />
            )}
          </VStack>
        ) : (
          <>
            <Text
              fontWeight={600}
              fontSize={14}
              lineHeight={4}
              mb={4}
              dangerouslySetInnerHTML={{
                __html: title || (acceptVideo ? 'Dodaj plik' : 'Dodaj zdjęcie'),
              }}
            />

            {isDragActive ? (
              <Text fontSize={14} lineHeight={4}>
                Upuść plik tutaj...
              </Text>
            ) : (
              <>
                <Text fontSize={14} lineHeight={4}>
                  Przeciągnij i upuść lub dodaj z dysku
                </Text>
                <Button type="button" variant="solidSecondary" mt={6}>
                  DODAJ Z DYSKU
                </Button>
              </>
            )}
          </>
        )}
      </Box>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}

Dropzone.defaultProps = {
  title: null,
  isRemovable: false,
};

interface DropzoneFieldProps {
  name: string;
  acceptVideo?: boolean;
  acceptAudio?: boolean;
  title?: string;
  isRemovable?: boolean;
}

function DropzoneField({
  name,
  acceptVideo = false,
  acceptAudio = false,
  title,
  isRemovable,
}: DropzoneFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange }, fieldState: { error } }) => (
        <Dropzone
          name={name}
          onChange={(e: any) => onChange(e.target.files[0])}
          acceptVideo={acceptVideo}
          acceptAudio={acceptAudio}
          error={error}
          title={title}
          isRemovable={isRemovable}
        />
      )}
    />
  );
}
DropzoneField.defaultProps = {
  acceptVideo: false,
  acceptAudio: false,
  title: null,
  isRemovable: false,
};
export default memo(DropzoneField);
