import { Box, Text } from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';
import ROUTES from 'app/routes';
import { useAuth } from 'context/AuthContext';
import LoginForm from 'pages/Login/LoginForm';

function Login() {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={ROUTES.onboardScreens.base} replace />;
  }

  return (
    <Box>
      <Text variant="h2" w="100%" pb="68px">
        ZALOGUJ SIĘ
      </Text>
      <LoginForm />
    </Box>
  );
}

export default Login;
