import { AspectRatio, Box } from '@chakra-ui/react';
import MediaPlaceholder from 'components/MediaPlaceholder';
import VideoPlayer from 'components/VideoPlayer';
import { PreviewComponentProps } from 'types/common';

function VideoPreview({ formValues }: PreviewComponentProps) {
  return (
    <AspectRatio width="100%" ratio={1.76}>
      <Box borderRadius="8px">
        {formValues?.video ? (
          <VideoPlayer src={formValues.video} />
        ) : (
          <MediaPlaceholder
            text="Dodaj video, aby wygenerować podgląd"
            type="video"
          />
        )}
      </Box>
    </AspectRatio>
  );
}

export default VideoPreview;
