import {
  Flex,
  IconButton,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Box,
  Icon,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { IconChevronRight, IconChevronsRight } from '@tabler/icons-react';
import useQueryParams from 'utils/useQueryParams';
import {
  SIDEBAR_CLOSED_WIDTH,
  SIDEBAR_OPENED_WIDTH,
} from 'components/Layout/constants';
import { useAppContext } from 'context/AppContext';

export const FOOTER_HEIGHT = '65px';

interface PaginantionFooterProps {
  totals: number;
  footerButtons: React.ReactNode;
  hidePagination?: boolean;
}
function PaginationFooter({
  totals,
  hidePagination = false,
  footerButtons,
}: PaginantionFooterProps) {
  const { isSidebarExpanded } = useAppContext();
  const { search, setSearch } = useQueryParams();

  const page = Number(search.get('page'));
  const pageSize = Number(search.get('page_size'));

  const lastPage = Math.ceil(totals / +pageSize);

  const onPageSizeChange = (ps: string) => {
    search.set('page', '1');
    search.set('page_size', ps);
    setSearch(search);
  };

  const handleClick = (name: string, value: string) => {
    search.set(name, value);
    setSearch(search);
  };

  return (
    <motion.div
      initial={false}
      animate={{
        left: isSidebarExpanded ? SIDEBAR_OPENED_WIDTH : SIDEBAR_CLOSED_WIDTH,
        width: isSidebarExpanded
          ? `calc(100vw - ${SIDEBAR_OPENED_WIDTH}px`
          : `calc(100vw - ${SIDEBAR_CLOSED_WIDTH}px`,
      }}
      style={{
        display: 'flex',
        height: FOOTER_HEIGHT,
        background: 'black',
        position: 'fixed',
        bottom: 0,
        alignItems: 'center',
        zIndex: 10,
        color: '#fff',
      }}
    >
      <Flex paddingInline="48px" w="100%">
        {footerButtons}
        <Box w="100%" />
        <HStack spacing="50px" hidden={hidePagination}>
          <Flex align="center">
            <Text color="white" fontSize={14} mr="16px">
              {pageSize}
            </Text>
            <Menu>
              <MenuButton
                as={IconButton}
                variant="unstyled"
                aria-label="Page size"
                icon={
                  <Icon
                    as={IconChevronRight}
                    transform="rotate(90deg)"
                    fontSize={16}
                  />
                }
              />
              <MenuList minW="80px" color="#000">
                <MenuItem
                  isDisabled={+pageSize === 10}
                  onClick={() => onPageSizeChange('10')}
                >
                  10
                </MenuItem>
                <MenuItem
                  isDisabled={+pageSize === 25}
                  onClick={() => onPageSizeChange('25')}
                >
                  25
                </MenuItem>
                <MenuItem
                  isDisabled={+pageSize === 50}
                  onClick={() => onPageSizeChange('50')}
                >
                  50
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>

          <Text fontSize={15} whiteSpace="nowrap">
            {totals === 0 && '0 - 0 z 0'}
            {totals !== 0 &&
              `${(+page - 1) * +pageSize || 1} - ${
                +page * +pageSize > totals ? totals : +page * +pageSize
              } z ${totals}`}
          </Text>
          <Flex alignItems="center">
            <IconButton
              aria-label="First page"
              variant="unstyled"
              icon={
                <Icon
                  as={IconChevronsRight}
                  transform="rotate(180deg)"
                  fontSize={16}
                />
              }
              onClick={() => handleClick('page', '1')}
            />
            <IconButton
              aria-label="Prev page"
              variant="unstyled"
              icon={
                <Icon
                  as={IconChevronRight}
                  transform="rotate(180deg)"
                  fontSize={16}
                />
              }
              onClick={() => {
                if (page > 1) handleClick('page', (+page - 1).toString());
              }}
            />
            <IconButton
              aria-label="Next page"
              variant="unstyled"
              onClick={() => {
                if (+page + 1 <= lastPage)
                  handleClick('page', (+page + 1).toString());
              }}
              icon={<Icon as={IconChevronRight} fontSize={16} />}
            />
            <IconButton
              aria-label="Last page"
              variant="unstyled"
              onClick={() => handleClick('page', lastPage.toString())}
              icon={<Icon as={IconChevronsRight} fontSize={16} />}
            />
          </Flex>
        </HStack>
      </Flex>
    </motion.div>
  );
}

PaginationFooter.defaultProps = {
  hidePagination: false,
};
export default PaginationFooter;
