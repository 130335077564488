import { Box, HStack, Text } from '@chakra-ui/react';

type BadgeProps = {
  label: string;
  icon: JSX.Element;
};

function Badge({ label, icon }: BadgeProps) {
  return (
    <HStack bgColor="#fff" border="solid 1px" px={4} py={2} borderRadius="8px">
      <Text fontSize={13} lineHeight="16px">
        {label}
      </Text>
      <Box boxSize={6}>{icon}</Box>
    </HStack>
  );
}

export default Badge;
