import { Icon, IconProps } from '@chakra-ui/react';

function BrandLogo(props: IconProps) {
  return (
    <Icon
      width="91px"
      height="88px"
      viewBox="0 0 91 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.188 35.145H15.6V15.772h15.462v3.656H19.22v12.06h6.967v3.657zM19.22 47.241v-7.129h-3.619v10.804h15.462v-3.675H19.22zM75.484 50.916h-3.602V19.428h-3.675c-2.382 9.197-10.64 15.717-20.172 15.717H44.16v-3.657h3.875c8.312 0 15.443-5.984 16.971-14.22l.273-1.496h10.223v35.144h-.018z"
        fill="#000"
      />
      <path
        d="M52.982 19.428v-3.656c-8.931 0-16.298 6.907-17.208 15.716h-4.711v3.657h4.711C36.665 43.99 44.051 50.916 53 50.916V47.26c-7.549 0-13.697-6.242-13.697-13.907-.018-7.682 6.13-13.925 13.68-13.925zM18.475 66.282v3.269h6.64v2.696H15.6v-4.672l-1.419.627v-2.77l1.42-.628v-6.02h2.873v4.727l2.82-1.274v2.77l-2.82 1.275zM28.698 65.543v-.037c0-3.823 2.965-6.944 7.058-6.944s7.022 3.084 7.022 6.907v.037c0 3.823-2.965 6.944-7.058 6.944-4.093.018-7.022-3.084-7.022-6.907zm11.024 0v-.037c0-2.309-1.674-4.23-4.002-4.23-2.347 0-3.966 1.884-3.966 4.193v.037c0 2.308 1.674 4.23 4.002 4.23 2.347 0 3.966-1.885 3.966-4.193zM48.09 58.784h5.165c4.166 0 7.04 2.9 7.04 6.685v.037c0 3.786-2.874 6.722-7.04 6.722H48.09V58.784zM51 61.462v8.107h2.255c2.383 0 3.984-1.625 3.984-4.008v-.037c0-2.382-1.6-4.044-3.984-4.044H51v-.018zM64.46 69.994l7.277-8.606h-7.058v-2.586h10.805v2.253l-7.276 8.606h7.276v2.586H64.46v-2.253zM38.12 54.905l-1.109 2.641h-3.11l1.11-2.64h3.11zM72.464 54.905l-1.11 2.641h-3.11l1.11-2.64h3.11z"
        fill="#000"
      />
    </Icon>
  );
}

export default BrandLogo;
