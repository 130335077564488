import { FormControl } from '@chakra-ui/react';
import DropzoneField from 'components/Form/Dropzone';
import { SectionTitle } from 'components/Form/SectionText';

interface ImageSectionProps {
  prepareFieldName: (name: string) => string;
}

function ImageSection({ prepareFieldName }: ImageSectionProps) {
  return (
    <FormControl as="fieldset">
      <SectionTitle as="legend">Zdjęcie</SectionTitle>
      <DropzoneField
        name={prepareFieldName('imagesAttributes[0].image')}
        title="Dodaj zdjęcie w formacie .png, .jpg"
      />
    </FormControl>
  );
}

export default ImageSection;
