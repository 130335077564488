import { Center, Container, Flex } from '@chakra-ui/react';
import BrandLogo from 'components/Icons/BigEC1';

interface LayoutProps {
  children: React.ReactNode;
}

function PublicLayout({ children }: LayoutProps) {
  return (
    <Flex h="100vh">
      <Center w="612px" bg="complementary.lightBlack">
        <BrandLogo />
      </Center>
      <Center w="100%">
        <Container maxWidth="lg" px={8}>
          {children}
        </Container>
      </Center>
    </Flex>
  );
}

export default PublicLayout;
