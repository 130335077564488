import { Box } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import Previews from 'components/Content/Previews/constants';
import { PreviewComponentProps } from 'types/common';

function DashboardContainerPreview({
  formValues,
  isEditMode,
}: PreviewComponentProps) {
  const PreviewComponent: FunctionComponent<PreviewComponentProps> =
    Previews[formValues?.kind];

  return (
    <Box padding="24px 16px 32px 16px">
      {PreviewComponent ? (
        <PreviewComponent formValues={formValues} isEditMode={isEditMode} />
      ) : null}
    </Box>
  );
}

export default DashboardContainerPreview;
