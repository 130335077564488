const Modal = {
  parts: ['overlay', 'dialog', 'body', 'closeButton'],
  baseStyle: {
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    dialog: {
      borderRadius: '4px',
      margin: 'auto',
      border: 'solid 1px #0d0d0d',
    },
    body: {
      padding: '32px',
    },
    closeButton: {
      position: 'unset',
    },
  },
  sizes: {
    xs: {
      dialog: {
        maxW: 'xs',
      },
    },
    sm: {
      dialog: {
        maxW: 'sm',
      },
    },
    md: {
      dialog: {
        maxW: 'md',
      },
    },
    lg: {
      dialog: {
        maxW: 'lg',
      },
    },
    xl: {
      dialog: {
        maxW: 'xl',
      },
    },
    '2xl': {
      dialog: {
        maxW: '2xl',
      },
    },
    '3xl': {
      dialog: {
        maxW: '3xl',
      },
    },
    '4xl': {
      dialog: {
        maxW: '4xl',
      },
    },
    '5xl': {
      dialog: {
        maxW: '5xl',
      },
    },
    '6xl': {
      dialog: {
        maxW: '1270px',
      },
    },
    full: {
      dialog: {
        maxW: '100vw',
        minH: '100vh',
        '@supports(min-height: -webkit-fill-available)': {
          minH: '-webkit-fill-available',
        },
        margin: 0,
        borderRadius: 0,
      },
    },
  },
};

export default Modal;
