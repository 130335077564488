import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

const Card = styled(Box)`
  background-color: #fff;
  padding: ${({ padding }) => padding || '32px 50px 32px 32px'};
  flex: 1;
  position: ${({ position }) => position || 'relative'};
  z-index: 2;
  overflow: visible;

  & + & {
    margin-top: 16px;
  }
`;

export default Card;
