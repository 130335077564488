import {
  Box,
  Flex,
  FormControl,
  HStack,
  Stack,
  VStack,
} from '@chakra-ui/react';
import Card from 'components/Card';
import Input from 'components/Form/Input';
import TimeInput from 'components/Form/Time';
import DateInput from 'components/Form/Date';
import DropzoneField from 'components/Form/Dropzone';
import Textarea from 'components/Form/Textarea';
import LoadingSpinner from 'components/FormSpinner';
import { FormBodyProps } from 'types/common';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { SectionTitle } from 'components/Form/SectionText';
import EventVenuesSelect from 'components/Select/EventVenuesSelect';
import MaskedInput from 'components/Form/MaskedInput';

type AttractionFacilityFormBody = FormBodyProps & {
  eventId: number;
};

function FormBody({ isLoading, eventId }: AttractionFacilityFormBody) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <LoadingSpinner />}
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <Stack spacing={8}>
            <FormControl as="fieldset">
              <SectionTitle as="legend">Zdjęcie wydarzenia</SectionTitle>
              <DropzoneField
                name="attractionAttributes.imagesAttributes[0].image"
                title="Dodaj zdjęcie w formacie .png, .jpg"
              />
            </FormControl>
            <Input
              name="attractionAttributes.name"
              label="Nazwa wydarzenia"
              placeholder="Wpisz nazwę"
            />
            <Textarea
              name="attractionAttributes.description"
              label="Opis wydarzenia"
              placeholder="Wpisz opis"
            />
            <FormControl as="fieldset">
              <SectionTitle as="legend">Czas trwania wydarzenia</SectionTitle>
              <MaskedInput
                name="attractionAttributes.duration"
                label="Podaj czas trwania"
                placeholder="HH:MM"
                format="##:##"
              />
            </FormControl>
            <FormControl as="fieldset">
              <SectionTitle as="legend">Data i godzina wydarzenia</SectionTitle>
              <VStack spacing={4}>
                <HStack spacing={2} align="start">
                  <DateInput
                    name="attractionAttributes.dateFrom"
                    label="Data od"
                    showError
                  />
                  <DateInput
                    name="attractionAttributes.dateTo"
                    label="Data do"
                    showError
                  />
                </HStack>
                <HStack spacing={2} align="start">
                  <TimeInput
                    name="attractionAttributes.timeFrom"
                    label="Godzina od"
                    showError
                  />
                  <TimeInput
                    name="attractionAttributes.timeTo"
                    label="Godzina do"
                    showError
                  />
                </HStack>
              </VStack>
            </FormControl>
            <EventVenuesSelect
              name="attractionAttributes.venueId"
              eventId={eventId}
            />
          </Stack>
        </Box>
      </Card>
    </Flex>
  );
}

export default FormBody;
