import { Box, Flex, FormControl, Stack } from '@chakra-ui/react';
import Card from 'components/Card';
import TicketsSection from 'components/Content/Sections/TicketsSection';
import DropzoneField from 'components/Form/Dropzone';
import Editor from 'components/Form/Editor';
import Input from 'components/Form/Input';
import NumberInput from 'components/Form/NumberInput';
import { SectionSubtitle, SectionTitle } from 'components/Form/SectionText';
import Textarea from 'components/Form/Textarea';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';
import LoadingSpinner from 'components/FormSpinner';
import ImageGalleryFieldset from 'components/ImageGallery/ImageGalleryFieldset';
import ScheduleFieldset from 'components/Schedule/ScheduleFieldset';
import { FormBodyProps } from 'types/common';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

function FormBody({ isLoading }: FormBodyProps) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <LoadingSpinner />}
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <Stack spacing={8}>
            <FormControl as="fieldset">
              <SectionTitle as="legend">Logo budynku</SectionTitle>
              <DropzoneField
                name="logo"
                title="Dodaj zdjęcie w formacie .png, .jpg"
              />
            </FormControl>
            <FormControl as="fieldset">
              <SectionTitle as="legend">Zdjęcie obiektu</SectionTitle>
              <DropzoneField
                name="commonImagesAttributes[0].image"
                title="Dodaj zdjęcie w formacie .png, .jpg"
              />
            </FormControl>
            <Input
              label="Nazwa obiektu"
              placeholder="Wpisz nazwę"
              name="name"
            />
            <Editor
              name="description"
              label="Opis obiektu"
              placeholder="Wpisz opis"
            />
            <Textarea
              name="shortDescription"
              label="Krótki opis obiektu"
              placeholder="Wpisz opis"
            />
            <TicketsSection prepareFieldName={(name: string) => name} />
            <FormControl as="fieldset">
              <SectionTitle as="legend">Godziny otwarcia </SectionTitle>
              <SectionSubtitle mb={24}>
                Wpisz zakres godzin lub zaznacz pole
              </SectionSubtitle>
              <ScheduleFieldset prepareFieldName={(name: string) => name} />
            </FormControl>
            <Textarea
              name="pathwaysDescription"
              label="Opis ścieżki zwiedzania (opcjonalnie)"
              placeholder="Wpisz opis"
            />
            <FormControl as="fieldset">
              <SectionTitle as="legend">
                Zdjęcie(a) wejścia do budynku
              </SectionTitle>
              <ImageGalleryFieldset baseFieldName="entriesImagesAttributes" />
            </FormControl>
            <NumberInput
              name="poiId"
              label="Id w Mapsted"
              placeholder="Wpisz id"
            />
            <NumberInput
              name="iksorisId"
              label="Id w iKSORIS"
              placeholder="Wpisz id"
            />
            <ToggleCheckbox label="Pokaż plany" name="planVisible" />
            <ToggleCheckbox label="Aktywność" name="active" />
          </Stack>
        </Box>
      </Card>
    </Flex>
  );
}

export default FormBody;
