/* eslint-disable react/no-array-index-key */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { serialize } from 'object-to-formdata';
import HeaderNavigation from 'components/HeaderNavigation';
import { FORM_MAX_WIDTH } from 'utils/constants';
import FooterButtons, { FOOTER_HEIGHT } from 'components/FooterButtons';
import FormBody from 'pages/OnboardScreens/Form/components/FormBody';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
} from 'pages/OnboardScreens/Form/constants';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';
import transformErrors from 'utils/api';
import ComponentPreview from 'components/PreviewWrapper';
import { SERIALIZER_OPTIONS } from 'app/constants';
import FormWrapper from 'components/FormWrapper';

function OnboardScreenCreate() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const methods = useForm<OnboardScreen>({
    mode: 'onChange',
    resolver: yupResolver(BODY_SCHEMA),
    defaultValues: DEFAULT_VALUES,
  });

  const { handleSubmit, setError } = methods;

  const { mutate: createOnboardScreen, isLoading } = useMutation({
    mutationKey: 'onboardScreensMutation',
    mutationFn: (data: OnboardScreen) => {
      const formData = serialize(
        data,
        SERIALIZER_OPTIONS,
        new FormData(),
        'onboard_screen',
      );
      return API.post(APIRoutes.onboardScreens.index(), formData);
    },
    onSuccess: () => {
      toast.success('Ekran został pomyślnie utworzony!');
      navigate(ROUTES.onboardScreens.base);
      queryClient.invalidateQueries('onboardScreens');
    },
    onError: (error) => {
      const transformedErrors = transformErrors(error as any, null);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      toast.error('Wystąpił problem podczas tworzenia ekranu');
    },
  });

  const onSubmit = handleSubmit(async (data: OnboardScreen) =>
    createOnboardScreen(data),
  );

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: 'On-boarding',
          to: ROUTES.onboardScreens.base,
        }}
        crumbs={[{ to: '', label: 'Dodaj ekran' }]}
      />
      <FormWrapper {...methods}>
        <Flex gap={4} mb={4} alignItems="flex-start" pb={FOOTER_HEIGHT}>
          <Box
            w="100%"
            as="form"
            id="onboard-screen-creator"
            onSubmit={onSubmit}
            maxW={FORM_MAX_WIDTH}
          >
            <FormBody isLoading={isLoading} />
          </Box>
          <ComponentPreview kind="onboard_screen" />
        </Flex>
      </FormWrapper>
      <FooterButtons
        isLoading={isLoading}
        isCreate
        formId="onboard-screen-creator"
        onCancel={() => navigate(-2)}
      />
    </Box>
  );
}

export default OnboardScreenCreate;
