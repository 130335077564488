import { Box } from '@chakra-ui/react';
import AudioPlayer from 'components/AudioPlayer';
import { PreviewComponentProps } from 'types/common';

function AudioPreview({ formValues }: PreviewComponentProps) {
  return (
    <Box width="100%" height="100%">
      <AudioPlayer src={formValues?.audio} />
    </Box>
  );
}

export default AudioPreview;
