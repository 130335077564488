import { Flex, FlexProps, IconButton } from '@chakra-ui/react';
import { ConnectDragSource } from 'react-dnd';
import {
  IconTrash,
  IconChevronUp,
  IconGripVertical,
} from '@tabler/icons-react';

interface ContainerActionsProps extends FlexProps {
  showTrashButton: boolean;
  toggleAction: () => void;
  removeAction: () => void;
  isOpen: boolean;
  dragRef: ConnectDragSource;
}

function ContainerActions({
  showTrashButton,
  toggleAction,
  removeAction,
  isOpen,
  dragRef,
  ...props
}: ContainerActionsProps) {
  return (
    <Flex position="absolute" zIndex={5} right={6} top={2} gap={2} {...props}>
      {showTrashButton && (
        <IconButton
          aria-label="remove section"
          icon={<IconTrash />}
          variant="ghost"
          onClick={removeAction}
          isRound
        />
      )}
      <IconButton
        aria-label="toggle section"
        icon={<IconChevronUp />}
        variant="ghost"
        onClick={toggleAction}
        isRound
        sx={{
          transform: `rotate(${isOpen ? 0 : 180}deg)`,
        }}
      />
      <IconButton
        aria-label="drag section"
        icon={<IconGripVertical />}
        variant="ghost"
        ref={dragRef}
        isRound
      />
    </Flex>
  );
}

export default ContainerActions;
