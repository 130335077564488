import { Button, HStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import Input from 'components/Form/Input';
import useQueryParams from 'utils/useQueryParams';

interface SearchProps {
  searchKey: React.ReactNode;
}

function Search({ searchKey }: SearchProps) {
  const { search, setSearch } = useQueryParams();
  const paramName = `q[${searchKey}_cont]`;

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: search.get(paramName),
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const { name } = data;

    if (name) search.set(paramName, name);
    else search.delete(paramName);

    search.set('page', '1');
    setSearch(search);
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <HStack spacing={4} w="100%">
          <Input name="name" placeholder="Wpisz nazwę" />
          <Button
            variant="solidSecondary"
            type="submit"
            flexShrink={0}
            h="100%"
          >
            Szukaj
          </Button>
        </HStack>
      </form>
    </FormProvider>
  );
}

export default Search;
