import Input from 'components/Form/Input';

interface HeaderSectionProps {
  prepareFieldName: (name: string) => string;
}

function HeaderSection({ prepareFieldName }: HeaderSectionProps) {
  return (
    <Input
      name={prepareFieldName('title')}
      label="Nagłówek"
      placeholder="Wpisz nagłówek"
    />
  );
}

export default HeaderSection;
