import {
  AspectRatio,
  Box,
  VStack,
  Text,
  HStack,
  Divider,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';
import {
  IconArrowNarrowRight,
  IconCalendarEvent,
  IconMapPin,
  IconTicket,
  IconX,
} from '@tabler/icons-react';
import { isEmpty } from 'lodash';
import useImage from 'utils/useImage';
import { SlideProps } from 'types/previews';
import Badge from 'components/Content/Previews/CarouselPreview/Badge';
import { dateRangePrettierNoTime } from 'utils/date';

function VenuesListOverlay({
  venues,
  onClose,
}: {
  venues: Venue[];
  onClose: () => void;
}) {
  return (
    <Box
      position="absolute"
      bottom="0px"
      zIndex={1}
      padding="16px 16px 24px 16px"
      bgColor="white"
      w="100%"
      minH="232px"
      overflow="auto"
    >
      <HStack justify="space-between" mb={4}>
        <Text fontSize="17px" fontWeight={600} lineHeight="24px">
          W wielu obiektach:
        </Text>
        <IconButton
          icon={<IconX />}
          onClick={onClose}
          aria-label="Close"
          variant="unstyled"
          size="24px"
        />
      </HStack>
      <VStack spacing={2} w="100%">
        {venues?.map((venue) => (
          <HStack spacing={2} w="100%" align="start" key={venue?.id}>
            <IconMapPin size={24} />
            <Text fontSize={17} lineHeight="24px" w="100%">
              {venue?.name}
            </Text>
          </HStack>
        ))}
      </VStack>
    </Box>
  );
}

function EventSlide({ element }: SlideProps<EventItem>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const imagePreview = useImage(element?.image?.image);
  const venues = element?.venues;

  return (
    <VStack
      position="relative"
      border="solid 1px"
      borderRadius="8px"
      overflow="hidden"
      h="100%"
      w="100%"
    >
      <AspectRatio width="100%" ratio={0.91}>
        <Box
          w="100%"
          h="100%"
          bgColor="complementary.whiteGrey"
          bgImage={imagePreview}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition="50% 50%"
          position="relative"
        >
          {(element?.freeEntry || element?.ticketsTeaser) && (
            <Box position="absolute" top="8px" right="8px">
              <Badge
                label={
                  element?.freeEntry
                    ? 'Bezpłatny wstęp'
                    : element?.ticketsTeaser
                }
                icon={<IconTicket />}
              />
            </Box>
          )}
        </Box>
      </AspectRatio>
      <VStack
        padding="16px 16px 24px 16px"
        w="100%"
        h="100%"
        justify="space-between"
        spacing={4}
        minHeight="232px"
      >
        <VStack spacing={2} w="100%">
          <HStack spacing={2} w="100%">
            <Text variant="h3">{element?.name}</Text>
            <Box boxSize={6}>
              <IconArrowNarrowRight />
            </Box>
          </HStack>
          <Divider borderColor="complementary.lightBlack" />
          <Text fontSize={17} lineHeight="24px" w="100%" noOfLines={2}>
            {element?.shortDescription}
          </Text>
        </VStack>
        <VStack spacing={2} w="100%" color="complementary.previewGray">
          <HStack spacing={2} w="100%">
            <IconCalendarEvent />
            <Text fontSize={17} lineHeight="24px" w="100%">
              {dateRangePrettierNoTime(
                element?.dateFrom?.toString(),
                element?.dateTo?.toString(),
              )}
            </Text>
          </HStack>
          {!isEmpty(venues) && (
            <HStack spacing={2} w="100%" align="start">
              <IconMapPin size={24} />
              <VStack spacing={0} w="100%">
                {venues?.length > 1 ? (
                  <Text
                    fontSize={17}
                    lineHeight="24px"
                    w="100%"
                    textDecoration="underline"
                    cursor="pointer"
                    onClick={onOpen}
                  >
                    W wielu obiektach
                  </Text>
                ) : (
                  <Text fontSize={17} lineHeight="24px" w="100%">
                    {venues?.[0]?.name}
                  </Text>
                )}
              </VStack>
            </HStack>
          )}
        </VStack>
      </VStack>
      {isOpen && (
        <VenuesListOverlay venues={element?.venues} onClose={onClose} />
      )}
    </VStack>
  );
}

export default EventSlide;
