import { AspectRatio, Box, VStack, Text } from '@chakra-ui/react';
import MediaPlaceholder from 'components/MediaPlaceholder';
import { PreviewComponentProps } from 'types/common';
import useImage from 'utils/useImage';

function OnboardScreenPreview({ formValues }: PreviewComponentProps) {
  const imagePreview = useImage(formValues?.image);

  return (
    <Box>
      <AspectRatio w="100%" ratio={0.72}>
        <VStack spacing={0}>
          {imagePreview ? (
            <Box
              bgColor="complementary.gray"
              bgImage={imagePreview}
              bgSize="cover"
              bgRepeat="no-repeat"
              bgPosition="50% 50%"
              height="100%"
              width="100%"
            />
          ) : (
            <MediaPlaceholder
              text="Tu pojawi się dodane zdjęcie"
              type="image"
            />
          )}
          <Box padding="16px 16px 24px 16px" w="100%">
            <Text
              fontSize="17px"
              lineHeight="24px"
              fontWeight={600}
              noOfLines={1}
            >
              {formValues?.title || 'Tu pojawi się tytuł'}
            </Text>
            <Text fontSize="17px" lineHeight="24px" noOfLines={4} height="96px">
              {formValues?.description || 'Tu pojawi się opis'}
            </Text>
          </Box>
        </VStack>
      </AspectRatio>
    </Box>
  );
}

export default OnboardScreenPreview;
