import PathwayCard from 'components/Cards/PathwayCard';
import { PreviewComponentProps } from 'types/common';
import { timeToDuration } from 'utils/date';

function PathwayPreview({ formValues }: PreviewComponentProps) {
  const attractions = formValues?.pathwayPointsAttributes?.length;
  const duration = timeToDuration(formValues?.duration);

  return (
    <PathwayCard
      name={formValues?.name}
      attractions={attractions}
      duration={duration}
    />
  );
}

export default PathwayPreview;
