import {
  Tag,
  TagLabel,
  TagCloseButton,
  TagRightIcon,
  IconButton,
  Box,
} from '@chakra-ui/react';
import { IconGripVertical } from '@tabler/icons-react';
import { useDrag, useDrop } from 'react-dnd';
import DndSpacer from 'components/DndSpacer';
import { OptionType } from 'types/common';

interface SelectedOptionsProps {
  item: OptionType | undefined;
  index: number;
  handleReorder: (sourceIndex: number, targetIndex: number) => void;
  handleDelete: (index: number) => void;
}

function SelectedOptions({
  item,
  index,
  handleReorder,
  handleDelete,
}: SelectedOptionsProps) {
  const [{ isOver, targetOffset }, dropRef] = useDrop({
    accept: 'row',
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
        targetOffset: monitor.getDifferenceFromInitialOffset()?.y,
      };
    },
    drop: (dropItem: { index: number }) => {
      const sourcePlacement = dropItem.index;
      const targetPlacement = index;
      if (sourcePlacement === targetPlacement) return;
      handleReorder(sourcePlacement, targetPlacement);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'row',
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const showDrop = isOver && !isDragging && targetOffset;

  return (
    <>
      {showDrop && targetOffset < 0 && <DndSpacer />}
      <Box ref={previewRef}>
        <Tag
          variant="outlined"
          w="100%"
          cursor="pointer"
          ref={(el) => {
            dropRef(el);
            dragRef(el);
          }}
        >
          <TagLabel w="100%" textAlign="left">
            {item?.label}
          </TagLabel>
          <TagCloseButton onClick={() => handleDelete(index)} />
          <TagRightIcon
            as={IconButton}
            variant="unstyled"
            height="20px"
            width="20px"
            minW="auto"
            icon={<IconGripVertical size={16} />}
          />
        </Tag>
      </Box>
      {showDrop && targetOffset > 0 && <DndSpacer />}
    </>
  );
}

export default SelectedOptions;
