/* eslint-disable react/no-array-index-key */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useMemo } from 'react';
import HeaderNavigation from 'components/HeaderNavigation';
import { FORM_MAX_WIDTH } from 'utils/constants';
import FooterButtons, { FOOTER_HEIGHT } from 'components/FooterButtons';
import FormBody from 'pages/Events/AttractionFacilities/Form/components/FormBody';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
  preparePayload,
  TRANSFORMED_KEYS,
} from 'pages/Events/AttractionFacilities/Form/constants';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';
import transformErrors from 'utils/api';
import ComponentPreview from 'components/PreviewWrapper';
import { useGetEvent } from 'api/events';
import FormWrapper from 'components/FormWrapper';

function EventAttractionFacilityCreate() {
  const { id = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const backPath = useMemo(
    () =>
      generatePath(ROUTES.events.attractionFacilities.base, {
        id,
      }),
    [id],
  );

  const methods = useForm<AttractionFacility>({
    mode: 'onChange',
    resolver: yupResolver(BODY_SCHEMA),
    defaultValues: DEFAULT_VALUES,
  });

  const { handleSubmit, setError } = methods;

  const { data: { data: event } = {} } = useGetEvent(+id);

  const { mutate: createEvent, isLoading } = useMutation({
    mutationFn: (data: AttractionFacility) => {
      const payload = preparePayload(data);
      return API.post(
        APIRoutes.events.attractionFacilities.index(+id),
        payload,
      );
    },
    mutationKey: 'eventAttractionFacilitiesMutation',
    onSuccess: () => {
      toast.success('Wydarzenie agendy zostało pomyślnie utworzone!');
      navigate(backPath);
      queryClient.invalidateQueries('eventAttractionFacilities');
    },
    onError: (error) => {
      const transformedErrors = transformErrors(error as any, null);
      Object.keys(transformedErrors).forEach((field: string) => {
        const fieldName = TRANSFORMED_KEYS[field] || field;
        setError(fieldName as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      toast.error('Wystąpił problem podczas tworzenia wydarzenia agendy');
    },
  });

  const onSubmit = handleSubmit(async (data: AttractionFacility) =>
    createEvent(data),
  );

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: event?.name!,
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Dodaj wydarzenie agendy' }]}
      />
      <FormWrapper {...methods}>
        <Flex gap={4} mb={4} alignItems="flex-start" pb={FOOTER_HEIGHT}>
          <Box
            w="100%"
            as="form"
            id="attraction-facility-creator"
            onSubmit={onSubmit}
            maxW={FORM_MAX_WIDTH}
          >
            <FormBody isLoading={isLoading} eventId={+id} />
          </Box>
          <ComponentPreview kind="attraction_facility" />
        </Flex>
      </FormWrapper>
      <FooterButtons
        isLoading={isLoading}
        isCreate
        formId="attraction-facility-creator"
        onCancel={() => navigate(-2)}
      />
    </Box>
  );
}

export default EventAttractionFacilityCreate;
