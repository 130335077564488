/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';
import { useQueryClient } from 'react-query';
import { LANGUAGE_KEY } from 'utils/auth';

type AppContextType = {
  isSidebarExpanded: boolean;
  toggleSidebar: () => void;
  currentLanguage: string;
  setCurrentLanguage: React.Dispatch<React.SetStateAction<string>>;
};

const AppContextDefaultState: AppContextType = {
  isSidebarExpanded: false,
  toggleSidebar: () => {},
  currentLanguage: 'pl',
  setCurrentLanguage: () => {},
};

const IS_SIDEBAR_EXPANDED_KEY = 'ec1-cms-is-sidebar-expanded';

type Props = {
  children: ReactNode;
};

const AppContext = createContext(AppContextDefaultState);

export function AppContextProvider({ children }: Props) {
  const queryClient = useQueryClient();

  const wasSidebarExpanded = sessionStorage.getItem(IS_SIDEBAR_EXPANDED_KEY);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(
    !wasSidebarExpanded || wasSidebarExpanded === 'true',
  );

  const storedLanguage = localStorage.getItem(LANGUAGE_KEY);
  const [currentLanguage, setCurrentLanguage] = useState(
    storedLanguage || 'pl',
  );

  const toggleSidebar = useCallback(
    () => setIsSidebarExpanded((wasExpanded) => !wasExpanded),
    [],
  );

  useEffect(() => {
    sessionStorage.setItem(
      IS_SIDEBAR_EXPANDED_KEY,
      isSidebarExpanded.toString(),
    );
  }, [isSidebarExpanded]);

  useEffect(() => {
    localStorage.setItem(LANGUAGE_KEY, currentLanguage);
    queryClient.invalidateQueries();
  }, [currentLanguage]);

  const globalContextValue = useMemo(
    () => ({
      ...AppContextDefaultState,
      isSidebarExpanded,
      toggleSidebar,
      currentLanguage,
      setCurrentLanguage,
    }),
    [isSidebarExpanded, currentLanguage],
  );

  return (
    <AppContext.Provider value={globalContextValue}>
      {children}
    </AppContext.Provider>
  );
}

export const useAppContext = () => useContext(AppContext);
