import { ColumnDef } from '@tanstack/react-table';

function GetColumns(): ColumnDef<User, string>[] {
  return [
    {
      header: 'Id',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Imię',
      accessorKey: 'firstName',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Nazwisko',
      accessorKey: 'lastName',
      cell: (info) => info.getValue(),
    },
    {
      header: 'E-email',
      accessorKey: 'email',
      cell: (info) => info.getValue(),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
