/* eslint-disable react/no-array-index-key */
import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { serialize } from 'object-to-formdata';
import HeaderNavigation from 'components/HeaderNavigation';
import { FORM_MAX_WIDTH } from 'utils/constants';
import FooterButtons, { FOOTER_HEIGHT } from 'components/FooterButtons';
import FormBody from 'pages/Venues/Plans/Form/components/FormBody';
import { BODY_SCHEMA, DEFAULT_VALUES } from 'pages/Venues/Plans/Form/constants';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';
import transformErrors from 'utils/api';
import LoadingIndicator from 'components/LoadingIndicator';
import ComponentPreview from 'components/PreviewWrapper';
import { SERIALIZER_OPTIONS } from 'app/constants';
import FormWrapper from 'components/FormWrapper';
import { useGetVenue, useGetVenuePlan } from 'api/venues';
import { convertKeysToSnakeCase } from 'utils/helpers';

function OnboardScreenEdit() {
  const { venueId = '', id = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const backPath = useMemo(
    () =>
      generatePath(ROUTES.venues.plans.base, {
        id: venueId,
      }),
    [venueId],
  );

  const methods = useForm<Plan>({
    mode: 'onChange',
    resolver: yupResolver(BODY_SCHEMA),
    defaultValues: DEFAULT_VALUES,
  });

  const { handleSubmit, setError, reset } = methods;

  const { data: { data: venue } = {}, isFetching: isFetchingVenue } =
    useGetVenue(+venueId);

  const { data: { data: plan } = {}, isFetching: isFetchingVenuePlan } =
    useGetVenuePlan(+venueId, +id);

  const { mutate: editVenuePlan, isLoading: isEditing } = useMutation({
    mutationKey: 'venuePlansMutation',
    mutationFn: (data: Plan) => {
      const snakePayload = convertKeysToSnakeCase(data);
      const formData = serialize(
        snakePayload,
        SERIALIZER_OPTIONS,
        new FormData(),
        'plan',
      );
      return API.put(APIRoutes.venues.plans.byId(+venueId, +id), formData);
    },
    onSuccess: () => {
      toast.success('Zmiany w planie zostały zastosowane');
      navigate(backPath);
      queryClient.invalidateQueries('venuePlans');
    },
    onError: ({ errors }) => {
      const transformedErrors = transformErrors(errors, null);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      toast.error('Wystąpił problem podczas edycji planu');
    },
  });

  const onSubmit = handleSubmit(async (data: Plan) => editVenuePlan(data));

  useEffect(() => {
    if (plan) reset({ ...DEFAULT_VALUES, ...plan });
  }, [plan, reset]);

  const isLoading = isFetchingVenue || isFetchingVenuePlan || isEditing;

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: venue?.name!,
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj plan' }]}
      />
      {isFetchingVenuePlan && <LoadingIndicator />}
      <FormWrapper {...methods}>
        <Flex gap={4} mb={4} alignItems="flex-start" pb={FOOTER_HEIGHT}>
          <Box
            w="100%"
            as="form"
            id="venue-plan-editor"
            onSubmit={onSubmit}
            maxW={FORM_MAX_WIDTH}
          >
            <FormBody isLoading={isLoading} />
          </Box>
          <ComponentPreview kind="plan" isEditMode />
        </Flex>
      </FormWrapper>
      <FooterButtons
        isLoading={isLoading}
        formId="venue-plan-editor"
        onCancel={() => navigate(-2)}
      />
    </Box>
  );
}

export default OnboardScreenEdit;
