import colors from 'theme/colors';

const Tabs = {
  parts: ['tab', 'tablist, tabpanel'],
  baseStyle: {},
  variants: {
    line: {
      tablist: {
        border: 'none',
      },
      tab: {
        py: '8px',
        fontFamily: 'Bebas Neue',
        fontSize: '24px',
        lineHeight: '32px',
        textTransform: 'uppercase',
        color: colors.complementary.black,
        border: 'none',
        _active: {
          bg: 'transparent',
        },
        _hover: {
          boxShadow: `0 2px 0 0 ${colors.complementary.black}`,
        },
        _selected: {
          border: 'none',
          boxShadow: `0 2px 0 0 ${colors.complementary.black}`,
          color: colors.complementary.black,
          _hover: {
            cursor: 'default',
            boxShadow: `0 2px 0 0 ${colors.complementary.black}`,
          },
        },
      },
      tabpanel: {
        paddingX: 0,
        paddingTop: 6,
        paddingBottom: 0,
      },
    },
  },
};

export default Tabs;
