import { Box, Stack, HStack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import Textarea from 'components/Form/Textarea';
import { SectionTitle } from 'components/Form/SectionText';
import Checkbox from 'components/Form/Checkbox';

interface TicketsSectionProps {
  prepareFieldName: (name: string) => string;
}

function TicketsSection({ prepareFieldName }: TicketsSectionProps) {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();
  const hasFreeEntry = useWatch({
    control,
    name: prepareFieldName('freeEntry'),
  });

  useEffect(() => {
    if (!isEmpty(errors)) {
      trigger(prepareFieldName('ticketsTeaser'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFreeEntry]);

  return (
    <Box w="100%">
      <SectionTitle as="legend">Bilety</SectionTitle>
      <Stack spacing={8}>
        <Textarea
          name={prepareFieldName('ticketsInfo')}
          label="Dodatkowy opis (opcjonalnie)"
          placeholder="Wpisz opis lub zostaw puste pole"
        />
        <HStack spacing={8}>
          <Box w="100%">
            <Textarea
              name={prepareFieldName('ticketsTeaser')}
              label="Cena biletu"
              placeholder="Wprowadź cenę biletu"
              isDisabled={hasFreeEntry}
            />
          </Box>
          <Box>
            <Checkbox label="Bezpłatne" name={prepareFieldName('freeEntry')} />
          </Box>
        </HStack>
        <Textarea
          name={prepareFieldName('ticketsLink')}
          label="Link do biletów"
          placeholder="Link do biletów"
        />
      </Stack>
    </Box>
  );
}

export default TicketsSection;
