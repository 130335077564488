import { TabPanel, VStack, Text, Box } from '@chakra-ui/react';
import ImageGalleryPreview from 'components/Content/Previews/ImageGalleryPreview';
import TicketsPreview from 'components/Content/Previews/TicketsPreview';
import SchedulePreview from 'components/Previews/SchedulePreview';
import WYSIWYGContent from 'components/WYSIWYGContent';

interface InformationsTabProps {
  attraction: Attraction;
}

function InformationsTab({ attraction }: InformationsTabProps) {
  return (
    <TabPanel>
      <VStack spacing={6}>
        <WYSIWYGContent
          content={attraction?.description}
          placeholder="Tu pojawi się opis obiektu"
        />
        <TicketsPreview formValues={attraction} />
        <SchedulePreview
          label="Godziny otwarcia"
          hoursKind={attraction?.hoursKind}
          schedules={attraction?.schedulesAttributes}
        />
        <Box w="100%">
          <Text variant="h2" w="100%" mb={4}>
            WEJŚCIA DO OBIEKTU
          </Text>
          <ImageGalleryPreview
            formValues={attraction}
            fieldName="entriesImagesAttributes"
            hideHeader
          />
        </Box>
      </VStack>
    </TabPanel>
  );
}

export default InformationsTab;
