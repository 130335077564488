import { ColumnDef } from '@tanstack/react-table';
import { prettierTime } from 'utils/date';
import PathwayPointsPreview from './components/PathwayPointsPreview';

function GetColumns(): ColumnDef<Pathway, string>[] {
  return [
    {
      header: 'Id',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Nazwa ścieżki',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Czas zwiedzania',
      accessorKey: 'duration',
      cell: (info) => prettierTime(+info.getValue()),
    },
    {
      header: 'Nazwa(y) atrakcji',
      accessorKey: 'pathwayPoints',
      cell: ({
        row: {
          original: { pathwayPoints },
        },
      }) => <PathwayPointsPreview points={pathwayPoints} />,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
