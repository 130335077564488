import { FormControl } from '@chakra-ui/react';
import DropzoneField from 'components/Form/Dropzone';
import { SectionTitle } from 'components/Form/SectionText';

interface AudioSectionProps {
  prepareFieldName: (name: string) => string;
}

function AudioSection({ prepareFieldName }: AudioSectionProps) {
  return (
    <FormControl as="fieldset">
      <SectionTitle as="legend">Ścieżka dźwiękowa</SectionTitle>
      <DropzoneField
        name={prepareFieldName('audio')}
        title="Dodaj plik mp3"
        acceptAudio
      />
    </FormControl>
  );
}

export default AudioSection;
