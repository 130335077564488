/* eslint-disable react/no-array-index-key */
import {
  VStack,
  Text,
  HStack,
  Stack,
  Box,
  AspectRatio,
} from '@chakra-ui/react';
import { FunctionComponent, useMemo } from 'react';
import Previews from 'components/Content/Previews/constants';
import BrandLogo from 'components/Icons/PreviewEC1';
import { useGetCurrentForecast } from 'api/dashboard';
import LoadingSpinner from 'components/FormSpinner';
import { PreviewComponentProps } from 'types/common';
import MediaPlaceholder from 'components/MediaPlaceholder';

function DashboardPreview({ formValues }: PreviewComponentProps) {
  const isDashboard = useMemo(
    () => !!formValues?.header || !!formValues?.teaser,
    [formValues?.header, formValues?.teaser],
  );

  const { isFetching, data: { data: forecast } = {} } =
    useGetCurrentForecast(isDashboard);

  return (
    <Box w="100%">
      {isFetching && <LoadingSpinner />}
      <VStack padding="24px 16px 32px 16px" spacing={8}>
        {isDashboard && (
          <HStack w="100%" spacing={0} align="start">
            <Stack spacing={2} w="100%">
              {formValues?.header && (
                <Text variant="h1">{formValues?.header}</Text>
              )}
              {formValues?.teaser && (
                <Text variant="h1">{formValues?.teaser}</Text>
              )}
              <Text fontSize="17px" lineHeight="24px">
                Dziś w Łodzi mamy {forecast?.temp}
              </Text>
            </Stack>
            <BrandLogo />
          </HStack>
        )}
        {formValues?.contentContainersAttributes?.map(
          (container: any, index: number) => {
            if (!container.kind) {
              return (
                <AspectRatio w="100%" ratio={0.72} key={`$container_${index}`}>
                  <MediaPlaceholder text="Wybierz typ, aby wygenerować podgląd" />
                </AspectRatio>
              );
            }

            const PreviewComponent: FunctionComponent<PreviewComponentProps> =
              Previews[container.kind];
            if (PreviewComponent) {
              return (
                <PreviewComponent
                  formValues={container}
                  key={`$container_${index}`}
                  isDashboardPreview={isDashboard}
                />
              );
            }
            return null;
          },
        )}
      </VStack>
    </Box>
  );
}

export default DashboardPreview;
