import { AspectRatio, Box, VStack, Text, HStack } from '@chakra-ui/react';
import {
  IconArrowNarrowRight,
  IconRoute,
  IconTicket,
} from '@tabler/icons-react';
import useImage from 'utils/useImage';
import { SlideProps } from 'types/previews';
import Badge from 'components/Content/Previews/CarouselPreview/Badge';
import WarningBadge from 'components/Content/Previews/CarouselPreview/WarningBadge';

function VenueSlide({ element }: SlideProps<Venue>) {
  const imagePreview = useImage(element?.image?.image);
  const logoPreview = useImage(element?.logo);
  const isActive = element?.active;

  return (
    <Box
      border="solid 1px"
      borderRadius="8px"
      overflow="hidden"
      h="100%"
      position="relative"
      bgColor="black"
    >
      <AspectRatio width="100%" ratio={0.91} position="relative">
        <Box w="100%">
          <Box
            w="100%"
            h="100%"
            bgColor="complementary.whiteGrey"
            bgImage={imagePreview}
            bgSize="cover"
            bgRepeat="no-repeat"
            bgPosition="50% 50%"
            opacity={isActive ? 1 : 0.8}
          >
            <HStack
              justify="space-between"
              align="start"
              p={2}
              position="absolute"
              top="0px"
              left="0px"
              w="100%"
            >
              <Box
                width="88px"
                height="88px"
                bgImage={logoPreview}
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPosition="50% 50%"
                border={logoPreview ? 'none' : 'solid 1px #000'}
              />
              <VStack spacing={2} align="end">
                {(element?.freeEntry || element?.ticketsTeaser) && (
                  <Badge
                    label={
                      element?.freeEntry
                        ? 'Bezpłatny wstęp'
                        : element.ticketsTeaser
                    }
                    icon={<IconTicket />}
                  />
                )}
                <Badge label="Zwiedzaj" icon={<IconRoute />} />
              </VStack>
            </HStack>
          </Box>
          {!isActive && (
            <Box w="100%" px={2} position="absolute" bottom="16px" zIndex={1}>
              <WarningBadge label="Obiekt chwilowo nieczynny" />
            </Box>
          )}
        </Box>
      </AspectRatio>
      <VStack
        p={4}
        spacing={2}
        h="100%"
        bgColor="white"
        opacity={isActive ? 1 : 0.8}
      >
        <HStack spacing={2} w="100%">
          <Text variant="h3">{element?.name}</Text>
          <Box boxSize={6}>
            <IconArrowNarrowRight />
          </Box>
        </HStack>
        <Text fontSize={17} lineHeight="24px" w="100%" noOfLines={4}>
          {element?.shortDescription}
        </Text>
      </VStack>
    </Box>
  );
}

export default VenueSlide;
