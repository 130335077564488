const NumberInput = {
  baseStyle: {
    field: {
      borderColor: 'complementary.black',
    },
  },
  variants: {
    outline: {
      field: {
        px: '12px',
        py: '12px',
        bg: 'white',
        lineHeight: '24px',
        fontSize: '17px',
        color: 'complementary.black',
        fontWeight: 'normal',
        borderWidth: '1px',
        borderRadius: '4px',
        _placeholder: { color: 'complementary.lightBlack' },
        _hover: {
          borderColor: 'complementary.black',
        },
        _invalid: {
          boxShadow: 'unset',
          borderColor: 'complementary.error',
        },
        _disabled: {
          opacity: 1,
          bg: 'rgba(0, 0, 0, 0.05)',
          color: 'complementary.disabled',
          _placeholder: { color: 'complementary.disabled' },
          borderColor: 'complementary.gray',
          _hover: {
            borderColor: 'complementary.gray',
          },
        },
      },
    },
  },
  defaultProps: {
    variant: 'outline',
    size: 'md',
  },
};

export default NumberInput;
