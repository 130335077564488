import * as yup from 'yup';
import { serialize } from 'object-to-formdata';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { ERROR_MESSAGES } from 'utils/form';
import {
  isValidTime,
  timeToDuration,
  transformActivityDates,
} from 'utils/date';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { ATTRACTION_KIND } from 'pages/Attractions/Form/constants';

const validateDateRange = (
  dateFrom: Date,
  timeFrom: Date,
  dateTo: Date,
  timeTo: Date,
) => {
  const { activeFrom, activeTo } = transformActivityDates({
    dateFrom,
    timeFrom,
    dateTo,
    timeTo,
  });

  const diff = differenceInSeconds(new Date(activeTo), new Date(activeFrom));

  return diff > 0;
};

export const BODY_SCHEMA = yup.object({
  attractionAttributes: yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(ERROR_MESSAGES.required)
      .validateMaxStringLength(),
    description: yup
      .string()
      .trim()
      .nullable()
      .required(ERROR_MESSAGES.required),
    duration: yup
      .string()
      .nullable()
      .required(ERROR_MESSAGES.required)
      .test({
        test(duration, context) {
          if (duration && !isValidTime(duration)) {
            return context.createError({
              message: ERROR_MESSAGES.invalidFormat,
            });
          }
          return true;
        },
      }),
    imagesAttributes: yup.array().of(
      yup.object().shape({
        image: yup.mixed().nullable().required(ERROR_MESSAGES.required),
        position: yup.number().required(),
      }),
    ),
    dateFrom: yup.date().nullable().required(ERROR_MESSAGES.required),
    timeFrom: yup.date().nullable().required(ERROR_MESSAGES.required),
    dateTo: yup
      .date()
      .nullable()
      .required(ERROR_MESSAGES.required)
      .test(
        'dateToLaterThanFrom',
        ERROR_MESSAGES.dateToLaterThanFrom,
        (value, testContext) => {
          const { dateFrom, timeFrom, timeTo } = testContext.parent;
          if (dateFrom && value) {
            return validateDateRange(dateFrom, timeFrom, value, timeTo);
          }
          return true;
        },
      ),
    timeTo: yup
      .date()
      .nullable()
      .required(ERROR_MESSAGES.required)
      .test(
        'dateToLaterThanFrom',
        ERROR_MESSAGES.dateToLaterThanFrom,
        (value, testContext) => {
          const { dateFrom, timeFrom, dateTo } = testContext.parent;
          if (dateFrom && value) {
            return validateDateRange(dateFrom, timeFrom, dateTo, value);
          }
          return true;
        },
      ),
    venueId: yup.number().nullable().required(ERROR_MESSAGES.required),
  }),
});

export const DEFAULT_VALUES = {
  position: 0,
  attractionAttributes: {
    kind: ATTRACTION_KIND.AGENDA,
    imagesAttributes: [{ image: null, position: 0 }],
    name: '',
    description: '',
    duration: '',
    activeFrom: null,
    activeTo: null,
    venueId: null,
  },
};

export const preparePayload = (values: AttractionFacility) => {
  const data = { ...values };

  data.attractionAttributes = {
    ...transformActivityDates(values?.attractionAttributes),
    duration: values?.attractionAttributes?.duration
      ? timeToDuration(values.attractionAttributes.duration?.toString())
      : '',
  };

  const snakePayload = convertKeysToSnakeCase(data);

  const formData = serialize(
    snakePayload,
    SERIALIZER_OPTIONS,
    new FormData(),
    'attraction_facility',
  );

  return formData;
};

export const TRANSFORMED_KEYS: { [key: string]: string } = {
  activeFrom: 'dateFrom',
  activeTo: 'dateTo',
};
