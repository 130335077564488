import { VStack, HStack, Text, IconButton, Box } from '@chakra-ui/react';
import {
  IconClock,
  IconMapPin,
  IconArrowNarrowRight,
} from '@tabler/icons-react';
import { useGetVenue } from 'api/venues';
import LoadingSpinner from 'components/FormSpinner';
import { PreviewComponentProps } from 'types/common';
import { timeRangePrettier } from 'utils/date';

function AttractionFacilityPreview({ formValues }: PreviewComponentProps) {
  const { attractionAttributes: attraction } = formValues;
  const { isFetching, data: { data: venue } = {} } = useGetVenue(
    attraction?.venueId,
    true,
  );

  return (
    <Box w="100%">
      {isFetching && <LoadingSpinner />}
      <VStack px={4} py={8} spacing={4}>
        <Text variant="h2" w="100%">
          Agenda
        </Text>
        <VStack
          p={4}
          spacing={4}
          w="100%"
          border="solid 1px"
          borderColor="complementary.black"
          borderRadius="8px"
        >
          <Text w="100%" fontSize={17} fontWeight={600} lineHeight="24px">
            {attraction?.name || 'Tutaj pojawi się nazwa wydarzenia'}
          </Text>
          <HStack spacing={2} w="100%" align="end">
            <VStack spacing={2} w="100%">
              <HStack spacing={2} w="100%" color="complementary.previewGray">
                <IconClock />
                <Text fontSize={17} lineHeight={1.41} w="100%">
                  {timeRangePrettier(
                    attraction?.timeFrom,
                    attraction?.timeTo,
                  ) || 'Czas trwania wydarzenia'}
                </Text>
              </HStack>
              <HStack spacing={2} w="100%" color="complementary.previewGray">
                <IconMapPin />
                <Text fontSize={17} lineHeight={1.41} w="100%" noOfLines={1}>
                  {venue?.name || 'Miejsce wydarzenia'}
                </Text>
              </HStack>
            </VStack>
            <IconButton
              icon={<IconArrowNarrowRight />}
              aria-label="Link"
              variant="ghost"
              border="solid 1px"
              borderColor="complementary.black"
            />
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
}

export default AttractionFacilityPreview;
