import { useQuery } from 'react-query';
import { API, APIRoutes, DEFAULT_STALE_TIME } from 'api';

const fetchUsers = async (queryString: string) => {
  const { data: response } = await API.get(APIRoutes.users.index(queryString));
  return {
    data: response?.data,
    totals: response?.meta?.pagination?.objects,
  };
};

const fetchUser = async (id: number) => {
  const { data } = await API.get(APIRoutes.users.edit(id));
  return data;
};

const useGetUsers = (queryString: string) =>
  useQuery<UsersResponse>(
    ['users', queryString],
    () => fetchUsers(queryString),
    { staleTime: DEFAULT_STALE_TIME },
  );

const useGetUser = (id: number) =>
  useQuery<UserResponse>(['user', id], () => fetchUser(+id));

export { useGetUsers, useGetUser };
