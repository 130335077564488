import { Text, TextProps } from '@chakra-ui/react';

function DescriptionWrapper({ children, ...props }: TextProps) {
  return (
    <Text as="span" minW="256px" noOfLines={8} {...props}>
      {children}
    </Text>
  );
}

export default DescriptionWrapper;
