import { VStack, HStack, Text, IconButton, Icon } from '@chakra-ui/react';
import {
  IconPhoto,
  IconClock,
  IconArrowNarrowRight,
} from '@tabler/icons-react';
import { prettierTime } from 'utils/date';

interface DescriptionSectionProps {
  name: string;
  attractions: number;
  duration: number;
}

const pluralize = (count: number) => {
  if (count === 1) {
    return 'atrakcja';
  }
  if (
    count % 10 > 1 &&
    count % 10 < 5 &&
    (count % 100 < 10 || count % 100 > 19)
  ) {
    return 'atrakcje';
  }
  return 'atrakcji';
};

function PathwayCard({ name, attractions, duration }: DescriptionSectionProps) {
  return (
    <VStack p={4} gap={2}>
      <Text
        fontSize={17}
        fontWeight={600}
        lineHeight="24px"
        w="100%"
        noOfLines={2}
      >
        {name || 'Tutaj pojawi się nazwa nowej ścieżki'}
      </Text>
      <HStack gap={2} w="100%" align="end">
        <HStack
          spacing={2}
          w="100%"
          color="complementary.previewGray"
          align="end"
        >
          <Icon as={IconPhoto} boxSize={6} />
          <Text fontSize={17} lineHeight="24px">
            {attractions || 'X'} {pluralize(attractions)}
          </Text>
        </HStack>
        <HStack
          spacing={2}
          w="100%"
          color="complementary.previewGray"
          align="end"
        >
          <Icon as={IconClock} boxSize={6} />
          <Text fontSize={17} lineHeight="24px">
            {prettierTime(duration) || 'Czas zwiedzania'}
          </Text>
        </HStack>
        <IconButton
          icon={<IconArrowNarrowRight />}
          aria-label="Link"
          variant="ghost"
          border="solid 1px"
          borderColor="complementary.black"
        />
      </HStack>
    </VStack>
  );
}

export default PathwayCard;
