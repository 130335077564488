import { IconX } from '@tabler/icons-react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
  Flex,
} from '@chakra-ui/react';

interface CustomModalProps {
  isOpen: boolean;
  cancellationText: string;
  onClose: () => void;
  confirmationText: string;
  onAccept: () => void;
  title: string;
  subtitle?: string;
}

function CustomModal({
  isOpen,
  cancellationText,
  onClose,
  confirmationText,
  onAccept,
  title,
  subtitle,
}: CustomModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Flex justify="end" mb={6}>
            <ModalCloseButton>
              <IconX />
            </ModalCloseButton>
          </Flex>
          <Text variant="h1" mb={2}>
            {title}
          </Text>
          {subtitle && (
            <Text fontSize="17px" lineHeight="24px">
              {subtitle}
            </Text>
          )}
          <Flex mt={6} gap={4}>
            <Button variant="outlined" onClick={onClose}>
              {cancellationText}
            </Button>
            <Button variant="solidSecondary" onClick={onAccept}>
              {confirmationText}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

CustomModal.defaultProps = {
  subtitle: '',
};

export default CustomModal;
