import { useQuery } from 'react-query';
import qs from 'query-string';
import { API, APIRoutes, DEFAULT_STALE_TIME, EXTENDED_STALE_TIME } from 'api';
import { ContentContainerResponse } from 'types/contentComponent';

const fetchDashboards = async () => {
  const { data } = await API.get(APIRoutes.dashboard.index());
  return data;
};

const fetchDashboard = async (id: number) => {
  const { data } = await API.get(APIRoutes.dashboard.byId(id));
  return data;
};

const fetchDashboardContainers = async (
  dashboardId: number,
  queryString: string,
) => {
  const { data: response } = await API.get(
    APIRoutes.dashboard.containers.index(dashboardId, queryString),
  );
  return {
    data: response?.data,
    totals: response?.meta?.pagination?.objects,
  };
};

const fetchDashboardContainer = async (dashboardId: number, id: number) => {
  const { data } = await API.get(
    APIRoutes.dashboard.containers.byId(dashboardId, id),
  );
  return data;
};

const fetchCurrentForecast = async (queryString: string) => {
  const { data } = await API.get(
    APIRoutes.dashboard.currentForecast(`?${queryString}`),
  );
  return data;
};

const useGetDashboards = () =>
  useQuery<DashboardResponse>(['dashboards'], () => fetchDashboards(), {
    staleTime: DEFAULT_STALE_TIME,
  });

const useGetDashboard = (id: number) =>
  useQuery<DashboardResponse>(['dashboard', id], () => fetchDashboard(id));

const useGetDashboardContainers = (dashboardId: number, queryString: string) =>
  useQuery<DashboardContainersResponse>(
    ['dashboardContainers', dashboardId, queryString],
    () => fetchDashboardContainers(dashboardId, queryString),
    {
      enabled: !!dashboardId,
      staleTime: DEFAULT_STALE_TIME,
    },
  );

const useGetDashboardContainer = (dashboardId: number, id: number) =>
  useQuery<ContentContainerResponse>(
    ['dashboardContainer', dashboardId, id],
    () => fetchDashboardContainer(+dashboardId, +id),
  );

const useGetCurrentForecast = (enabled: boolean) =>
  useQuery<CurrentForecastResponse>(
    ['currentForecast'],
    () => {
      const queryString = qs.stringify({
        device_id: 'CMS',
      });
      return fetchCurrentForecast(queryString);
    },
    {
      enabled,
      staleTime: EXTENDED_STALE_TIME,
    },
  );

export {
  useGetDashboards,
  useGetDashboard,
  useGetDashboardContainers,
  useGetDashboardContainer,
  useGetCurrentForecast,
};
