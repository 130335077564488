const fonts = {
  body: 'Poppins, sans-serif',
  heading: 'Poppins, sans-serif',
};

const fontSizes = {
  xs: '0.625rem', // 10px
  sm: '0.75rem', // 12px
  md: '0.875rem', // 14px
  lg: '1rem', // 16px
  xl: '1.25rem', // 20px
  '2xl': '1.625rem', // 26px
  '3xl': '2rem', // 32px
  '4xl': '2.5rem', // 40px
  '5xl': '2.875rem', // 46px
  '6xl': '3rem', // 48px
};

export default {
  fonts,
  fontSizes,
};
