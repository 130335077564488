import { Stack } from '@chakra-ui/react';
import ImageGalleryFieldset from 'components/ImageGallery/ImageGalleryFieldset';
import Textarea from 'components/Form/Textarea';
import { SectionTitle } from 'components/Form/SectionText';

interface ImageGallerySectionProps {
  prepareFieldName: (name: string) => string;
}

function ImageGallerySection({ prepareFieldName }: ImageGallerySectionProps) {
  return (
    <Stack spacing={8}>
      <Textarea
        name={prepareFieldName('galleryHeader')}
        label="Nagłówek galerii (opcjonalnie)"
        placeholder="Wpisz nagłówek lub zostaw pusty"
      />
      <SectionTitle as="legend">Galeria zdjęć</SectionTitle>
      <ImageGalleryFieldset
        baseFieldName={prepareFieldName('imagesAttributes')}
      />
    </Stack>
  );
}

export default ImageGallerySection;
