import { FunctionComponent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Heading } from '@chakra-ui/react';
import Card from 'components/Card';
import NewsPreview from 'components/Previews/NewsPreview';
import SplashScreenPreview from 'components/Previews/SplashScreenPreview';
import OnboardScreenPreview from 'components/Previews/OnboardScreenPreview';
import VenuePreview from 'components/Previews/VenuePreview';
import PathwayPreview from 'components/Previews/PathwayPreview';
import AttractionPreview from 'components/Previews/AttractionPreview';
import EventPreview from 'components/Previews/EventPreview';
import DashboardPreview from 'components/Previews/DashboardPreview';
import DashboardContainerPreview from 'components/Previews/DashboardContainerPreview';
import AttractionFacilityPreview from 'components/Previews/AttractionFacilityPreview';
import ContentPagePreview from 'components/Previews/ContentPagePreview';
import PlanPreview from 'components/Previews/PlanPreview';
import { PreviewComponentProps } from 'types/common';

interface ComponentPreviewProps {
  kind: string;
  isEditMode?: boolean;
}

export const Previews: {
  [key: string]: FunctionComponent<PreviewComponentProps>;
} = {
  splash_screen: SplashScreenPreview,
  onboard_screen: OnboardScreenPreview,
  news: NewsPreview,
  venue: VenuePreview,
  pathway: PathwayPreview,
  attraction: AttractionPreview,
  event: EventPreview,
  dashboard: DashboardPreview,
  dashboard_container: DashboardContainerPreview,
  attraction_facility: AttractionFacilityPreview,
  content_page: ContentPagePreview,
  plan: PlanPreview,
};

function ComponentPreview({ kind, isEditMode }: ComponentPreviewProps) {
  const PreviewComponent: FunctionComponent<any> = Previews[kind];

  const { control } = useFormContext();
  const formValues = useWatch({ control });

  return (
    <Card padding="32px 24px" maxW="423px" position="sticky" top="65px">
      <Heading as="h4" fontSize={17} mb={4}>
        Podgląd
      </Heading>

      {PreviewComponent && (
        <Box
          border="solid 1px #000"
          maxH="calc(100vh - 262px)"
          overflowY="auto"
          whiteSpace="pre-wrap"
        >
          <PreviewComponent formValues={formValues} isEditMode={isEditMode} />
        </Box>
      )}
    </Card>
  );
}

ComponentPreview.defaultProps = {
  isEditMode: false,
};

export default ComponentPreview;
