import { Text, Box } from '@chakra-ui/react';

type EmptySectionProps = {
  text: string;
};

function EmptySection({ text }: EmptySectionProps) {
  return (
    <Box
      width="100%"
      backgroundColor="complementary.gray"
      px={4}
      py={14}
      borderRadius="8px"
    >
      <Text
        fontWeight={600}
        textAlign="center"
        fontSize="14px"
        lineHeight="16px"
      >
        {text}
      </Text>
    </Box>
  );
}

export default EmptySection;
