import { Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Navigation from 'components/Navigation';
import Header from 'components/Sidebar/Header';
import ResizeButton from 'components/Sidebar/ResizeButton';

interface SidebarProps {
  isExpanded: boolean;
  closeWidth: number;
  openWidth: number;
  expand: () => void;
}

function Sidebar({ isExpanded, closeWidth, openWidth, expand }: SidebarProps) {
  return (
    <motion.div
      hidden={false}
      initial={false}
      animate={{ width: isExpanded ? openWidth : closeWidth }}
      style={{
        background: '#191919',
        position: 'sticky',
        zIndex: 100,
        left: 0,
        height: '100vh',
        top: 0,
        flexShrink: 0,
      }}
    >
      <Flex w="100%" h="100%" justify="space-between" direction="column">
        <Header isExpanded={isExpanded} />
        <Navigation isSidebarExpanded={isExpanded} />
        <ResizeButton isExpanded={isExpanded} onClick={expand} />
      </Flex>
    </motion.div>
  );
}

export default Sidebar;
