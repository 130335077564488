/* eslint-disable no-underscore-dangle, react/no-array-index-key */
import 'swiper/css';
import { useState } from 'react';
import { AspectRatio, Box, Flex, VStack, Text } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Controller } from 'swiper';
import Slide from 'components/Content/Previews/ImageGallery/Slide';
import SliderNavigation from 'components/SliderNavigation';

function ImageGallery({ header, slides }: any) {
  const [imageSwiperRef, setImageSwiperRef] = useState<SwiperCore>();
  const [descriptionSwiperRef, setDescriptionSwiperRef] =
    useState<SwiperCore>();

  return (
    <VStack spacing={4} w="100%">
      {header && (
        <Text variant="h2" w="100%">
          {header}
        </Text>
      )}
      <Box w="100%">
        <Swiper
          onSwiper={setImageSwiperRef}
          modules={[Controller]}
          controller={{ control: descriptionSwiperRef }}
          loop
        >
          {slides?.map((field: any, index: number) => {
            if (field?._destroy) return null;

            return (
              <SwiperSlide key={`image_${index}`}>
                <AspectRatio width="100%" ratio={1.48}>
                  <Slide element={field} />
                </AspectRatio>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
      <Flex gap={2} align="start" w="100%">
        <Swiper
          onSwiper={setDescriptionSwiperRef}
          modules={[Controller]}
          controller={{ control: imageSwiperRef }}
          spaceBetween={4}
          loop
        >
          {slides?.map((field: any, index: number) => {
            if (field?._destroy) return null;

            return (
              <SwiperSlide key={`description_${index}`}>
                <Text fontSize={17} lineHeight={1.41} noOfLines={2}>
                  {field?.description ||
                    'Tu pojawi się opis np.: wejście od ulicy Dowborczyków'}
                </Text>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <SliderNavigation sliderRef={descriptionSwiperRef} />
      </Flex>
    </VStack>
  );
}

export default ImageGallery;
