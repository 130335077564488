import { durationToTime } from 'utils/date';

const parseDefaultValues = (pathway: Pathway) => {
  const result: Record<string, any> = { ...pathway };
  result.duration = durationToTime(Number(pathway?.duration));

  result.pathwayPoints = pathway?.pathwayPoints?.map((point) => ({
    id: point.id,
    position: point.position,
    attractionId: point?.attraction?.id,
  }));

  result.pathwayPointsAttributes = pathway?.pathwayPoints?.map(
    (point) => point?.attraction?.id,
  );

  return result;
};

export default parseDefaultValues;
