import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { AuthProvider } from 'context/AuthContext';
import { AppContextProvider } from 'context/AppContext';
import '@fontsource/poppins';
import '@fontsource/bebas-neue';
import customTheme from 'theme';
import AxiosInterceptor from 'context/AxiosInterceptor';
import App from './App';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppContextProvider>
          <ChakraProvider theme={customTheme}>
            <DndProvider backend={HTML5Backend}>
              <AxiosInterceptor>
                <App />
              </AxiosInterceptor>
            </DndProvider>
          </ChakraProvider>
        </AppContextProvider>
      </AuthProvider>
    </QueryClientProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
