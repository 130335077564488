import {
  Box,
  VStack,
  Text,
  AspectRatio,
  Center,
  HStack,
} from '@chakra-ui/react';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import Button from 'components/Previews/Button';
import { PreviewComponentProps } from 'types/common';
import useImage from 'utils/useImage';

function ContainerPreview({ formValues }: PreviewComponentProps) {
  const images = formValues?.imagesAttributes || formValues?.images;
  const imagePreview = useImage(images?.[0]?.image);

  return (
    <VStack spacing={2} w="100%">
      {formValues?.title && (
        <Text variant="h2" w="100%">
          {formValues.title}
        </Text>
      )}
      <Box border="solid 1px" borderRadius="8px" overflow="hidden" w="100%">
        {imagePreview && (
          <AspectRatio w="100%" ratio={1.48}>
            <Center
              bgColor="complementary.gray"
              bgImage={imagePreview}
              bgSize="cover"
              bgRepeat="no-repeat"
              bgPosition="50% 50%"
            />
          </AspectRatio>
        )}
        <VStack spacing={10} padding={4}>
          <VStack spacing={2} w="100%">
            <HStack w="100%" spacing={2}>
              <Text variant="h3" noOfLines={2}>
                {formValues?.header || 'Tu pojawi się nagłówek'}
              </Text>
              <Box>
                <IconArrowNarrowRight />
              </Box>
            </HStack>
            <Text w="100%" fontSize="17px" lineHeight="24px">
              {formValues.content || 'Tu pojawi się opis'}
            </Text>
          </VStack>
          {formValues?.buttonText && (
            <Button label={formValues.buttonText} icon={IconArrowNarrowRight} />
          )}
        </VStack>
      </Box>
    </VStack>
  );
}

export default ContainerPreview;
