import { Flex } from '@chakra-ui/react';
import BrandLogo from 'components/Icons/EC1';
import LanguageMenu from 'components/LanguageMenu';

interface HeaderProps {
  isExpanded: boolean;
}
function Header({ isExpanded }: HeaderProps) {
  return (
    <Flex
      w="100%"
      justify="space-between"
      align={isExpanded ? 'start' : 'center'}
      pt={0}
      pr={isExpanded ? '12px' : '0'}
      pb={0}
      direction={isExpanded ? 'row' : 'column'}
    >
      <BrandLogo />
      <LanguageMenu />
    </Flex>
  );
}

export default Header;
