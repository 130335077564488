import { VStack, HStack, Text, AspectRatio, Box } from '@chakra-ui/react';
import { IconCalendarEvent, IconTicket } from '@tabler/icons-react';
import WarningBadge from 'components/Content/Previews/CarouselPreview/WarningBadge';
import { ATTRACTION_KIND } from 'pages/Attractions/Form/constants';
import { dateRangePrettierNoTimeAndYear } from 'utils/date';
import useImage from 'utils/useImage';

interface AttractionCardProps {
  name: string;
  image: Image;
  kind: string;
  activeFrom?: string;
  activeTo?: string;
  freeEntry?: boolean;
  ticketsTeaser?: string;
  active?: boolean;
  cyclic?: boolean;
}

function AttractionCard({
  name,
  image,
  kind,
  activeFrom,
  activeTo,
  freeEntry,
  ticketsTeaser,
  active,
  cyclic,
}: AttractionCardProps) {
  const imagePreview = useImage(image?.image);
  const isTemporary = kind === ATTRACTION_KIND.TEMPORARY;

  return (
    <VStack
      w="100%"
      h="100%"
      border="solid 1px"
      borderColor="complementary.black"
      borderRadius="8px"
      overflow="hidden"
      spacing={0}
    >
      <AspectRatio width="100%" ratio={1.75}>
        <Box
          w="100%"
          h="100%"
          bgColor="complementary.whiteGrey"
          bgImage={imagePreview}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition="50% 50%"
          position="relative"
        />
      </AspectRatio>
      <VStack h="100%" w="100%" spacing={10} p={4} justify="space-between">
        <Text
          w="100%"
          fontSize="17px"
          lineHeight="24px"
          noOfLines={2}
          mb={2}
          height="48px"
        >
          {name}
        </Text>
        {isTemporary &&
          (active ? (
            <VStack w="100%" spacing={2} color="complementary.previewGray">
              {(activeFrom || activeTo) && !cyclic && (
                <HStack spacing={2} w="100%" align="start">
                  <IconCalendarEvent />
                  <Text w="100%" fontSize="17px" lineHeight="24px">
                    {dateRangePrettierNoTimeAndYear(activeFrom, activeTo)}
                  </Text>
                </HStack>
              )}
              {(ticketsTeaser || freeEntry) && (
                <HStack spacing={2} w="100%" align="start">
                  <IconTicket />
                  <Text
                    w="100%"
                    fontSize="17px"
                    lineHeight="24px"
                    noOfLines={2}
                  >
                    {freeEntry ? 'Bezpłatny wstęp' : ticketsTeaser}
                  </Text>
                </HStack>
              )}
            </VStack>
          ) : (
            <WarningBadge label="Chwilowo nieczynne" />
          ))}
      </VStack>
    </VStack>
  );
}

AttractionCard.defaultProps = {
  activeFrom: null,
  activeTo: null,
  freeEntry: false,
  ticketsTeaser: '',
  active: true,
  cyclic: false,
};

export default AttractionCard;
