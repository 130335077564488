import * as yup from 'yup';
import {
  schedulesAttributesSchema,
  schedulesAttributesDefaultValues,
  HOURS_KIND,
} from 'components/Schedule/constants';
import {
  imageGallerySchema,
  imageGalleryDefaultValues,
} from 'components/ImageGallery/constants';
import { VALIDATION_SCHEMA } from 'components/Content/constants';
import { ERROR_MESSAGES } from 'utils/form';

export const BODY_SCHEMA = yup.object({
  logo: yup.mixed().nullable().required(ERROR_MESSAGES.required),
  commonImagesAttributes: yup.array().of(
    yup.object().shape({
      image: yup.mixed().nullable().required(ERROR_MESSAGES.required),
      position: yup.number().required(),
    }),
  ),
  name: yup
    .string()
    .trim()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  description: yup.string().trim().required(ERROR_MESSAGES.required),
  shortDescription: yup
    .string()
    .nullable()
    .trim()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  ...VALIDATION_SCHEMA.tickets,
  schedulesAttributes: schedulesAttributesSchema,
  pathwaysDescription: yup.string().nullable().notRequired(),
  entriesImagesAttributes: yup
    .array()
    .of(yup.object().shape(imageGallerySchema)),
  poiId: yup.string().nullable().required(ERROR_MESSAGES.required),
  iksorisId: yup.string().nullable(),
  planVisible: yup.boolean().required(ERROR_MESSAGES.required),
  active: yup.boolean().required(ERROR_MESSAGES.required),
});

export const DEFAULT_VALUES = {
  logo: null,
  commonImagesAttributes: [
    {
      image: null,
      position: 0,
    },
  ],
  name: '',
  description: '',
  ticketsInfo: '',
  ticketsTeaser: '',
  freeEntry: false,
  ticketsLink: '',
  schedulesAttributes: schedulesAttributesDefaultValues,
  pathwaysDescription: '',
  entriesImagesAttributes: imageGalleryDefaultValues,
  hoursKind: HOURS_KIND.RANGE,
  poiId: null,
  iksorisId: null,
  planVisible: true,
  active: true,
};
