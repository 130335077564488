import { useQuery } from 'react-query';
import { API, APIRoutes, DEFAULT_STALE_TIME } from 'api';

const fetchOnboardScreens = async (queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.onboardScreens.index(queryString),
  );
  return {
    data: response?.data,
    totals: response?.meta?.pagination?.objects,
  };
};

const fetchOnboardScreen = async (id: number) => {
  const { data } = await API.get(APIRoutes.onboardScreens.byId(id));
  return data;
};

const useGetOnboardScreens = (queryString: string) =>
  useQuery<OnboardScreensResponse>(
    ['onboardScreens', queryString],
    () => fetchOnboardScreens(queryString),
    {
      staleTime: DEFAULT_STALE_TIME,
    },
  );

const useGetOnboardScreen = (id: number) =>
  useQuery<OnboardScreenResponse>(['onboardScreen', id], () =>
    fetchOnboardScreen(+id),
  );

export { useGetOnboardScreens, useGetOnboardScreen };
