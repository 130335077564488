import * as yup from 'yup';
import { concat, differenceBy, find } from 'lodash';
import { isValidTime, timeToDuration } from 'utils/date';
import { transformRemoved } from 'utils/helpers';
import { ERROR_MESSAGES } from 'utils/form';

export const BODY_SCHEMA = yup.object({
  name: yup
    .string()
    .trim()
    .nullable()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  duration: yup
    .string()
    .trim()
    .nullable()
    .required(ERROR_MESSAGES.required)
    .test({
      test(duration, context) {
        if (!isValidTime(duration)) {
          return context.createError({
            message: ERROR_MESSAGES.invalidFormat,
          });
        }
        return true;
      },
    }),
  pathwayPointsAttributes: yup
    .array()
    .of(yup.number())
    .min(1, ERROR_MESSAGES.minAttractionsLimit),
});

export const DEFAULT_VALUES = {
  name: '',
  duration: '',
  pathwayPointsAttributes: [],
};

export const preparePayload = (values: Pathway) => {
  const data = { ...values };
  data.duration = timeToDuration(data?.duration?.toString());

  data.pathwayPointsAttributes = values?.pathwayPointsAttributes?.map(
    (attraction, index) => ({
      id: find(data.pathwayPoints, (point) => point.attractionId === attraction)
        ?.id,
      position: index,
      attractionId: +attraction,
    }),
  );

  const removedPathwayPoints = differenceBy(
    values?.pathwayPoints,
    data?.pathwayPointsAttributes,
    'id',
  );

  data.pathwayPointsAttributes = concat(
    data?.pathwayPointsAttributes,
    ...transformRemoved(removedPathwayPoints),
  );

  delete data?.pathwayPoints;

  return data;
};
