import { Text, Stack, Button, Box } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

type PathwayPointsPreviewProps = {
  points?: PathwayPoint[];
};

function PathwayPointsPreview({ points }: PathwayPointsPreviewProps) {
  const elementRef = useRef<HTMLInputElement | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);

  useEffect(() => {
    setIsClamped(
      (elementRef.current?.scrollHeight as number) >
        (elementRef.current?.clientHeight as number),
    );
  }, [elementRef]);

  return (
    <>
      <Stack spacing={1} noOfLines={isExpanded ? 0 : 5} ref={elementRef} pb={1}>
        {points?.map((point) => (
          <Text
            key={`point_${point.id}`}
            fontWeight={600}
            as="span"
            display="block"
            lineHeight="inherit"
          >
            {point?.attraction?.name}
          </Text>
        ))}
      </Stack>
      {isClamped && (
        <Box width="100%" textAlign="center">
          <Button
            variant="text"
            height="auto"
            color="complementary.previewGray"
            onClick={() => setIsExpanded((wasExpanded) => !wasExpanded)}
          >
            {isExpanded ? 'Zwiń' : 'Rozwiń'}
          </Button>
        </Box>
      )}
    </>
  );
}

PathwayPointsPreview.defaultProps = {
  points: [],
};

export default PathwayPointsPreview;
