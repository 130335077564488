import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { IconClock } from '@tabler/icons-react';

function CustomInput(props: InputProps, ref: React.Ref<HTMLInputElement>) {
  return (
    <InputGroup>
      <Input ref={ref} {...props} />
      <InputRightElement cursor="pointer" w="48px" zIndex={1} {...props}>
        <IconClock size={19} />
      </InputRightElement>
    </InputGroup>
  );
}

export default CustomInput;
