import { ColumnDef } from '@tanstack/react-table';
import { datePrettierNoTime } from 'utils/date';
import WYSIWYGContent from 'components/WYSIWYGContent';

function GetColumns(): ColumnDef<News, string>[] {
  return [
    {
      header: 'Id',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Tytuł',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Autor',
      accessorKey: 'author',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Budynek powiązany',
      cell: ({
        row: {
          original: { venue },
        },
      }) => venue?.name || '-',
    },
    {
      header: 'Data publikacji',
      accessorKey: 'date',
      cell: (info) => datePrettierNoTime(info.getValue()),
    },
    {
      header: 'Opis',
      accessorKey: 'description',
      cell: (info) => <WYSIWYGContent content={info.getValue()} isTruncated />,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
