import { useQuery } from 'react-query';
import { API, APIRoutes, DEFAULT_STALE_TIME } from 'api';

const fetchContentPages = async (queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.contentPages.index(queryString),
  );
  return {
    data: response?.data,
    totals: response?.meta?.pagination?.objects,
  };
};

const fetchContentPage = async (id: number) => {
  const { data } = await API.get(APIRoutes.contentPages.byId(id));
  return data;
};

const useGetContentPages = (queryString: string) =>
  useQuery<ContentPagesResponse>(
    ['contentPages', queryString],
    () => fetchContentPages(queryString),
    {
      staleTime: DEFAULT_STALE_TIME,
    },
  );

const useGetContentPage = (id: number) =>
  useQuery<ContentPageResponse>(['contentPage', id], () =>
    fetchContentPage(+id),
  );

export { useGetContentPages, useGetContentPage };
