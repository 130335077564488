import { useMemo } from 'react';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import Select from 'components/Form/Select';
import { useGetAttractionsList } from 'api/attractions';
import ExtendedSelect from 'components/Form/Select/ExtendedSelect';

interface VenuesAttractionsSelectProps {
  name: string;
  label: string;
  placeholder: string;
  venueIds: number | number[];
  isMulti?: boolean;
  kind?: string;
  withExtendedSelect?: boolean;
}

function VenueAttractionsSelect({
  name,
  label,
  placeholder,
  venueIds,
  isMulti,
  kind,
  withExtendedSelect,
}: VenuesAttractionsSelectProps) {
  const SelectComponent = withExtendedSelect ? ExtendedSelect : Select;
  const venues = useMemo(
    () => (Array.isArray(venueIds) ? venueIds : [venueIds]),
    [venueIds],
  );

  const queryString = useMemo(
    () =>
      qs.stringify(
        {
          page_size: 'all',
          kind,
          venue_ids: venues,
        },
        { arrayFormat: 'bracket' },
      ),
    [kind, venues],
  );

  const { isFetching: isFetchingAttractions, data: attractions = [] } =
    useGetAttractionsList(queryString, !isEmpty(venues));

  return (
    <SelectComponent
      label={label}
      placeholder={placeholder}
      name={name}
      isLoading={isFetchingAttractions}
      options={attractions}
      isMulti={isMulti}
    />
  );
}

VenueAttractionsSelect.defaultProps = {
  isMulti: false,
  kind: '',
  withExtendedSelect: false,
};

export default VenueAttractionsSelect;
