import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes } from 'react-router-dom';
import CustomToastContainer from 'components/Toast';
import PrivateRoutes from 'components/PrivateRoutes';
import { PRIVATE_ROUTES, PUBLIC_ROUTES, renderRoutes } from 'app/routesConfig';

function App() {
  return (
    <>
      <Routes>
        <Route element={<PrivateRoutes />}>
          {renderRoutes(PRIVATE_ROUTES)}
        </Route>
        {renderRoutes(PUBLIC_ROUTES)}
      </Routes>
      <CustomToastContainer />
    </>
  );
}

export default App;
