import { useIsMutating } from 'react-query';
import { generatePath, Link } from 'react-router-dom';
import useQueryParams from 'utils/useQueryParams';
import ListingLayout from 'components/Layout/ListingLayout';
import ROUTES from 'app/routes';
import Table from 'pages/Dashboard/Listing/components/Table';
import Footer from 'components/Footer';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetDashboardContainers, useGetDashboards } from 'api/dashboard';

function Dashboard() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });

  const isMutating = !!useIsMutating({
    mutationKey: ['dashboardContainersMutation'],
  });

  const {
    isFetching: isFetchingDashboard,
    data: { data: dashboard } = { data: {} as Dashboard },
  } = useGetDashboards();

  const {
    isFetching: isFetchingDashboardContainers,
    data: { data: dashboardContainers, totals } = { data: [], totals: 0 },
  } = useGetDashboardContainers(dashboard?.id, queryString);

  const showLoading =
    isMutating || isFetchingDashboard || isFetchingDashboardContainers;

  return (
    <ListingLayout
      title="Dashboard"
      headerButtons={[
        {
          label: 'EDYTUJ DASHBOARD',
          variant: 'outlined',
          as: Link,
          to: generatePath(ROUTES.dashboard.edit, {
            dashboardId: dashboard?.id,
          }),
        },
        {
          label: 'NOWY ELEMENT',
          as: Link,
          to: generatePath(ROUTES.dashboard.containers.create, {
            dashboardId: dashboard?.id,
          }),
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table data={dashboardContainers} dashboardId={dashboard?.id} />
      )}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default Dashboard;
