import { useIsMutating } from 'react-query';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import useQueryParams from 'utils/useQueryParams';
import ListingLayout from 'components/Layout/ListingLayout';
import ROUTES from 'app/routes';
import ConstantAttractionsTable from 'pages/Attractions/Listing/components/Table/Constant';
import TemporaryAttractionsTable from 'pages/Attractions/Listing/components/Table/Temporary';
import Footer from 'components/Footer';
import LoadingIndicator from 'components/LoadingIndicator';
import { ATTRACTION_KIND } from 'pages/Attractions/Form/constants';
import { useGetAttractions } from 'api/attractions';

const ATTRACTION_KIND_OPTIONS = [
  { label: 'Stałe', value: ATTRACTION_KIND.CONSTANT },
  { label: 'Czasowe', value: ATTRACTION_KIND.TEMPORARY },
];

function Attractions() {
  const { search, queryString } = useQueryParams({
    page_size: '10',
    page: '1',
    kind: ATTRACTION_KIND.CONSTANT,
  });
  const kind = useMemo(() => search.get('kind'), [search]);

  const createPath = useMemo(
    () => ROUTES.attractions.create + queryString,
    [queryString],
  );

  const isMutating = !!useIsMutating({
    mutationKey: ['attractionsMutation'],
  });

  const {
    isFetching,
    data: { data: attractions, totals } = { data: [], totals: 0 },
  } = useGetAttractions(queryString);

  const showLoading = isMutating || isFetching;

  return (
    <ListingLayout
      title="Atrakcje"
      headerButtons={[
        {
          label: 'NOWA ATRAKCJA',
          as: Link,
          to: createPath,
        },
      ]}
      tabs={{
        paramName: 'kind',
        options: ATTRACTION_KIND_OPTIONS,
      }}
      searchKey="name"
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading &&
        (kind === ATTRACTION_KIND.CONSTANT ? (
          <ConstantAttractionsTable data={attractions} />
        ) : (
          <TemporaryAttractionsTable data={attractions} />
        ))}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default Attractions;
