import styled from '@emotion/styled';

const Th = styled.th`
  padding: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  position: sticky;
  top: 0;
  border: solid 1px #666;
  text-align: left;
`;

export default Th;
