import { AspectRatio, Box } from '@chakra-ui/react';
import MediaPlaceholder from 'components/MediaPlaceholder';
import { PreviewComponentProps } from 'types/common';
import useImage from 'utils/useImage';

function PlanPreview({ formValues }: PreviewComponentProps) {
  const imagePreview = useImage(formValues?.plan);

  return (
    <Box>
      <AspectRatio w="100%" ratio={1}>
        {imagePreview ? (
          <Box
            bgColor="complementary.gray"
            bgImage={imagePreview}
            bgSize="cover"
            bgRepeat="no-repeat"
            bgPosition="50% 50%"
            height="100%"
            width="100%"
          />
        ) : (
          <MediaPlaceholder text="Tu pojawi się dodane zdjęcie" type="image" />
        )}
      </AspectRatio>
    </Box>
  );
}

export default PlanPreview;
