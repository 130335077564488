/* eslint-disable react/no-array-index-key, react/function-component-definition */
import { VStack, Text, TabPanel } from '@chakra-ui/react';
import { filter, isEmpty, isNumber } from 'lodash';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import qs from 'query-string';
import { useEffect, useMemo } from 'react';
import AttractionFacilityCard from 'components/Cards/AttractionFacilityCard';
import {
  useGetMobileEventAgenda,
  useGetMobileEventAgendaDays,
} from 'api/events';
import Select from 'components/Form/Select';
import LoadingSpinner from 'components/FormSpinner';
import VenueSelect from 'components/Previews/EventPreview/VenueSelect';
import EmptySection from 'components/EmptySection';
import { ATTRACTION_KIND } from 'pages/Attractions/Form/constants';

interface AttractionFacilitiesTabProps {
  eventId: number;
  isActive: boolean;
  venues: Venue[];
}

const EmptyState = () => (
  <EmptySection text="Aby dodać agendę skorzystaj z menu funkcjonalnego, które jest wyświetlone w ostatniej kolumnie tabeli “Imprezy”" />
);

function AttractionFacilitiesTab({
  eventId,
  isActive,
  venues,
}: AttractionFacilitiesTabProps) {
  const methods = useForm({
    mode: 'onChange',
  });
  const { control, setValue } = methods;
  const [day, venueId] = useWatch({
    control,
    name: ['day', 'venueId'],
  });

  const queryString = qs.stringify({
    page_size: 'all',
    kind: ATTRACTION_KIND.AGENDA,
    date: day,
  });
  const isQueryEnabled = isNumber(eventId) && isActive;

  const { isFetching: isFetchingDays, data: days = [] } =
    useGetMobileEventAgendaDays(eventId, isQueryEnabled);

  const {
    isFetching: isFetchingAttractionFacilities,
    data: attractionFacilities = [],
  } = useGetMobileEventAgenda(eventId, `?${queryString}`, isQueryEnabled);

  const agenda = useMemo(
    () =>
      venueId
        ? filter(attractionFacilities, { venue: { id: venueId } })
        : attractionFacilities,
    [attractionFacilities, venueId],
  );

  useEffect(() => {
    const defaultValue = days?.[0]?.value;
    if (defaultValue) {
      setValue('day', defaultValue);
    }
  }, [days, setValue]);

  return (
    <TabPanel>
      {isFetchingAttractionFacilities && <LoadingSpinner />}
      <VStack spacing={4}>
        <Text variant="h2" w="100%">
          AGENDA
        </Text>
        {isEmpty(days) ? (
          <EmptyState />
        ) : (
          <>
            <FormProvider {...methods}>
              <Select
                label="Wybierz dzień"
                name="day"
                options={days}
                isLoading={isFetchingDays}
              />
              {venues?.length > 1 && (
                <VenueSelect label="Filtruj" venues={venues} showAllOption />
              )}
            </FormProvider>
            {isEmpty(agenda) ? (
              <EmptyState />
            ) : (
              agenda?.map((attraction, index) => (
                <AttractionFacilityCard
                  key={`facility_card_${index}`}
                  name={attraction?.name}
                  activeFrom={attraction?.activeFrom}
                  activeTo={attraction?.activeTo}
                  venueName={attraction?.venue?.name}
                />
              ))
            )}
          </>
        )}
      </VStack>
    </TabPanel>
  );
}

export default AttractionFacilitiesTab;
