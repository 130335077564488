import { motion } from 'framer-motion';
import { Box } from '@chakra-ui/react';
import { useWatch, useFormContext } from 'react-hook-form';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { find } from 'lodash';
import ContainerActions from 'components/ContainerActions';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import Select from 'components/Form/Select';
import Card from 'components/Card';
import {
  DEFAULT_FORM_VALUES,
  FormComponents,
} from 'components/Content/constants';
import LoadingSpinner from 'components/FormSpinner';
import { ContentComponent } from 'types/contentComponent';
import { useGetContentComponents } from 'api/contentComponents';

interface ContentComponentsFormProps {
  index: number;
  isLoading: boolean;
  multipleContainers: boolean;
  isOpen: boolean;
  dragRef: ConnectDragSource;
  previewRef: ConnectDragPreview;
  removeContainer: (index: number) => void;
  toggleSectionHandler: () => void;
  isDashboard: boolean;
}

function ContentComponentsForm({
  index,
  isLoading,
  multipleContainers,
  removeContainer,
  isOpen,
  dragRef,
  previewRef,
  toggleSectionHandler,
  isDashboard,
}: ContentComponentsFormProps) {
  const { control, setValue, clearErrors, getValues } = useFormContext();
  const {
    isLoading: isFetching,
    data: { data: contentComponents } = { data: [] },
  } = useGetContentComponents(isDashboard);

  const fieldName = useMemo(
    () => `contentContainersAttributes[${index}]`,
    [index],
  );

  const containerKind = useWatch({
    control,
    name: `contentContainersAttributes[${index}].kind`,
    defaultValue: '',
  });

  const prepareFieldName: (name: string) => string = useCallback(
    (name) => `${fieldName}.${name}`,
    [fieldName],
  );

  const removeContainerHandler = useCallback(() => {
    removeContainer(index);
  }, [index, removeContainer]);

  const FormComponent: FunctionComponent<any> = FormComponents[containerKind];

  const contentComponentsOptions = useMemo(
    () =>
      contentComponents?.map((component: ContentComponent) => ({
        label: component.name,
        value: component.kind,
      })),
    [contentComponents],
  );

  const prepareFormWithDefaultValues = useCallback(
    (kind: string) => {
      clearErrors();
      const containers = getValues(`contentContainersAttributes`);
      const prevContainer = getValues(`contentContainersAttributes[${index}]`);
      if (containers) {
        const contentComponentId = find(contentComponents, ['kind', kind])?.id;
        setValue(`contentContainersAttributes[${index}]`, {
          ...(DEFAULT_FORM_VALUES[kind] || {}),
          kind,
          contentComponentId,
          id: prevContainer?.id,
        });
      }
    },
    [clearErrors, contentComponents, getValues, index, setValue],
  );

  return (
    <Box flex={1}>
      <Card ref={previewRef}>
        <ContainerActions
          showTrashButton={multipleContainers}
          toggleAction={toggleSectionHandler}
          removeAction={removeContainerHandler}
          dragRef={dragRef}
          isOpen={isOpen}
        />

        <Box maxW={FORM_BODY_MAX_WIDTH}>
          {isLoading && <LoadingSpinner />}
          <Select
            label={isDashboard ? 'Typ elementu' : 'Typ komponentu'}
            name={prepareFieldName('kind')}
            options={contentComponentsOptions}
            isLoading={isFetching}
            onChangeCallback={prepareFormWithDefaultValues}
          />
          <motion.div
            initial={false}
            animate={{
              height: isOpen ? 'auto' : 0,
              overflow: isOpen ? 'visible' : 'hidden',
              paddingTop: isOpen && containerKind ? '36px' : 0,
            }}
          >
            {FormComponent && (
              <FormComponent prepareFieldName={prepareFieldName} />
            )}
          </motion.div>
        </Box>
      </Card>
    </Box>
  );
}

export default ContentComponentsForm;
