import { ColumnDef } from '@tanstack/react-table';
import SchedulePreview from 'components/Schedule/SchedulePreview';
import { dateRangePrettierNoTime } from 'utils/date';
import { HOURS_KIND } from 'components/Schedule/constants';
import WYSIWYGContent from 'components/WYSIWYGContent';

function GetColumns(): ColumnDef<EventItem, string>[] {
  return [
    {
      header: 'Id',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Nazwa imprezy',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Data wydarzenia',
      cell: ({
        row: {
          original: { dateFrom, dateTo },
        },
      }) => dateRangePrettierNoTime(dateFrom?.toString(), dateTo?.toString()),
    },
    {
      header: 'Budynek powiązany',
      cell: ({
        row: {
          original: { venues },
        },
      }) => venues.map((venue) => venue.name).join(', '),
    },
    {
      header: 'Opis',
      accessorKey: 'description',
      cell: (info) => <WYSIWYGContent content={info.getValue()} isTruncated />,
    },
    {
      header: 'Godziny',
      cell: ({
        row: {
          original: { hoursKind, schedules, venues },
        },
      }) => (
        <SchedulePreview
          days={
            hoursKind === HOURS_KIND.OBJECT ? venues?.[0]?.schedules : schedules
          }
        />
      ),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
