import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Stack } from '@chakra-ui/react';
import Select from 'components/Form/Select';
import Input from 'components/Form/Input';
import { useGetLinkTypes } from 'api/linkTypes';

type LinkCreatorProps = {
  prepareFieldName: (name: string) => string;
  component: string;
};

function LinkCreator({ prepareFieldName, component }: LinkCreatorProps) {
  const { control } = useFormContext();

  const linkType = useWatch({
    control,
    name: prepareFieldName('linkParametersAttributes[0].linkType'),
  });

  const shouldRenderParamsInput = useMemo(
    () => linkType?.includes('param_value'),
    [linkType],
  );

  const { isLoading: isFetching, data: linkTypes = [] } = useGetLinkTypes();

  const options = useMemo(() => {
    const componentLinkTypes = linkTypes?.filter(
      (link) => link?.component === component,
    );
    return componentLinkTypes?.map((type) => ({
      label: type.name,
      value: type.value,
    }));
  }, [component, linkTypes]);

  return (
    <Stack spacing={8}>
      <Select
        label="Parametr linku"
        placeholder="Wybierz parametr"
        name={prepareFieldName('linkParametersAttributes[0].linkType')}
        isLoading={isFetching}
        options={options}
      />
      {shouldRenderParamsInput && (
        <Input
          label="Wartość parametru"
          name={prepareFieldName('linkParametersAttributes[0].linkParamValue')}
        />
      )}
    </Stack>
  );
}

export default LinkCreator;
