import { FunctionComponent } from 'react';
import * as yup from 'yup';
import ImageGallerySection from 'components/Content/Sections/ImageGallerySection';
import TicketsSection from 'components/Content/Sections/TicketsSection';
import HeaderSection from 'components/Content/Sections/HeaderSection';
import TitleSection from 'components/Content/Sections/TitleSection';
import DescriptionSection from 'components/Content/Sections/DescriptionSection';
import AudioSection from 'components/Content/Sections/AudioSection';
import VideoSection from 'components/Content/Sections/VideoSection';
import ImageSection from 'components/Content/Sections/ImageSection';
import CarouselSection from 'components/Content/Sections/CarouselSection';
import ContainerSection from 'components/Content/Sections/ContainerSection';
import { ContentContainer } from 'types/contentComponent';
import { imageGallerySchema } from 'components/ImageGallery/constants';
import { ERROR_MESSAGES, LINK_PARAMETERS_SCHEMA } from 'utils/form';

export const FormComponents: {
  [key: string]: FunctionComponent<any>;
} = {
  image_gallery: ImageGallerySection,
  tickets: TicketsSection,
  header: HeaderSection,
  title: TitleSection,
  description_field: DescriptionSection,
  audio: AudioSection,
  video: VideoSection,
  image: ImageSection,
  carousel: CarouselSection,
  container: ContainerSection,
};

export const DEFAULT_FORM_VALUES: { [key: string]: any } = {
  image_gallery: {
    galleryHeader: '',
    imagesAttributes: [{ image: null, description: '', position: 0 }],
  },
  tickets: {
    ticketsInfo: '',
    ticketsTeaser: '',
    freeEntry: false,
    ticketsLink: '',
  },
  header: {
    title: '',
  },
  description_field: {
    content: '',
  },
  audio: {
    audio: null,
  },
  video: {
    video: null,
  },
  image: {
    imagesAttributes: [{ image: null, position: 0 }],
  },
  carousel: {
    title: '',
    linkParametersAttributes: [{ linkType: null }],
  },
  container: {
    title: '',
    imagesAttributes: [{ image: null, position: 0 }],
    header: '',
    content: '',
    buttonText: '',
    linkParametersAttributes: [{ linkType: null }],
  },
};

export const VALIDATION_SCHEMA: { [key: string]: any } = {
  image_gallery: {
    galleryHeader: yup
      .string()
      .nullable()
      .notRequired()
      .validateMaxStringLength(),
    imagesAttributes: yup.array().of(yup.object().shape(imageGallerySchema)),
  },
  tickets: {
    ticketsInfo: yup.string().nullable().notRequired(),
    ticketsTeaser: yup
      .string()
      .validateMaxStringLength()
      .when(['freeEntry'], ([freeEntry]) =>
        freeEntry
          ? yup.string().nullable().notRequired()
          : yup.string().trim().required(ERROR_MESSAGES.required),
      ),
    freeEntry: yup.boolean().notRequired(),
    ticketsLink: yup
      .string()
      .nullable()
      .notRequired()
      .validateMaxStringLength()
      .when({
        is: (ticketsLink: string) => ticketsLink,
        then: (schema) => schema.validateLink(),
      }),
  },
  header: {
    title: yup
      .string()
      .trim()
      .required(ERROR_MESSAGES.required)
      .validateMaxStringLength(),
  },
  description_field: {
    content: yup.string().trim().required(ERROR_MESSAGES.required),
  },
  audio: {
    audio: yup.mixed().nullable().required(ERROR_MESSAGES.required),
  },
  video: {
    video: yup.mixed().nullable().required(ERROR_MESSAGES.required),
  },
  image: {
    imagesAttributes: yup.array().of(
      yup.object().shape({
        image: yup.mixed().nullable().required(ERROR_MESSAGES.required),
      }),
    ),
  },
  carousel: {
    title: yup.string().nullable().notRequired().validateMaxStringLength(),
    linkParametersAttributes: yup
      .array()
      .of(yup.object().shape(LINK_PARAMETERS_SCHEMA)),
  },
  container: {
    title: yup.string().nullable().notRequired().validateMaxStringLength(),
    imagesAttributes: yup.array().of(
      yup.object().shape({
        image: yup.mixed().nullable().notRequired(),
      }),
    ),
    header: yup
      .string()
      .trim()
      .nullable()
      .required(ERROR_MESSAGES.required)
      .validateMaxStringLength(),
    content: yup.string().trim().nullable().required(ERROR_MESSAGES.required),
    buttonText: yup.string().nullable().notRequired().validateMaxStringLength(),
    linkParametersAttributes: yup
      .array()
      .of(yup.object().shape(LINK_PARAMETERS_SCHEMA)),
  },
};

export const CONTENT_CONTAINER_SCHEMA = yup.lazy((container) =>
  yup.object().shape({
    kind: yup.string().trim().nullable().required(ERROR_MESSAGES.required),
    ...VALIDATION_SCHEMA[container?.kind],
  }),
);

export const parseContentComponents = (container: any) => {
  const result: Record<string, any> = { ...container };

  result.contentContainersAttributes = container?.contentContainers?.map(
    (component: ContentContainer) => ({
      ...component,
      imagesAttributes: component?.images,
      linkParametersAttributes: component?.linkParameters,
      kind: component?.componentKind,
    }),
  );

  delete result?.contentContainers;
  return result;
};
