/* eslint-disable no-console, prefer-destructuring */
const TRANSFORMED_FIELDS = [
  'images',
  'commonImages',
  'entriesImages',
  'schedules',
  'linkParameters',
  'contentContainers',
];

const transformErrors: (
  errors: { [key: string]: string[] },
  index: number | null,
) => {
  [key: string]: string;
} = (errors) => {
  try {
    if (errors) {
      const err: { [key: string]: string } = {};

      Object.keys(errors).forEach((field: any) => {
        const fieldName: string = field;

        if (
          Array.isArray(errors[field]) &&
          !TRANSFORMED_FIELDS.includes(field)
        ) {
          [err[fieldName]] = errors[field];
        } else {
          Object.keys(errors[field]).forEach((nested: any) => {
            const nestedFields = errors[field][nested];

            if (Array.isArray(nestedFields)) {
              err[`${fieldName}Attributes.${nested}`] = nestedFields;
            } else {
              Object.keys(nestedFields).forEach((nestedFieldName: any) => {
                err[`${fieldName}Attributes.${nested}.${nestedFieldName}`] =
                  errors[field][nested][nestedFieldName][0];
              });
            }
          });
        }
      });

      return err;
    }
  } catch (error) {
    console.log(error);
  }

  return {};
};

export default transformErrors;
