import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { serialize } from 'object-to-formdata';
import HeaderNavigation from 'components/HeaderNavigation';
import { FORM_MAX_WIDTH } from 'utils/constants';
import FooterButtons, { FOOTER_HEIGHT } from 'components/FooterButtons';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';
import transformErrors from 'utils/api';
import LoadingIndicator from 'components/LoadingIndicator';
import ComponentPreview from 'components/PreviewWrapper';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { convertKeysToSnakeCase } from 'utils/helpers';
import parseDefaultValues from 'pages/Dashboard/Containers/Form/Edit/parseDefaultValues';
import { ContentContainer } from 'types/contentComponent';
import {
  CONTENT_CONTAINER_SCHEMA,
  DEFAULT_FORM_VALUES,
} from 'components/Content/constants';
import { useGetDashboardContainer } from 'api/dashboard';
import ComponentWrapper from 'pages/Dashboard/Containers/Form/Edit/components/ComponentWrapper';
import { prepareDashboardContainer } from 'pages/Dashboard/Containers/Form/constants';
import FormWrapper from 'components/FormWrapper';

function ContainerEdit() {
  const { dashboardId = '', id = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const methods = useForm<ContentContainer>({
    mode: 'onChange',
    resolver: yupResolver(CONTENT_CONTAINER_SCHEMA as any),
  });

  const { handleSubmit, setError, reset } = methods;

  const { data: { data: dashboardContainer } = {}, isFetching } =
    useGetDashboardContainer(+dashboardId, +id);

  const { mutate: editDashboardContainer, isLoading: isEditing } = useMutation({
    mutationKey: 'dashboardContainersMutation',
    mutationFn: (data: ContentContainer) => {
      const snakePayload = convertKeysToSnakeCase(
        prepareDashboardContainer(data),
      );
      const formData = serialize(
        snakePayload,
        SERIALIZER_OPTIONS,
        new FormData(),
        'content_container',
      );
      return API.put(
        APIRoutes.dashboard.containers.byId(+dashboardId, +id),
        formData,
      );
    },
    onSuccess: () => {
      toast.success('Zmiany w elemencie zostały zastosowane');
      navigate(ROUTES.dashboard.base);
      queryClient.invalidateQueries('dashboardContainers');
    },
    onError: ({ errors }) => {
      const transformedErrors = transformErrors(errors, null);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      toast.error('Wystąpił problem podczas edycji elemenentu');
    },
  });

  const onSubmit = handleSubmit(async (data: ContentContainer) =>
    editDashboardContainer(data),
  );

  useEffect(() => {
    if (dashboardContainer) {
      reset({
        ...DEFAULT_FORM_VALUES[dashboardContainer?.componentKind],
        ...parseDefaultValues(dashboardContainer),
      });
    }
  }, [dashboardContainer, reset]);

  const isLoading = isFetching || isEditing;

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: 'Dashboard',
          to: ROUTES.dashboard.base,
        }}
        crumbs={[{ to: '', label: 'Edytuj element' }]}
      />
      {isFetching && <LoadingIndicator />}
      <FormWrapper {...methods}>
        <Flex gap={4} mb={4} alignItems="flex-start" pb={FOOTER_HEIGHT}>
          <Box
            w="100%"
            as="form"
            id="container-editor"
            onSubmit={onSubmit}
            maxW={FORM_MAX_WIDTH}
          >
            {dashboardContainer && (
              <ComponentWrapper
                isLoading={isLoading}
                kind={dashboardContainer?.componentKind}
              />
            )}
          </Box>
          <ComponentPreview kind="dashboard_container" isEditMode />
        </Flex>
      </FormWrapper>
      <FooterButtons
        isLoading={isLoading}
        formId="container-editor"
        onCancel={() => navigate(-2)}
      />
    </Box>
  );
}

export default ContainerEdit;
