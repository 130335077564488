import { Text } from '@chakra-ui/react';
import { PreviewComponentProps } from 'types/common';

function TitlePreview({ formValues }: PreviewComponentProps) {
  return (
    <Text fontSize={17} fontWeight={600} lineHeight="24px" w="100%">
      {formValues?.title || 'Tu pojawi się Twój tytuł'}
    </Text>
  );
}

export default TitlePreview;
