import { useIsMutating } from 'react-query';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Table from 'pages/Events/AttractionFacilities/Listing/components/Table';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import LoadingIndicator from 'components/LoadingIndicator';
import HeaderNavigation from 'pages/Events/AttractionFacilities/Listing/components/HeaderNavigation';
import useQueryParams from 'utils/useQueryParams';
import { useGetEvent, useGetEventAttractionFacilities } from 'api/events';

function EventAttractionFacilities() {
  const { id = '' } = useParams();
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
    'q[attraction_kind_eq]': 'agenda',
  });

  const isMutating = !!useIsMutating({
    mutationKey: ['eventAttractionFacilitiesMutation'],
  });

  const { data: { data: event } = {}, isFetching: isFetchingEvent } =
    useGetEvent(+id);

  const {
    isFetching: isFetchingAttractionFacilities,
    data: { data: attractionFacilities, totals } = { data: [], totals: 0 },
  } = useGetEventAttractionFacilities(+id, queryString);

  const showLoading =
    isMutating || isFetchingEvent || isFetchingAttractionFacilities;

  return (
    <Box mb={FOOTER_HEIGHT}>
      <HeaderNavigation name={event?.name!} id={id} />
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={attractionFacilities} eventId={+id} />}
      <Footer totals={totals} footerButtons={null} />
    </Box>
  );
}

export default EventAttractionFacilities;
