export const LANGUAGES_LABELS: {
  [K: string]: string;
} = {
  pl: 'polski',
  en: 'angielski',
};

export const LANGUAGES_OPTIONS = Object.entries(LANGUAGES_LABELS).map(
  ([key, value]) => ({ value: key, label: value }),
);
