import { useMemo } from 'react';
import ExtendedSelect from 'components/Form/Select/ExtendedSelect';
import { useGetEvent } from 'api/events';

interface EventAttractionsSelectProps {
  name: string;
  eventId: number;
}

function EventAttractionsSelect({
  name,
  eventId,
}: EventAttractionsSelectProps) {
  const { data: { data: event } = {}, isFetching: isFetchingEvent } =
    useGetEvent(eventId);

  const options = useMemo(
    () =>
      event?.attractionFacilities?.map((attractionFacility) => ({
        label: attractionFacility?.attraction?.name,
        value: attractionFacility.attractionId,
      })),
    [event],
  );

  return (
    <ExtendedSelect
      label="Atrakcje powiązane"
      placeholder="Wybierz atrakcje"
      name={name}
      isLoading={isFetchingEvent}
      options={options || []}
      isMulti
    />
  );
}

export default EventAttractionsSelect;
