import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CloseButton from 'components/Toast/CloseButton';

const StyledContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    text-align: left;
    align-items: center;
    padding: 16px 32px;
    gap: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  .Toastify__toast-body {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    padding: 0;
  }
  .Toastify__toast--success {
    color: black;
    background: #49d16f;
  }
  .Toastify__toast--error {
    color: white;
    background: #f02b02;
  }
`;

function CustomToastContainer() {
  return (
    <StyledContainer
      style={{ width: '75%' }}
      closeButton={<CloseButton />}
      hideProgressBar
      position="bottom-center"
      icon={false}
      autoClose={5000}
    />
  );
}

export default CustomToastContainer;
