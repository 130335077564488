import { Button, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useAppContext } from 'context/AppContext';
import {
  SIDEBAR_CLOSED_WIDTH,
  SIDEBAR_OPENED_WIDTH,
} from 'components/Layout/constants';

export const FOOTER_HEIGHT = '88px';

interface FooterButtonsProps {
  isLoading: boolean;
  onCancel: () => void;
  isCreate?: boolean;
  formId: string;
  okButtonText?: string;
  cancelButtonText?: string;
}

function FooterButtons({
  isLoading,
  onCancel,
  isCreate,
  formId,
  okButtonText,
  cancelButtonText,
}: FooterButtonsProps) {
  const { isSidebarExpanded } = useAppContext();

  return (
    <motion.div
      hidden={false}
      initial={false}
      animate={{
        left: isSidebarExpanded ? SIDEBAR_OPENED_WIDTH : SIDEBAR_CLOSED_WIDTH,
      }}
      style={{
        width: '100%',
        background: 'white',
        overflow: 'hidden',
        position: 'fixed',
        zIndex: 100,
        left: 0,
        height: FOOTER_HEIGHT,
        bottom: 0,
      }}
    >
      <Flex w="100%" h="100%" alignItems="center" p={6} gap={4}>
        <Button type="submit" form={formId} isLoading={isLoading}>
          {okButtonText || (isCreate ? 'UTWÓRZ' : 'ZAPISZ')}
        </Button>
        <Button variant="link" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </Flex>
    </motion.div>
  );
}

FooterButtons.defaultProps = {
  isCreate: false,
  okButtonText: '',
  cancelButtonText: 'ANULUJ',
};

export default FooterButtons;
