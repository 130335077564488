import { Navigate, Outlet } from 'react-router-dom';
import ROUTES from 'app/routes';
import { useAuth } from 'context/AuthContext';

function PrivateRoutes() {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Outlet /> : <Navigate to={ROUTES.auth.login} />;
}

export default PrivateRoutes;
