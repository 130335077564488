/* eslint-disable @typescript-eslint/dot-notation */
import {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
  ReactNode,
} from 'react';
import { noop } from 'lodash';
import { useQueryClient } from 'react-query';

import { API, APIRoutes, destroySession, setHeaders } from 'api';
import { setCredentials, getCredentials, TOKEN_KEY } from 'utils/auth';

const fetchUser = async () =>
  API.get(APIRoutes.auth.currentUser).then((resp) => resp?.data?.data);

const AuthContextDefaultState: AuthContextType = {
  signIn: async () => {},
  logout: noop,
  user: null,
  isAuthenticated: false,
  isInitialized: false,
  initializeUser: async () => {},
};
type Props = {
  children: ReactNode;
};

const AuthContext = createContext(AuthContextDefaultState);

function AuthProvider({ children }: Props) {
  const queryClient = useQueryClient();

  const [isInitialized, setIsInitialized] = useState(false);
  const [user, setUser] = useState<any>(null);
  const isAuthenticated = !!localStorage.getItem(TOKEN_KEY);

  const logout = useCallback(async () => {
    destroySession();
    queryClient.cancelQueries();
    queryClient.removeQueries();
    setUser(null);
    setIsInitialized(false);
  }, [queryClient]);

  const initializeUser = useCallback(async () => {
    const userData = await fetchUser();
    setUser(userData);
  }, []);

  const signIn = useCallback<SignIn>(
    async (payload) => {
      if (payload) {
        const { headers } = await API.post(APIRoutes.auth.signIn, payload);

        const authHeaders: Credentials = {
          client: headers['client'],
          accessToken: headers['access-token'],
          tokenType: headers['token-type'],
          uid: headers['uid'],
          acceptLanguage: 'pl',
        };

        setCredentials(authHeaders);
        setHeaders(authHeaders);

        await initializeUser();
        setIsInitialized(true);
      }
    },
    [initializeUser],
  );

  const restoreUser = useCallback(async () => {
    const credentials = getCredentials();

    if (
      credentials.client &&
      credentials.accessToken &&
      credentials.tokenType &&
      credentials.uid
    ) {
      setHeaders(credentials);
      await initializeUser();
      setIsInitialized(true);
    }
  }, [initializeUser]);

  useEffect(() => {
    (async () => {
      await restoreUser();
    })();
  }, [restoreUser]);

  const globalContextValue = useMemo(
    () => ({
      ...AuthContextDefaultState,
      signIn,
      logout,
      user,
      isAuthenticated,
      isInitialized,
      initializeUser,
    }),
    [signIn, logout, user, isAuthenticated, isInitialized, initializeUser],
  );

  return (
    <AuthContext.Provider value={globalContextValue}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
