/* eslint-disable react/no-array-index-key */
import { Box, TabPanel, VStack, Text } from '@chakra-ui/react';
import { isEmpty, isNumber } from 'lodash';
import LoadingSpinner from 'components/FormSpinner';
import {
  useGetMobileVenueAttractions,
  useGetMobileVenuePathways,
} from 'api/venues';
import PathwayCard from 'components/Cards/PathwayCard';
import AttractionsCarousel from 'components/Previews/AttractionsCarousel';
import EmptySection from 'components/EmptySection';
import { ATTRACTION_KIND } from 'pages/Attractions/Form/constants';

interface AttractionsTabProps {
  venueId: number;
  isActive: boolean;
  pathwaysDescription: string;
}

function AttractionsTab({
  venueId,
  isActive,
  pathwaysDescription,
}: AttractionsTabProps) {
  const isQueryEnabled = isNumber(venueId) && isActive;

  const { isFetching: isFetchingPathways, data: pathways = [] } =
    useGetMobileVenuePathways(venueId, isQueryEnabled);

  const {
    isFetching: isFetchingConstantAttractions,
    data: { data: constantAttractions = [] } = {},
  } = useGetMobileVenueAttractions(
    venueId,
    ATTRACTION_KIND.CONSTANT,
    isQueryEnabled,
  );

  const {
    isFetching: isFetchingTemporaryAttractions,
    data: { data: temporaryAttractions = [] } = {},
  } = useGetMobileVenueAttractions(
    venueId,
    ATTRACTION_KIND.TEMPORARY,
    isQueryEnabled,
  );

  return (
    <TabPanel>
      {(isFetchingPathways ||
        isFetchingConstantAttractions ||
        isFetchingTemporaryAttractions) && <LoadingSpinner />}
      <VStack spacing={8}>
        <AttractionsCarousel
          label="ATRAKCJE"
          attractions={constantAttractions}
        />
        <AttractionsCarousel
          label="WYDARZENIA"
          attractions={temporaryAttractions}
        />
        <Box w="100%">
          <Text w="100%" variant="h2" mb={4}>
            WYBIERZ ŚCIEŻKĘ ZWIEDZANIA
          </Text>
          <Text fontSize="17px" lineHeight="24px" w="100%" mb={6}>
            {pathwaysDescription || 'Tu pojawi się opis'}
          </Text>
          {isEmpty(pathways) ? (
            <EmptySection text="Aby dodać ścieżki zwiedzania, skorzystaj z menu funkcjonalnego, które jest wyświetlone w ostatniej kolumnie tabeli “Obiekty”" />
          ) : (
            <VStack w="100%" spacing={4}>
              {pathways?.map((pathway, index) => (
                <Box
                  w="100%"
                  border="solid 1px"
                  borderRadius="8px"
                  key={`pathway_card_${index}`}
                >
                  <PathwayCard
                    name={pathway?.name}
                    attractions={Number(pathway?.pathwayPoints?.length)}
                    duration={Number(pathway?.duration)}
                  />
                </Box>
              ))}
            </VStack>
          )}
        </Box>
      </VStack>
    </TabPanel>
  );
}

export default AttractionsTab;
