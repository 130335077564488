import { flexRender, Row } from '@tanstack/react-table';
import { toast } from 'react-toastify';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { generatePath, Link } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import CustomModal from 'components/CustomModal';
import Td from 'pages/Users/Listing/components/Table/components/Td';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';

interface TableRowProps {
  row: Row<User>;
}
function TableRow({ row }: TableRowProps) {
  const { id } = row.original;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const queryClient = useQueryClient();

  const { mutate: removeUser } = useMutation(
    () => API.delete(APIRoutes.users.byId(id!)),
    {
      mutationKey: 'usersMutation',
      onSuccess: () => {
        toast.success('Pomyślnie usunięto użytkownika');
        onClose();
        queryClient.invalidateQueries('users');
      },
      onError: () => {
        toast.error('Wystąpił problem podczas usuwania użytkownika');
      },
    },
  );

  return (
    <tr key={`row_${id}`}>
      {row.getVisibleCells().map((cell, idx, { length }) => {
        if (idx + 1 === length) return null;
        return (
          <Td key={`row_cell_${cell.id}_${cell}`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
      <Td>
        <>
          <CustomModal
            confirmationText="Usuń"
            onAccept={removeUser}
            cancellationText="Anuluj"
            onClose={onClose}
            isOpen={isOpen}
            title="Czy na pewno chcesz trwale usunąć tego użytkownika?"
          />
          <Tooltip label="Edytuj" openDelay={1000}>
            <IconButton
              width="56px"
              height="40px"
              icon={<IconPencil />}
              aria-label="visiblity"
              variant="ghost"
              as={Link}
              to={generatePath(ROUTES.users.edit, {
                id,
              })}
            />
          </Tooltip>
          <Tooltip label="Usuń" openDelay={1000}>
            <IconButton
              width="56px"
              height="40px"
              icon={<IconTrash />}
              aria-label="visiblity"
              variant="ghost"
              onClick={onOpen}
            />
          </Tooltip>
        </>
      </Td>
    </tr>
  );
}

export default TableRow;
