import { AspectRatio, Center } from '@chakra-ui/react';
import MediaPlaceholder from 'components/MediaPlaceholder';
import { PreviewComponentProps } from 'types/common';
import useImage from 'utils/useImage';

function ImagePreview({ formValues }: PreviewComponentProps) {
  const imagePreview = useImage(formValues?.imagesAttributes?.[0]?.image);

  return (
    <AspectRatio w="100%" ratio={1.37}>
      <Center
        bgColor="complementary.gray"
        bgImage={imagePreview}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="50% 50%"
        borderRadius="8px"
      >
        {!imagePreview && (
          <MediaPlaceholder text="Tu pojawi się Twoje zdjęcie" />
        )}
      </Center>
    </AspectRatio>
  );
}

export default ImagePreview;
