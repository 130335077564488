const Tag = {
  variants: {
    outlined: {
      container: {
        borderColor: 'complementary.black',
        color: 'complementary.black',
        backgroundColor: '#fff',
      },
    },
    solid: {
      container: {
        borderColor: 'transparent',
        backgroundColor: 'complementary.black',
        color: '#fff',
      },
    },
  },
  baseStyle: {
    container: {
      fontSize: '14px',
      lineHeight: '20px',
      padding: '4px 12px',
      borderRadius: '8px',
      border: 'solid 1px',
    },
    closeButton: {
      marginStart: '4px',
      opacity: 1,
      cursor: 'pointer',
    },
  },
};

export default Tag;
