import { FunctionComponent, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import Previews from 'components/Content/Previews/constants';

interface ShowPreviewProp {
  data: any;
  children: string;
}

function ShowPreview({ data, children }: ShowPreviewProp) {
  const [previewIsVisible, setPreviewIsVisible] = useState(false);
  let debounce: ReturnType<typeof setTimeout>;

  const PreviewComponent: FunctionComponent<any> =
    Previews[data?.componentKind];

  if (!PreviewComponent) {
    return <Text>Podgląd niedostępny</Text>;
  }

  const onHover = () => {
    debounce = setTimeout(() => {
      setPreviewIsVisible(true);
    }, 300);
  };

  const onLeave = () => {
    setPreviewIsVisible(false);
    clearTimeout(debounce);
  };

  return (
    <Box position="relative" onMouseEnter={onHover} onMouseLeave={onLeave}>
      {previewIsVisible && (
        <Box
          top={0}
          left={0}
          zIndex={1000}
          position="absolute"
          transform="translate(-35%, -10%)"
          p={4}
          w="423px"
          maxW="423px"
        >
          <Box
            px={6}
            py={8}
            bg="white"
            boxShadow="0 4px 10px 0 rgba(0, 0, 0, 0.25)"
            textAlign="left"
            overflowX="auto"
            color="initial"
            maxH="80vh"
          >
            <Text fontSize="17px" fontWeight={600} mb={4}>
              {children}
            </Text>
            <PreviewComponent formValues={data} isDashboardPreview />
          </Box>
        </Box>
      )}
      <Text textDecoration="underline" cursor="pointer">
        {children}
      </Text>
    </Box>
  );
}

export default ShowPreview;
