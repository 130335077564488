import { useEffect } from 'react';
import { FormProvider, FormProviderProps } from 'react-hook-form';
import { toast } from 'react-toastify';

function FormWrapper({ ...props }: FormProviderProps<any>) {
  const { errors, isSubmitted } = props.formState;
  useEffect(() => {
    if (isSubmitted && Object.keys(errors).length) {
      toast.error(
        'Formularz zawiera błędy. Sprawdź poprawność i spróbuj ponownie.',
      );
    }
  }, [errors, isSubmitted]);

  return <FormProvider {...props} />;
}

export default FormWrapper;
