import { useMemo } from 'react';
import Select from 'components/Form/Select';
import { useGetAllVenues } from 'api/venues';

interface VenuesSelectProps {
  name: string;
  label: string;
  isMulti?: boolean;
}

function VenuesSelect({ name, label, isMulti }: VenuesSelectProps) {
  const { isFetching, data: { data: venues } = { data: [] } } =
    useGetAllVenues();

  const options = useMemo(
    () =>
      venues?.map((venue) => ({
        label: venue.name,
        value: venue.id,
      })),
    [venues],
  );

  return (
    <Select
      label={label}
      placeholder="Wybierz obiekt"
      name={name}
      isLoading={isFetching}
      options={options}
      isMulti={isMulti}
      isClearable
    />
  );
}

VenuesSelect.defaultProps = {
  isMulti: false,
};

export default VenuesSelect;
