/* eslint-disable no-underscore-dangle, @typescript-eslint/naming-convention */
import * as yup from 'yup';
import differenceInDays from 'date-fns/differenceInDays';
import endOfDay from 'date-fns/endOfDay';
import { isValidTime, timeToDuration } from 'utils/date';
import {
  CONTENT_CONTAINER_SCHEMA,
  VALIDATION_SCHEMA,
} from 'components/Content/constants';
import {
  HOURS_KIND,
  prepareSchedulesAttributes,
  schedulesAttributesDefaultValues,
  schedulesAttributesSchema,
} from 'components/Schedule/constants';
import { ERROR_MESSAGES } from 'utils/form';

export enum ATTRACTION_KIND {
  TEMPORARY = 'temporary',
  CONSTANT = 'constant',
  AGENDA = 'agenda',
}

const ATTRACTION_SCHEMA = {
  name: yup
    .string()
    .trim()
    .nullable()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  description: yup.string().trim().nullable().required(ERROR_MESSAGES.required),
  venueId: yup.number().nullable().required(ERROR_MESSAGES.required),
  poiId: yup.string().nullable().required(ERROR_MESSAGES.required),
  planId: yup.string().nullable().notRequired(),
  imagesAttributes: yup.array().of(
    yup.object().shape({
      image: yup.mixed().nullable().required(ERROR_MESSAGES.required),
      position: yup.number().required(),
    }),
  ),
  contentContainersAttributes: yup.array().of(CONTENT_CONTAINER_SCHEMA),
  pathwaysDescription: yup.string().nullable().notRequired(),
  active: yup.boolean().required(ERROR_MESSAGES.required),
  pushEnabled: yup.boolean(),
  pushTitle: yup
    .string()
    .nullable()
    .when('pushEnabled', {
      is: (pushEnabled: boolean) => pushEnabled,
      then: (schema) => schema.required(ERROR_MESSAGES.required),
    }),
  pushContent: yup
    .string()
    .nullable()
    .when('pushEnabled', {
      is: (pushEnabled: boolean) => pushEnabled,
      then: (schema) => schema.required(ERROR_MESSAGES.required),
    }),
};

const TEMPORARY_ATTRACTION_SCHEMA = {
  ...ATTRACTION_SCHEMA,
  duration: yup
    .string()
    .nullable()
    .required(ERROR_MESSAGES.required)
    .test({
      test(duration, context) {
        if (duration && !isValidTime(duration)) {
          return context.createError({
            message: ERROR_MESSAGES.invalidFormat,
          });
        }
        return true;
      },
    }),
  activeFrom: yup.string().trim().nullable().required(ERROR_MESSAGES.required),
  activeTo: yup
    .string()
    .trim()
    .nullable()
    .required(ERROR_MESSAGES.required)
    .test(
      'dateToLaterThanFrom',
      ERROR_MESSAGES.dateToLaterThanFrom,
      (value, testContext) => {
        const { activeFrom, kind } = testContext.parent;
        if (activeFrom && value && kind === ATTRACTION_KIND.TEMPORARY) {
          return differenceInDays(new Date(value), new Date(activeFrom)) >= 0;
        }
        return true;
      },
    ),
  ...VALIDATION_SCHEMA.tickets,
  hoursKind: yup.string().trim().nullable().required(ERROR_MESSAGES.required),
  schedulesAttributes: schedulesAttributesSchema,
  cyclic: yup.boolean().required(ERROR_MESSAGES.required),
};

export const BODY_SCHEMA: any = yup.lazy((container) =>
  yup.object().shape({
    kind: yup.string().trim().nullable().required(ERROR_MESSAGES.required),
    ...(container?.kind === ATTRACTION_KIND.CONSTANT && ATTRACTION_SCHEMA),
    ...(container?.kind === ATTRACTION_KIND.TEMPORARY &&
      TEMPORARY_ATTRACTION_SCHEMA),
  }),
);

const ATTRACTION_DEFAULT_VALUES = {
  active: true,
  name: '',
  description: '',
  venueId: null,
  planId: null,
  imagesAttributes: [{ image: null, position: 0 }],
  contentContainersAttributes: [],
  pathwaysDescription: '',
  poiId: null,
};

export const DEFAULT_VALUES: {
  [key: string]: object;
} = {
  constant: ATTRACTION_DEFAULT_VALUES,
  temporary: {
    ...ATTRACTION_DEFAULT_VALUES,
    hoursKind: HOURS_KIND.OBJECT,
    schedulesAttributes: schedulesAttributesDefaultValues,
    duration: '',
    dateFrom: null,
    dateTo: null,
    ticketsInfo: '',
    ticketsTeaser: '',
    freeEntry: false,
    ticketsLink: '',
    cyclic: false,
  },
};

export const preparePayload = (values: Attraction) => {
  const data = { ...values };

  data.attractionVenueAttributes = {
    id: values?.attractionFacilityId,
    attractionableId: values?.venueId,
    attractionableType: 'Venue',
  };

  if (!values?.venueId) {
    if (values?.attractionFacilityId) {
      data.attractionVenueAttributes._destroy = true;
    } else {
      delete data.attractionVenueAttributes;
    }
  }

  if (values?.kind === ATTRACTION_KIND.TEMPORARY) {
    data.duration = values?.duration
      ? timeToDuration(values.duration.toString())
      : '';
    data.activeTo = endOfDay(new Date(values?.activeTo)).toString();
    return prepareSchedulesAttributes(data);
  }

  return data;
};
