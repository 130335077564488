import * as yup from 'yup';
import { Box, Button, VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Input from 'components/Form/Input';
import PasswordInput from 'components/Form/PasswordInput';
import { useAuth } from 'context/AuthContext';
import FormWrapper from 'components/FormWrapper';
import { ERROR_MESSAGES } from 'utils/form';

const schema = yup.object({
  email: yup
    .string()
    .trim()
    .email(ERROR_MESSAGES.invalidEmail)
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  password: yup
    .string()
    .trim()
    .required(ERROR_MESSAGES.required)
    .validatePassword()
    .validateMaxStringLength(),
});

type FormData = yup.InferType<typeof schema>;

function LoginForm() {
  const { signIn } = useAuth();

  const methods = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { handleSubmit, formState } = methods;

  const { isDirty, isValid, isSubmitting } = formState;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await signIn(data);
    } catch (err) {
      toast.error('Nieprawidłowe dane logowania');
    }
  });

  return (
    <FormWrapper {...methods}>
      <Box as="form" onSubmit={onSubmit}>
        <VStack spacing="36px" mb="44px">
          <Input
            name="email"
            label="E-mail"
            autoComplete="email"
            placeholder="Wpisz adres e-mail"
          />
          <PasswordInput
            name="password"
            label="Hasło"
            autoComplete="current-password"
          />
        </VStack>

        <Button
          isLoading={isSubmitting}
          isDisabled={!isDirty || !isValid}
          type="submit"
        >
          ZALOGUJ
        </Button>
      </Box>
    </FormWrapper>
  );
}

export default LoginForm;
