import { ColumnDef } from '@tanstack/react-table';
import ShowPreview from 'components/ShowPreview';
import { ContentContainer } from 'types/contentComponent';

export const KIND_DICT: {
  [K: string]: string;
} = {
  carousel: 'Karuzela',
  container: 'Kontener',
};

function GetColumns(): ColumnDef<ContentContainer, string>[] {
  return [
    {
      header: 'Id',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Typ',
      accessorKey: 'componentKind',
      cell: (info) => KIND_DICT[info.getValue()],
    },
    {
      header: 'Podgląd',
      cell: (info) => (
        <ShowPreview data={info.row.original}>Podgląd</ShowPreview>
      ),
    },
    {
      header: 'Nagłówek (opcjonalnie)',
      accessorKey: 'title',
      cell: (info) => info.getValue() || '-',
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
