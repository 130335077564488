const Textarea = {
  baseStyle: {
    borderColor: 'complementary.black',
  },
  variants: {
    outline: {
      padding: '12px',
      borderWidth: '1px',
      borderRadius: '4px',
      bg: 'white',
      color: 'complementary.black',
      fontWeight: 400,
      fontSize: '17px',
      lineHeight: '24px',
      minHeight: '68px',
      _focus: { bg: 'white' },
      _placeholder: { color: 'complementary.lightBlack' },
      _hover: {
        borderColor: 'complementary.black',
      },
      _invalid: {
        boxShadow: 'unset',
        borderColor: 'complementary.error',
      },
    },
  },
  defaultProps: {
    variant: 'outline',
    size: 'lg',
  },
};

export default Textarea;
