import styled from '@emotion/styled';

const Thead = styled.thead`
  z-index: 1;
  background-color: #e5e5e5;
  color: #0d0d0d;
  position: sticky;
  top: 0;
  padding: 2px 0;
`;

export default Thead;
