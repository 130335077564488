import { useIsMutating } from 'react-query';
import { Link } from 'react-router-dom';
import useQueryParams from 'utils/useQueryParams';
import ListingLayout from 'components/Layout/ListingLayout';
import ROUTES from 'app/routes';
import Table from 'pages/Events/Listing/components/Table';
import Footer from 'components/Footer';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetEvents } from 'api/events';

function Events() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });

  const isMutating = !!useIsMutating({
    mutationKey: ['eventsMutation'],
  });

  const {
    isFetching,
    data: { data: events, totals } = { data: [], totals: 0 },
  } = useGetEvents(queryString);

  const showLoading = isMutating || isFetching;

  return (
    <ListingLayout
      title="Imprezy"
      subtitle="Aby zdefiniować ścieżkę zwiedzania lub agendę, skorzystaj z menu funkcjonalnego umieszczonego na końcu tabeli"
      headerButtons={[
        {
          label: 'NOWA IMPREZA',
          as: Link,
          to: ROUTES.events.create,
        },
      ]}
      searchKey="name"
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={events} />}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default Events;
