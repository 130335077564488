import { Flex } from '@chakra-ui/react';
import DndSpacer from 'components/DndSpacer';

function TableDndSpacer() {
  return (
    <tr>
      <td colSpan={30}>
        <Flex h="16px" bg="#e5e5e5" paddingInline="7px" align="center">
          <DndSpacer />
        </Flex>
      </td>
    </tr>
  );
}

export default TableDndSpacer;
