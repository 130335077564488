import { Box } from '@chakra-ui/react';

type WYSIWYGContentProps = {
  content: string;
  placeholder?: string;
  isTruncated?: boolean;
};

function WYSIWYGContent({
  content,
  placeholder,
  isTruncated,
}: WYSIWYGContentProps) {
  return (
    <Box
      w="100%"
      dangerouslySetInnerHTML={{
        __html: content || placeholder || 'Tu pojawi się opis',
      }}
      noOfLines={isTruncated ? 8 : 0}
      sx={{
        '*': {
          all: 'revert',
        },
        p: {
          margin: 0,
        },
        'ul, ol': {
          padding: 0,
          margin: 0,
          listStylePosition: 'inside',
        },
      }}
    />
  );
}

WYSIWYGContent.defaultProps = {
  placeholder: '',
  isTruncated: false,
};

export default WYSIWYGContent;
