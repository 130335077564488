import omit from 'lodash/omit';

const PARSED_VALUES: string[] = ['schedules, entriesImages, commonImages'];

const parseDefaultValues = (venue: Venue) => {
  const result: Record<string, any> = { ...omit(venue, PARSED_VALUES) };

  result.schedulesAttributes = venue?.schedules;
  result.entriesImagesAttributes = venue?.entriesImages;
  result.commonImagesAttributes = venue?.commonImages;

  return result;
};

export default parseDefaultValues;
