import * as yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';

const Link = ReactQuill.Quill.import('formats/link');

const linkSchema = yup
  .string()
  .matches(/https:\/\//)
  .url();

const showErrorMessage = () =>
  toast.error(
    'Wprowadzony link jest nieprawidłowy. Pamiętaj, że link musi rozpoczynać się od "https://"',
    { toastId: 'editorLinkError' },
  );

class CustomLink extends Link {
  static create(value: string) {
    const node = super.create(value);
    linkSchema.isValid(value).then((isValid) => {
      if (!isValid) {
        showErrorMessage();
        node.removeAttribute('href');
      }
    });
    return node;
  }

  format(name: string, value: string) {
    super.format(name, value);
    if (name !== this.statics.blotName || !value) return;
    linkSchema.isValid(value).then((isValid) => {
      if (!isValid) {
        showErrorMessage();
        this.domNode.removeAttribute('href');
      }
    });
  }
}

export default CustomLink;
