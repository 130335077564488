import { Icon, IconProps } from '@chakra-ui/react';

function BrandLogo(props: IconProps) {
  return (
    <Icon
      width="288px"
      height="278.4px"
      viewBox="0 0 288 278.4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M92.942 116.815H65V66.093h40.808v9.574H74.554v31.574h18.388v9.574zM74.554 148.486v-18.664H65v28.286h40.808v-9.622H74.554zM223.048 158.108h-9.506V75.667h-9.698c-6.29 24.08-28.086 41.148-53.243 41.148h-10.226v-9.574h10.226c21.94 0 40.76-15.666 44.793-37.231l.72-3.917h26.982v92.015h-.048z"
        fill="#fff"
      />
      <path
        d="M163.66 75.667v-9.574c-23.573 0-43.017 18.084-45.417 41.148h-12.435v9.574h12.435c2.352 23.161 21.844 41.293 45.465 41.293v-9.574c-19.924 0-36.152-16.343-36.152-36.409-.048-20.115 16.18-36.458 36.104-36.458zM72.586 198.337v8.559h17.523v7.059H65v-12.233l-3.745 1.644v-7.253L65 194.469v-15.763h7.586v12.378l7.441-3.336v7.253l-7.441 3.336zM99.567 196.403v-.097c0-10.009 7.825-18.18 18.628-18.18 10.802 0 18.531 8.075 18.531 18.084v.096c0 10.009-7.825 18.181-18.627 18.181-10.803.048-18.532-8.075-18.532-18.084zm29.094 0v-.097c0-6.044-4.417-11.072-10.562-11.072-6.194 0-10.466 4.932-10.466 10.976v.096c0 6.044 4.416 11.073 10.562 11.073 6.193 0 10.466-4.932 10.466-10.976zM150.745 178.706h13.635c10.994 0 18.58 7.591 18.58 17.504v.096c0 9.913-7.586 17.601-18.58 17.601h-13.635v-35.201zm7.682 7.011v21.227h5.953c6.289 0 10.514-4.255 10.514-10.493v-.096c0-6.238-4.225-10.589-10.514-10.589h-5.953v-.049zM193.954 208.056l19.204-22.532H194.53v-6.77h28.518v5.899l-19.204 22.533h19.204v6.769h-29.094v-5.899zM124.436 168.552l-2.929 6.914h-8.209l2.928-6.914h8.21zM215.078 168.552l-2.928 6.914h-8.21l2.929-6.914h8.209z"
        fill="#fff"
      />
    </Icon>
  );
}

export default BrandLogo;
