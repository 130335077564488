/* eslint-disable react/no-array-index-key */
import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex } from '@chakra-ui/react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { serialize } from 'object-to-formdata';
import HeaderNavigation from 'components/HeaderNavigation';
import { FORM_MAX_WIDTH } from 'utils/constants';
import FooterButtons, { FOOTER_HEIGHT } from 'components/FooterButtons';
import FormBody from 'pages/ContentPages/Form/components/FormBody';
import { BODY_SCHEMA, DEFAULT_VALUES } from 'pages/ContentPages/Form/constants';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';
import transformErrors from 'utils/api';
import ComponentPreview from 'components/PreviewWrapper';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { convertKeysToSnakeCase } from 'utils/helpers';
import DndWrapper from 'components/DndWrapper';
import ContentComponentsForm from 'components/Content/ContentComponentsForm';
import AddButton from 'components/AddButton';
import FormWrapper from 'components/FormWrapper';

function ContentPageCreate() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let debounce: ReturnType<typeof setTimeout>;

  const methods = useForm<ContentPage>({
    mode: 'onChange',
    resolver: yupResolver(BODY_SCHEMA),
    defaultValues: DEFAULT_VALUES,
  });

  const { handleSubmit, setError, control } = methods;

  const {
    fields: containers,
    append,
    remove,
    move,
  } = useFieldArray({
    control,
    name: 'contentContainersAttributes',
    keyName: 'fieldId',
    shouldUnregister: true,
  });

  const addContainerHandler = useCallback(() => {
    append({ kind: '' });
  }, [append]);

  const reorderContainers = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      clearTimeout(debounce);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      debounce = setTimeout(() => move(dragIndex, hoverIndex), 500);
    },
    [move],
  );

  const { mutate: createContentPage, isLoading } = useMutation({
    mutationKey: 'contentPagesMutation',
    mutationFn: (data: ContentPage) => {
      const snakePayload = convertKeysToSnakeCase(data);
      const formData = serialize(
        snakePayload,
        SERIALIZER_OPTIONS,
        new FormData(),
        'content_page',
      );
      return API.post(APIRoutes.contentPages.index(), formData);
    },
    onSuccess: () => {
      toast.success('Strona została pomyślnie utworzona!');
      navigate(ROUTES.contentPages.base);
      queryClient.invalidateQueries('contentPages');
    },
    onError: (error) => {
      const transformedErrors = transformErrors(error as any, null);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      toast.error('Wystąpił problem podczas tworzenia strony');
    },
  });

  const onSubmit = handleSubmit(async (data: ContentPage) =>
    createContentPage(data),
  );

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: 'Kontent',
          to: ROUTES.contentPages.base,
        }}
        crumbs={[{ to: '', label: 'Dodaj stronę' }]}
      />
      <FormWrapper {...methods}>
        <Flex gap={4} mb={4} alignItems="flex-start" pb={FOOTER_HEIGHT}>
          <Box
            w="100%"
            as="form"
            id="content-page-creator"
            onSubmit={onSubmit}
            maxW={FORM_MAX_WIDTH}
          >
            <FormBody isLoading={isLoading} />
            {containers.map((container, index) => (
              <DndWrapper
                key={`content-page-creator-${container.fieldId}`}
                id={container.fieldId}
                isLoading={isLoading}
                index={index}
                reorderContainers={reorderContainers}
                removeContainer={remove}
                ContainerWrapper={ContentComponentsForm}
                multipleContainers
              />
            ))}
            <AddButton addHandler={addContainerHandler} mt={2} />
          </Box>
          <ComponentPreview kind="content_page" />
        </Flex>
      </FormWrapper>
      <FooterButtons
        isLoading={isLoading}
        isCreate
        formId="content-page-creator"
        onCancel={() => navigate(-2)}
      />
    </Box>
  );
}

export default ContentPageCreate;
