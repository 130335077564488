import { useMemo } from 'react';
import Select from 'components/Form/Select';
import { useGetEvent } from 'api/events';

interface EventVenuesSelectProps {
  name: string;
  eventId: number;
  isMulti?: boolean;
}

function EventVenuesSelect({ name, eventId, isMulti }: EventVenuesSelectProps) {
  const { data: { data: event } = {}, isFetching } = useGetEvent(eventId);

  const venuesOptions = useMemo(
    () =>
      event?.venues?.map((venue) => ({
        label: venue.name,
        value: venue.id,
      })),
    [event],
  );

  return (
    <Select
      label="Obiekt powiązany"
      placeholder="Wybierz obiekt"
      name={name}
      isLoading={isFetching}
      options={venuesOptions || []}
      isMulti={isMulti}
    />
  );
}

EventVenuesSelect.defaultProps = {
  isMulti: false,
};

export default EventVenuesSelect;
