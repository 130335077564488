/* eslint-disable react/no-array-index-key */
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import HeaderNavigation from 'components/HeaderNavigation';
import { FORM_MAX_WIDTH } from 'utils/constants';
import FooterButtons from 'components/FooterButtons';
import FormBody from 'pages/Users/Form/components/FormBody';
import { EDIT_SCHEMA, DEFAULT_VALUES } from 'pages/Users/Form/constants';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';
import transformErrors from 'utils/api';
import LoadingIndicator from 'components/LoadingIndicator';
import { useAuth } from 'context/AuthContext';
import { useGetUser } from 'api/users';
import FormWrapper from 'components/FormWrapper';

function UserEdit() {
  const { id = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user, initializeUser } = useAuth();

  const methods = useForm<UserPayload>({
    mode: 'onChange',
    resolver: yupResolver(EDIT_SCHEMA),
    defaultValues: DEFAULT_VALUES,
  });

  const { handleSubmit, setError, reset } = methods;

  const { data: { data: userData } = {}, isFetching } = useGetUser(+id);

  const { mutate: editUser, isLoading: isEditing } = useMutation(
    (data: UserPayload) =>
      API.put(APIRoutes.users.byId(+id), {
        admin: data,
      }),
    {
      mutationKey: 'usersMutation',
      onSuccess: (_data, payload) => {
        toast.success('Zmiany w użytkowniku zostały zastosowane');
        if (payload?.id === user?.id) {
          initializeUser();
        }
        navigate(ROUTES.users.base);
        queryClient.invalidateQueries('users');
      },
      onError: ({ errors }) => {
        const transformedErrors = transformErrors(errors, null);
        Object.keys(transformedErrors).forEach((field: string) => {
          setError(field as never, {
            type: 'custom',
            message: transformedErrors[field],
          });
        });
        toast.error('Wystąpił problem podczas edycji użytkownika');
      },
    },
  );

  const onSubmit = handleSubmit(async (data: UserPayload) => editUser(data));

  useEffect(() => {
    if (userData) reset({ ...DEFAULT_VALUES, ...userData });
  }, [userData, reset]);

  const isLoading = isFetching || isEditing;

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: 'Użytkownicy',
          to: ROUTES.users.base,
        }}
        crumbs={[{ to: '', label: 'Edytuj użytkownika' }]}
      />
      {isFetching && <LoadingIndicator />}
      <FormWrapper {...methods}>
        <Box
          as="form"
          id="user-editor"
          onSubmit={onSubmit}
          pb="120px"
          maxW={FORM_MAX_WIDTH}
        >
          <FormBody isLoading={isLoading} />
        </Box>
      </FormWrapper>
      <FooterButtons
        isLoading={isLoading}
        formId="user-editor"
        onCancel={() => navigate(-2)}
      />
    </Box>
  );
}

export default UserEdit;
