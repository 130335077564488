/* eslint-disable react/no-array-index-key */
import {
  Box,
  AspectRatio,
  Center,
  VStack,
  Text,
  HStack,
  Button,
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { IconClock, IconMapPin, IconRoute } from '@tabler/icons-react';
import Previews from 'components/Content/Previews/constants';
import useImage from 'utils/useImage';
import MediaPlaceholder from 'components/MediaPlaceholder';
import { ATTRACTION_KIND } from 'pages/Attractions/Form/constants';
import { prettierTime, timeToDuration } from 'utils/date';
import TicketsPreview from 'components/Content/Previews/TicketsPreview';
import SchedulePreview from 'components/Previews/SchedulePreview';
import { HOURS_KIND } from 'components/Schedule/constants';
import { useGetVenue } from 'api/venues';
import LoadingSpinner from 'components/FormSpinner';
import { PreviewComponentProps } from 'types/common';
import WYSIWYGContent from 'components/WYSIWYGContent';

function AttractionPreview({ formValues }: PreviewComponentProps) {
  const imagePreview = useImage(formValues?.imagesAttributes?.[0]?.image);
  const duration = timeToDuration(formValues?.duration);
  const attractionKind = formValues?.kind;
  const isTemporary = attractionKind === ATTRACTION_KIND.TEMPORARY;

  const { isFetching, data: { data: venue } = {} } = useGetVenue(
    formValues?.venueId,
    true,
  );

  if (!attractionKind) {
    return (
      <AspectRatio w="100%" ratio={0.72}>
        <MediaPlaceholder text="Wybierz typ, aby wygenerować podgląd" />
      </AspectRatio>
    );
  }

  return (
    <Box w="100%">
      {isFetching && <LoadingSpinner />}
      <AspectRatio width="100%" ratio={1.37}>
        <Center
          bgColor="complementary.gray"
          bgImage={imagePreview}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition="50% 50%"
          position="relative"
        >
          {!imagePreview && (
            <MediaPlaceholder text="Dodaj zdjęcie, aby wygenerować podgląd" />
          )}
        </Center>
      </AspectRatio>
      <Box padding="24px 16px 32px 16px">
        <VStack spacing={4}>
          <Text variant="h2" w="100%">
            {formValues?.name || 'tu pojawi się nazwa atrakcji'}
          </Text>
          <WYSIWYGContent
            content={formValues?.description}
            placeholder="Tu pojawi się opis atrakcji"
          />

          {isTemporary && (
            <>
              <Text variant="h2" w="100%">
                CZAS TRWANIA WYDARZENIA
              </Text>
              <HStack spacing="12px" w="100%">
                <IconClock />
                <Text fontSize={17} lineHeight="24px" w="100%">
                  {prettierTime(duration) || 'Tu pojawi się czas'}
                </Text>
              </HStack>
            </>
          )}
          <Text variant="h2" w="100%">
            Miejsce
          </Text>
          <HStack spacing="12px" w="100%">
            <IconMapPin />
            <Text fontSize={17} lineHeight="24px" w="100%">
              {venue?.name ||
                'Tu pojawi się obiekt, w którym znajduje się atrakcja'}
            </Text>
          </HStack>
        </VStack>
        <VStack spacing={6} mt={6}>
          <Button
            rightIcon={<IconRoute />}
            aria-label="Link"
            variant="ghost"
            border="solid 1px"
            borderColor="complementary.black"
            fontSize={17}
            w="100%"
          >
            {isTemporary ? 'PROWADŹ DO WYDARZENIA' : 'PROWADŹ DO ATRAKCJI'}
          </Button>
          {isTemporary && (
            <>
              <TicketsPreview formValues={formValues} />
              <SchedulePreview
                label="Godziny wydarzenia"
                hoursKind={formValues?.hoursKind}
                schedules={
                  formValues?.hoursKind === HOURS_KIND.OBJECT
                    ? venue?.schedules
                    : formValues?.schedulesAttributes
                }
              />
            </>
          )}
          {formValues?.contentContainersAttributes?.map(
            (container: any, index: number) => {
              const PreviewComponent: FunctionComponent<any> =
                Previews[container.kind];
              if (PreviewComponent) {
                return (
                  <PreviewComponent
                    formValues={container}
                    key={`$container_${index}`}
                  />
                );
              }
              return null;
            },
          )}
        </VStack>
      </Box>
    </Box>
  );
}

export default AttractionPreview;
