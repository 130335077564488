import { useMemo } from 'react';
import qs from 'query-string';
import Select from 'components/Form/Select';
import { useGetEventsList } from 'api/events';

interface VenueEventsSelectProps {
  name: string;
  venueId: number;
  isMulti?: boolean;
}

function VenueEventsSelect({ name, venueId, isMulti }: VenueEventsSelectProps) {
  const queryString = useMemo(
    () =>
      qs.stringify(
        {
          page_size: 'all',
          venue_ids: [venueId],
        },
        { arrayFormat: 'bracket' },
      ),
    [venueId],
  );

  const { isFetching: isFetchingEvents, data: events = [] } =
    useGetEventsList(queryString);

  return (
    <Select
      label="Impreza"
      placeholder="Wybierz imprezę"
      name={name}
      isLoading={isFetchingEvents}
      options={events}
      isMulti={isMulti}
    />
  );
}

VenueEventsSelect.defaultProps = {
  isMulti: false,
};

export default VenueEventsSelect;
