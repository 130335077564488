import { Box } from '@chakra-ui/react';
import { Route, Outlet, Navigate } from 'react-router-dom';
import map from 'lodash/map';
import PublicLayout from 'components/Layout/PublicLayout';
import Layout from 'components/Layout';
import ROUTES from 'app/routes';

import Login from 'pages/Login';
import UsersListing from 'pages/Users/Listing';
import UserCreate from 'pages/Users/Form/Create';
import UserEdit from 'pages/Users/Form/Edit';
// import SplashScreensListing from 'pages/SplashScreens/Listing';
// import SplashScreenCreate from 'pages/SplashScreens/Form/Create';
// import SplashScreenEdit from 'pages/SplashScreens/Form/Edit';
import OnboardScreensListing from 'pages/OnboardScreens/Listing';
import OnboardScreenCreate from 'pages/OnboardScreens/Form/Create';
import OnboardScreenEdit from 'pages/OnboardScreens/Form/Edit';
import VenuesListing from 'pages/Venues/Listing';
import VenueCreate from 'pages/Venues/Form/Create';
import VenueEdit from 'pages/Venues/Form/Edit';
import VenuePathwaysListing from 'pages/Venues/Pathways/Listing';
import VenuePathwayCreate from 'pages/Venues/Pathways/Form/Create';
import VenuePathwayEdit from 'pages/Venues/Pathways/Form/Edit';
import VenuePlansListing from 'pages/Venues/Plans/Listing';
import VenuePlanCreate from 'pages/Venues/Plans/Form/Create';
import VenuePlanEdit from 'pages/Venues/Plans/Form/Edit';
import NewsListing from 'pages/News/Listing';
import NewsCreate from 'pages/News/Form/Create';
import NewsEdit from 'pages/News/Form/Edit';
import AttractionsListing from 'pages/Attractions/Listing';
import AttractionCreate from 'pages/Attractions/Form/Create';
import AttractionEdit from 'pages/Attractions/Form/Edit';
import EventsListing from 'pages/Events/Listing';
import EventCreate from 'pages/Events/Form/Create';
import EventEdit from 'pages/Events/Form/Edit';
import EventPathwaysListing from 'pages/Events/Pathways/Listing';
import EventPathwayCreate from 'pages/Events/Pathways/Form/Create';
import EventPathwayEdit from 'pages/Events/Pathways/Form/Edit';
import EventAttractionFacilitiesListing from 'pages/Events/AttractionFacilities/Listing';
import EventAttractionFacilityCreate from 'pages/Events/AttractionFacilities/Form/Create';
import EventAttractionFacilityEdit from 'pages/Events/AttractionFacilities/Form/Edit';
import DashboardEdit from 'pages/Dashboard/Form/Edit';
import DashboardContainersListing from 'pages/Dashboard/Listing';
import DashboardContainerCreate from 'pages/Dashboard/Containers/Form/Create';
import DashboardContainerEdit from 'pages/Dashboard/Containers/Form/Edit';
import ContentPagesListing from 'pages/ContentPages/Listing';
import ContentPageCreate from 'pages/ContentPages/Form/Create';
import ContentPageEdit from 'pages/ContentPages/Form/Edit';

export const renderRoutes = (routes: any[]) =>
  map(routes, ({ layout: RouteLayout = Box, ...route }, idx: number) => {
    const Component =
      typeof route.element === 'string' ? route.element : <route.element />;
    return (
      <Route
        key={`route-${route.path}-${idx}`}
        path={route.path}
        element={
          <RouteLayout>
            {Component}
            <Outlet />
          </RouteLayout>
        }
      >
        {route.children ? renderRoutes(route.children) : null}
      </Route>
    );
  });

function DefaultRedirect() {
  return <Navigate to={ROUTES.auth.login} />;
}

export const PUBLIC_ROUTES = [
  { element: '404', path: '*', layout: PublicLayout },
  { element: DefaultRedirect, path: '/', layout: PublicLayout },
  { element: Login, path: ROUTES.auth.login, layout: PublicLayout },
  { element: 'Reset', path: ROUTES.auth.reset, layout: PublicLayout },
  { element: 'ChangePassword', path: ROUTES.auth.change, layout: PublicLayout },
];

export const PRIVATE_ROUTES = [
  {
    element: UsersListing,
    path: ROUTES.users.base,
    layout: Layout,
  },
  {
    element: UserCreate,
    path: ROUTES.users.create,
    layout: Layout,
  },
  {
    element: UserEdit,
    path: ROUTES.users.edit,
    layout: Layout,
  },
  // {
  //   element: SplashScreensListing,
  //   path: ROUTES.splashScreens.base,
  //   layout: Layout,
  // },
  // {
  //   element: SplashScreenCreate,
  //   path: ROUTES.splashScreens.create,
  //   layout: Layout,
  // },
  // {
  //   element: SplashScreenEdit,
  //   path: ROUTES.splashScreens.edit,
  //   layout: Layout,
  // },
  {
    element: OnboardScreensListing,
    path: ROUTES.onboardScreens.base,
    layout: Layout,
  },
  {
    element: OnboardScreenCreate,
    path: ROUTES.onboardScreens.create,
    layout: Layout,
  },
  {
    element: OnboardScreenEdit,
    path: ROUTES.onboardScreens.edit,
    layout: Layout,
  },
  {
    element: VenuesListing,
    path: ROUTES.venues.base,
    layout: Layout,
  },
  {
    element: VenueCreate,
    path: ROUTES.venues.create,
    layout: Layout,
  },
  {
    element: VenueEdit,
    path: ROUTES.venues.edit,
    layout: Layout,
  },
  {
    element: VenuePathwaysListing,
    path: ROUTES.venues.pathways.base,
    layout: Layout,
  },
  {
    element: VenuePathwayCreate,
    path: ROUTES.venues.pathways.create,
    layout: Layout,
  },
  {
    element: VenuePathwayEdit,
    path: ROUTES.venues.pathways.edit,
    layout: Layout,
  },
  {
    element: VenuePlansListing,
    path: ROUTES.venues.plans.base,
    layout: Layout,
  },
  {
    element: VenuePlanCreate,
    path: ROUTES.venues.plans.create,
    layout: Layout,
  },
  {
    element: VenuePlanEdit,
    path: ROUTES.venues.plans.edit,
    layout: Layout,
  },
  {
    element: NewsListing,
    path: ROUTES.news.base,
    layout: Layout,
  },
  {
    element: NewsCreate,
    path: ROUTES.news.create,
    layout: Layout,
  },
  {
    element: NewsEdit,
    path: ROUTES.news.edit,
    layout: Layout,
  },
  {
    element: AttractionsListing,
    path: ROUTES.attractions.base,
    layout: Layout,
  },
  {
    element: AttractionCreate,
    path: ROUTES.attractions.create,
    layout: Layout,
  },
  {
    element: AttractionEdit,
    path: ROUTES.attractions.edit,
    layout: Layout,
  },
  // {
  //   element: PathwaysListing,
  //   path: ROUTES.pathways.base,
  //   layout: Layout,
  // },
  {
    element: EventsListing,
    path: ROUTES.events.base,
    layout: Layout,
  },
  {
    element: EventCreate,
    path: ROUTES.events.create,
    layout: Layout,
  },
  {
    element: EventEdit,
    path: ROUTES.events.edit,
    layout: Layout,
  },
  {
    element: EventPathwaysListing,
    path: ROUTES.events.pathways.base,
    layout: Layout,
  },
  {
    element: EventPathwayCreate,
    path: ROUTES.events.pathways.create,
    layout: Layout,
  },
  {
    element: EventPathwayEdit,
    path: ROUTES.events.pathways.edit,
    layout: Layout,
  },
  {
    element: EventAttractionFacilitiesListing,
    path: ROUTES.events.attractionFacilities.base,
    layout: Layout,
  },
  {
    element: EventAttractionFacilityCreate,
    path: ROUTES.events.attractionFacilities.create,
    layout: Layout,
  },
  {
    element: EventAttractionFacilityEdit,
    path: ROUTES.events.attractionFacilities.edit,
    layout: Layout,
  },
  {
    element: DashboardEdit,
    path: ROUTES.dashboard.edit,
    layout: Layout,
  },
  {
    element: DashboardContainersListing,
    path: ROUTES.dashboard.base,
    layout: Layout,
  },
  {
    element: DashboardContainerCreate,
    path: ROUTES.dashboard.containers.create,
    layout: Layout,
  },
  {
    element: DashboardContainerEdit,
    path: ROUTES.dashboard.containers.edit,
    layout: Layout,
  },
  {
    element: ContentPagesListing,
    path: ROUTES.contentPages.base,
    layout: Layout,
  },
  {
    element: ContentPageCreate,
    path: ROUTES.contentPages.create,
    layout: Layout,
  },
  {
    element: ContentPageEdit,
    path: ROUTES.contentPages.edit,
    layout: Layout,
  },
];
