import omit from 'lodash/omit';
import { parseContentComponents } from 'components/Content/constants';
import { durationToTime } from 'utils/date';
import { parseSchedulesAttributes } from 'components/Schedule/constants';
import { ATTRACTION_KIND } from 'pages/Attractions/Form/constants';

const PARSED_VALUES: string[] = [
  'images',
  'schedules',
  'duration',
  'attractionVenue',
];

const parseDefaultValues = (attraction: Attraction) => {
  const result: Record<string, any> = {
    ...omit(parseContentComponents(attraction), PARSED_VALUES),
  };

  result.imagesAttributes = attraction?.images;
  result.logo = attraction?.attractionVenue?.venue?.logo;
  result.venueId = attraction?.attractionVenue?.venue?.id;
  result.planId = attraction?.plan?.id;
  result.attractionFacilityId = attraction?.attractionVenue?.id;

  if (attraction?.kind === ATTRACTION_KIND.TEMPORARY) {
    result.schedulesAttributes = attraction?.schedules;
    result.duration = attraction?.duration
      ? durationToTime(Number(attraction?.duration))
      : '';
    result.activeFrom = attraction?.activeFrom
      ? new Date(attraction?.activeFrom)
      : null;
    result.activeTo = attraction?.activeTo
      ? new Date(attraction?.activeTo)
      : null;
    return parseSchedulesAttributes(result);
  }

  return result;
};

export default parseDefaultValues;
