import Input from 'components/Form/Input';

interface TitleSectionProps {
  prepareFieldName: (name: string) => string;
}

function TitleSection({ prepareFieldName }: TitleSectionProps) {
  return (
    <Input
      name={prepareFieldName('title')}
      label="Tytuł"
      placeholder="Wpisz tytuł"
    />
  );
}

export default TitleSection;
