import { FormControl } from '@chakra-ui/react';
import DropzoneField from 'components/Form/Dropzone';
import { SectionTitle } from 'components/Form/SectionText';

interface VideoSectionProps {
  prepareFieldName: (name: string) => string;
}

function VideoSection({ prepareFieldName }: VideoSectionProps) {
  return (
    <FormControl as="fieldset">
      <SectionTitle as="legend">Video</SectionTitle>
      <DropzoneField
        name={prepareFieldName('video')}
        title="Dodaj plik mp4"
        acceptVideo
      />
    </FormControl>
  );
}

export default VideoSection;
