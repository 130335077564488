import * as yup from 'yup';
import { ERROR_MESSAGES } from 'utils/form';

const imageGallerySchema = {
  image: yup.mixed().nullable().required(ERROR_MESSAGES.required),
  description: yup.string().nullable().notRequired(),
};

const imageGalleryDefaultValues = [
  { image: null, description: '', position: 0 },
];

export { imageGallerySchema, imageGalleryDefaultValues };
