import { Box, Flex, Stack } from '@chakra-ui/react';
import Card from 'components/Card';
import Input from 'components/Form/Input';
import MaskedInput from 'components/Form/MaskedInput';
import LoadingSpinner from 'components/FormSpinner';
import { FormBodyProps } from 'types/common';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import EventAttractionsSelect from 'components/Select/EventAttractionsSelect';
import VenueAttractionsSelect from 'components/Select/VenueAttractionsSelect';

type PathwayFormBodyProps = FormBodyProps & {
  id: number;
  isEventsForm?: boolean;
};

function FormBody({ isLoading, id, isEventsForm }: PathwayFormBodyProps) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <LoadingSpinner />}
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <Stack spacing={8}>
            <Input
              name="name"
              label="Nazwa ścieżki"
              placeholder="Wpisz nazwę"
            />
            <MaskedInput
              name="duration"
              label="Czas zwiedzania"
              placeholder="HH:MM"
              format="##:##"
            />
            {isEventsForm ? (
              <EventAttractionsSelect
                name="pathwayPointsAttributes"
                eventId={id!}
              />
            ) : (
              <VenueAttractionsSelect
                name="pathwayPointsAttributes"
                label="Atrakcja / wydarzenie powiązane"
                placeholder="Wybierz atrakcję / wydarzenie"
                venueIds={id}
                withExtendedSelect
                isMulti
              />
            )}
          </Stack>
        </Box>
      </Card>
    </Flex>
  );
}

FormBody.defaultProps = {
  isEventsForm: false,
};

export default FormBody;
