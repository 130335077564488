import {
  FormControl,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  InputLeftElement,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';

type InputProps = ChakraInputProps & {
  name: string;
  label?: string | React.ReactNode;
  defaultValue?: number | string;
  rightElement?: React.ReactNode;
  leftElement?: React.ReactNode;
  type?: string;
};

function Input({
  label,
  name,
  isRequired,
  rightElement,
  leftElement,
  defaultValue,
  type,
  isDisabled,
  ...rest
}: InputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={Boolean(errorMessage)}
      isDisabled={isDisabled}
    >
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup>
        {leftElement && (
          <InputLeftElement pointerEvents="none">
            {leftElement}
          </InputLeftElement>
        )}
        <ChakraInput
          type={type}
          {...register(name)}
          defaultValue={defaultValue}
          paddingLeft={leftElement ? '40px' : '-moz-initial'}
          paddingRight={rightElement ? '40px' : 0}
          {...rest}
        />
        {rightElement && (
          <InputRightElement pointerEvents="none">
            {rightElement}
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

Input.defaultProps = {
  label: '',
  type: 'text',
  defaultValue: null,
  rightElement: null,
  leftElement: null,
};

export default Input;
