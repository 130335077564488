import { useMemo } from 'react';
import Select from 'components/Form/Select';

interface VenueSelectProps {
  venues: Venue[];
  label: string;
  showAllOption?: boolean;
}

const ALL_OPTION = { label: 'Wszystkie obiekty', value: '' };

function VenueSelect({ venues, label, showAllOption }: VenueSelectProps) {
  const venuesOptions = useMemo(() => {
    const options =
      venues?.map((venue) => ({
        label: venue.name,
        value: venue.id,
      })) || [];

    return showAllOption ? [ALL_OPTION, ...options] : options;
  }, [showAllOption, venues]);

  return <Select label={label} name="venueId" options={venuesOptions} />;
}

VenueSelect.defaultProps = {
  showAllOption: false,
};

export default VenueSelect;
