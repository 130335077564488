import { VStack, Text, Center, Icon } from '@chakra-ui/react';
import { IconPhoto, IconPlayerPlay } from '@tabler/icons-react';
import { ElementType } from 'types/previews';

type MediaPlaceholderProps = {
  type?: ElementType;
  text: string;
};

function MediaPlaceholder({ type, text }: MediaPlaceholderProps) {
  return (
    <Center width="100%" height="100%" backgroundColor="complementary.gray">
      <VStack spacing={8}>
        {type === 'image' && <IconPhoto size={32} />}
        {type === 'video' && (
          <Icon
            as={IconPlayerPlay}
            border="solid 1.5px"
            borderColor="complementary.black"
            borderRadius="50%"
            fontSize={32}
            padding={1}
          />
        )}
        <Text fontWeight={600} textAlign="center">
          {text}
        </Text>
      </VStack>
    </Center>
  );
}

MediaPlaceholder.defaultProps = {
  type: 'image',
};

export default MediaPlaceholder;
