import { FunctionComponent } from 'react';
import TicketsPreview from 'components/Content/Previews/TicketsPreview';
import HeaderPreview from 'components/Content/Previews/HeaderPreview';
import TitlePreview from 'components/Content/Previews//TitlePreview';
import ImagePreview from 'components/Content/Previews/ImagePreview';
import DescriptionPreview from 'components/Content/Previews/DescriptionPreview';
import VideoPreview from 'components/Content/Previews/VideoPreview';
import AudioPreview from 'components/Content/Previews/AudioPreview';
import ImageGalleryPreview from 'components/Content/Previews/ImageGalleryPreview';
import ContainerPreview from 'components/Content/Previews/ContainerPreview';
import CarouselPreview from 'components/Content/Previews/CarouselPreview';
import { PreviewComponentProps } from 'types/common';

const Previews: {
  [key: string]: FunctionComponent<PreviewComponentProps>;
} = {
  tickets: TicketsPreview,
  header: HeaderPreview,
  title: TitlePreview,
  image: ImagePreview,
  description_field: DescriptionPreview,
  video: VideoPreview,
  audio: AudioPreview,
  image_gallery: ImageGalleryPreview,
  container: ContainerPreview,
  carousel: CarouselPreview,
};

export default Previews;
