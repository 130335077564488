import { IconButton } from '@chakra-ui/react';
import { IconX } from '@tabler/icons-react';
import { ToastContentProps } from 'react-toastify';

function CloseButton({ closeToast }: Partial<ToastContentProps>) {
  return (
    <IconButton onClick={closeToast} aria-label="close_button" variant="ghost">
      <IconX />
    </IconButton>
  );
}
export default CloseButton;
