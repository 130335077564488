import {
  VStack,
  HStack,
  Text,
  Box,
  IconButton,
  Flex,
  Center,
} from '@chakra-ui/react';
import {
  IconArrowNarrowRight,
  IconCalendarEvent,
  IconTicket,
} from '@tabler/icons-react';
import { dateRangePrettierNoTimeAndYear } from 'utils/date';
import useImage from 'utils/useImage';

interface AttractionListItemCardProps {
  name: string;
  image: Image;
  activeFrom?: string;
  activeTo?: string;
  freeEntry?: boolean;
  ticketsTeaser?: string;
}

function AttractionListItemCard({
  name,
  image,
  activeFrom,
  activeTo,
  freeEntry,
  ticketsTeaser,
}: AttractionListItemCardProps) {
  const imagePreview = useImage(image?.image);

  return (
    <Flex
      width="100%"
      height="100%"
      border="solid 1px"
      borderColor="complementary.black"
      borderRadius="8px"
      overflow="hidden"
      gap={2}
    >
      <Center w="100%" maxWidth="106px">
        <Box
          height="100%"
          width="100%"
          bgColor="complementary.whiteGrey"
          bgImage={imagePreview}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition="50% 50%"
          position="relative"
        />
      </Center>
      <VStack
        padding="16px 16px 16px 0px"
        h="100%"
        w="100%"
        justify="space-between"
      >
        <Text
          w="100%"
          fontSize="17px"
          lineHeight="24px"
          minHeight="72px"
          mb={2}
          noOfLines={3}
        >
          {name}
        </Text>
        <HStack spacing={2} align="end" w="100%">
          {activeFrom && activeTo && (
            <VStack w="100%" spacing={2} color="complementary.previewGray">
              <HStack spacing={2} w="100%" align="start">
                <IconCalendarEvent />
                <Text w="100%" fontSize="17px" lineHeight="24px">
                  {dateRangePrettierNoTimeAndYear(activeFrom, activeTo)}
                </Text>
              </HStack>
              {ticketsTeaser && (
                <HStack spacing={2} w="100%" align="start">
                  <IconTicket />
                  <Text
                    w="100%"
                    fontSize="17px"
                    lineHeight="24px"
                    noOfLines={2}
                  >
                    {freeEntry ? 'Bezpłatny wstęp' : ticketsTeaser}
                  </Text>
                </HStack>
              )}
            </VStack>
          )}
          <IconButton
            icon={<IconArrowNarrowRight />}
            aria-label="Link"
            variant="ghost"
            boxSize="32px"
          />
        </HStack>
      </VStack>
    </Flex>
  );
}

AttractionListItemCard.defaultProps = {
  activeFrom: null,
  activeTo: null,
  freeEntry: false,
  ticketsTeaser: '',
};

export default AttractionListItemCard;
