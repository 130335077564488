import { flexRender, Row } from '@tanstack/react-table';
import { toast } from 'react-toastify';
import {
  IconPencil,
  IconRoute,
  IconTrash,
  IconCalendarEvent,
} from '@tabler/icons-react';
import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { generatePath, Link } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useMemo } from 'react';
import CustomModal from 'components/CustomModal';
import Td from 'pages/Events/Listing/components/Table/components/Td';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';

interface TableRowProps {
  row: Row<EventItem>;
}
function TableRow({ row }: TableRowProps) {
  const { id } = row.original;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const queryClient = useQueryClient();

  const pathwaysPath = useMemo(
    () =>
      generatePath(ROUTES.events.pathways.base, {
        id,
      }),
    [id],
  );

  const { mutate: removeEvent } = useMutation(
    () => API.delete(APIRoutes.events.byId(id!)),
    {
      mutationKey: 'eventsMutation',
      onSuccess: () => {
        toast.success('Pomyślnie usunięto imprezę');
        onClose();
        queryClient.invalidateQueries('events');
      },
      onError: () => {
        toast.error('Wystąpił problem podczas usuwania imprezy');
      },
    },
  );

  return (
    <tr key={`row_${id}`}>
      {row.getVisibleCells().map((cell, idx, { length }) => {
        if (idx + 1 === length) return null;
        return (
          <Td key={`row_cell_${cell.id}_${cell}`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
      <Td>
        <>
          <CustomModal
            confirmationText="Usuń"
            onAccept={removeEvent}
            cancellationText="Anuluj"
            onClose={onClose}
            isOpen={isOpen}
            title="Czy na pewno chcesz trwale usunąć tę imprezę?"
          />
          <Tooltip label="Edytuj" openDelay={1000}>
            <IconButton
              width="56px"
              height="40px"
              icon={<IconPencil />}
              aria-label="visiblity"
              variant="ghost"
              as={Link}
              to={generatePath(ROUTES.events.edit, {
                id,
              })}
              onClick={(e) => e.stopPropagation()}
            />
          </Tooltip>
          <Tooltip label="Usuń" openDelay={1000}>
            <IconButton
              width="56px"
              height="40px"
              icon={<IconTrash />}
              aria-label="visiblity"
              variant="ghost"
              onClick={(e) => {
                e.stopPropagation();
                onOpen();
              }}
            />
          </Tooltip>
          <Tooltip label="Ścieżki zwiedzania" openDelay={1000}>
            <IconButton
              width="56px"
              height="40px"
              icon={<IconRoute />}
              aria-label="visiblity"
              variant="ghost"
              as={Link}
              to={pathwaysPath}
              onClick={(e) => e.stopPropagation()}
            />
          </Tooltip>
          <Tooltip label="Agenda" openDelay={1000}>
            <IconButton
              width="56px"
              height="40px"
              icon={<IconCalendarEvent />}
              aria-label="visiblity"
              variant="ghost"
              as={Link}
              to={generatePath(ROUTES.events.attractionFacilities.base, {
                id,
              })}
            />
          </Tooltip>
        </>
      </Td>
    </tr>
  );
}

export default TableRow;
