import { Button, Box, BoxProps } from '@chakra-ui/react';
import { IconPlus } from '@tabler/icons-react';
import Card from 'components/Card';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

interface AddButtonProps extends BoxProps {
  addHandler: () => void;
}

function AddButton({ addHandler, ...props }: AddButtonProps) {
  return (
    <Box {...props}>
      <Card>
        <Button
          variant="solidSecondary"
          w="100%"
          maxW={FORM_BODY_MAX_WIDTH}
          borderRadius="32px"
          onClick={addHandler}
        >
          DODAJ ELEMENT
          <Box as="span" ml={2}>
            <IconPlus />
          </Box>
        </Button>
      </Card>
    </Box>
  );
}

export default AddButton;
