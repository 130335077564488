import { Text } from '@chakra-ui/react';
import { PreviewComponentProps } from 'types/common';

function HeaderPreview({ formValues }: PreviewComponentProps) {
  return (
    <Text variant="h2" w="100%">
      {formValues?.title || 'tu pojawi się Twój nagłówek'}
    </Text>
  );
}

export default HeaderPreview;
