import { FormControl } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from '@emotion/styled';
import colors from 'theme/colors';
import CustomLink from 'components/Form/Editor/CustomLink';

ReactQuill.Quill.register(CustomLink, true);
const Delta = ReactQuill.Quill.import('delta');
type DeltaType = typeof Delta;

const handleUnderline = (_node: Node, delta: DeltaType) => {
  return delta.compose(
    new Delta().retain(delta.length(), {
      underline: true,
    }),
  );
};

const handleStrike = (_node: Node, delta: DeltaType) => {
  return delta.compose(
    new Delta().retain(delta.length(), {
      strike: true,
    }),
  );
};

const CONFIG = {
  bounds: '.quill',
  formats: ['bold', 'italic', 'underline', 'strike', 'link', 'indent', 'list'],
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ],
    clipboard: {
      matchers: [
        ["*[style*='text-decoration-line: underline']", handleUnderline],
        ["*[style*='text-decoration-line: line-through']", handleStrike],
      ],
    },
  },
};

const EditorWrapper = styled(FormControl)`
  .quill {
    border-color: ${({ isInvalid }) =>
      isInvalid ? colors.complementary.error : colors.complementary.black};
    border-width: 1px;
    border-radius: 8px;
    .ql-toolbar {
      border-width: 0px 0px 1px 0px;
    }
    .ql-container {
      border: none;
      .ql-editor {
        min-height: 180px;
        max-height: 500px;
        font-size: 17px;
        line-height: 24px;
        overflow-y: scroll;
        resize: vertical;
      }
    }
  }
`;

export { CONFIG, EditorWrapper };
