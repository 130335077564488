import { flexRender, Row } from '@tanstack/react-table';
import { toast } from 'react-toastify';
import { IconPencil, IconTrash, IconRoute, IconMap } from '@tabler/icons-react';
import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { generatePath, Link } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useMemo } from 'react';
import CustomModal from 'components/CustomModal';
import Td from 'pages/Venues/Listing/components/Table/components/Td';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';

interface TableRowProps {
  row: Row<Venue>;
}
function TableRow({ row }: TableRowProps) {
  const { id } = row.original;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const queryClient = useQueryClient();

  const pathwaysPath = useMemo(
    () =>
      generatePath(ROUTES.venues.pathways.base, {
        id,
      }),
    [id],
  );

  const plansPath = useMemo(
    () =>
      generatePath(ROUTES.venues.plans.base, {
        id,
      }),
    [id],
  );

  const { mutate: removeVenue } = useMutation(
    () => API.delete(APIRoutes.venues.byId(id!)),
    {
      mutationKey: 'venuesMutation',
      onSuccess: () => {
        toast.success('Pomyślnie usunięto obiekt');
        onClose();
        queryClient.invalidateQueries('venues');
      },
      onError: () => {
        toast.error('Wystąpił problem podczas usuwania obiektu');
      },
    },
  );

  return (
    <tr key={`row_${id}`}>
      {row.getVisibleCells().map((cell, idx, { length }) => {
        if (idx + 1 === length) return null;
        return (
          <Td key={`row_cell_${cell.id}_${cell}`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
      <Td>
        <>
          <CustomModal
            confirmationText="Usuń"
            onAccept={removeVenue}
            cancellationText="Anuluj"
            onClose={onClose}
            isOpen={isOpen}
            title="Czy na pewno chcesz trwale usunąć ten obiekt?"
          />
          <Tooltip label="Edytuj" openDelay={1000}>
            <IconButton
              width="56px"
              height="40px"
              icon={<IconPencil />}
              aria-label="visiblity"
              variant="ghost"
              as={Link}
              to={generatePath(ROUTES.venues.edit, {
                id,
              })}
            />
          </Tooltip>
          <Tooltip label="Usuń" openDelay={1000}>
            <IconButton
              width="56px"
              height="40px"
              icon={<IconTrash />}
              aria-label="visiblity"
              variant="ghost"
              onClick={onOpen}
            />
          </Tooltip>
          <Tooltip label="Ścieżki zwiedzania" openDelay={1000}>
            <IconButton
              width="56px"
              height="40px"
              icon={<IconRoute />}
              aria-label="pathways"
              variant="ghost"
              as={Link}
              to={pathwaysPath}
            />
          </Tooltip>
          <Tooltip label="Plany" openDelay={1000}>
            <IconButton
              width="56px"
              height="40px"
              icon={<IconMap />}
              aria-label="plans"
              variant="ghost"
              as={Link}
              to={plansPath}
            />
          </Tooltip>
        </>
      </Td>
    </tr>
  );
}

export default TableRow;
