/* eslint-disable @typescript-eslint/naming-convention */
import * as yup from 'yup';
import { CONTENT_CONTAINER_SCHEMA } from 'components/Content/constants';
import { ERROR_MESSAGES } from 'utils/form';

enum PAGE_KIND {
  NAVIGATION = 'navigation',
  ACCESSIBILITY = 'accessibility',
}

export const PAGE_KIND_DICT: {
  [K: string]: string;
} = {
  navigation: 'Nawigacja',
  accessibility: 'Deklaracja dostępności',
};

export const PAGE_KIND_OPTIONS = [
  { label: PAGE_KIND_DICT.navigation, value: PAGE_KIND.NAVIGATION },
  { label: PAGE_KIND_DICT.accessibility, value: PAGE_KIND.ACCESSIBILITY },
];

export const BODY_SCHEMA = yup.object({
  name: yup
    .string()
    .trim()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  kind: yup.string().trim().required(ERROR_MESSAGES.required),
  contentContainersAttributes: yup.array().of(CONTENT_CONTAINER_SCHEMA),
});

export const DEFAULT_VALUES = {
  name: '',
  kind: '',
  contentContainersAttributes: [],
};
