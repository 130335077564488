import * as yup from 'yup';
import { serialize } from 'object-to-formdata';
import { concat, differenceBy, find, omit } from 'lodash';
import differenceInDays from 'date-fns/differenceInDays';
import endOfDay from 'date-fns/endOfDay';
import {
  HOURS_KIND,
  schedulesAttributesDefaultValues,
  prepareSchedulesAttributes,
  schedulesAttributesSchema,
} from 'components/Schedule/constants';
import { convertKeysToSnakeCase, transformRemoved } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { VALIDATION_SCHEMA } from 'components/Content/constants';
import { ERROR_MESSAGES } from 'utils/form';

export const BODY_SCHEMA = yup.object({
  imagesAttributes: yup.array().of(
    yup.object().shape({
      image: yup.mixed().nullable().required(ERROR_MESSAGES.required),
      position: yup.number().required(),
    }),
  ),
  name: yup
    .string()
    .trim()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  dateFrom: yup.string().trim().nullable().required(ERROR_MESSAGES.required),
  dateTo: yup
    .string()
    .trim()
    .nullable()
    .required(ERROR_MESSAGES.required)
    .test(
      'dateToLaterThanFrom',
      ERROR_MESSAGES.dateToLaterThanFrom,
      (value, testContext) => {
        const { dateFrom } = testContext.parent;
        if (dateFrom && value) {
          return differenceInDays(new Date(value), new Date(dateFrom)) >= 0;
        }
        return true;
      },
    ),
  description: yup.string().trim().required(ERROR_MESSAGES.required),
  shortDescription: yup
    .string()
    .nullable()
    .trim()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  ...VALIDATION_SCHEMA.tickets,
  schedulesAttributes: schedulesAttributesSchema,
  pathwaysDescription: yup.string().nullable().notRequired(),
  hoursKind: yup.string().trim().nullable().required(ERROR_MESSAGES.required),
  venueIds: yup.array().of(yup.number()).min(1, ERROR_MESSAGES.required),
});

export const DEFAULT_VALUES = {
  imagesAttributes: [
    {
      image: null,
      position: 0,
    },
  ],
  name: '',
  dateFrom: null,
  dateTo: null,
  description: '',
  shortDescription: '',
  ticketsInfo: '',
  ticketsTeaser: '',
  freeEntry: false,
  ticketsLink: '',
  schedulesAttributes: schedulesAttributesDefaultValues,
  pathwaysDescription: '',
  hoursKind: HOURS_KIND.OBJECT,
  venueIds: [],
};

export const preparePayload = (values: EventItem) => {
  const data = { ...omit(values, 'venueIds') };

  data.dateTo = endOfDay(new Date(values?.dateTo!)).toString();

  data.attractionFacilitiesAttributes =
    values?.attractionFacilitiesAttributes?.map((attractionId, index) => {
      const attraction = find(data.attractionFacilities, [
        'attractionId',
        attractionId,
      ]);

      return {
        id: attraction?.id,
        position: index,
        attractionId: +attractionId,
      };
    });

  const removedAttractionFacilities = differenceBy(
    values?.attractionFacilities,
    data?.attractionFacilitiesAttributes,
    'id',
  );

  data.attractionFacilitiesAttributes = concat(
    data?.attractionFacilitiesAttributes,
    ...transformRemoved(removedAttractionFacilities),
  );

  delete data?.attractionFacilities;

  const snakePayload = convertKeysToSnakeCase(prepareSchedulesAttributes(data));
  const formData = serialize(
    snakePayload,
    SERIALIZER_OPTIONS,
    new FormData(),
    'event',
  );

  values.venueIds?.forEach((id) =>
    formData.append('event[venue_ids][]', id.toString()),
  );

  return formData;
};
