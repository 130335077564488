import { Box, Flex, FormControl, Stack } from '@chakra-ui/react';
import Card from 'components/Card';
import DropzoneField from 'components/Form/Dropzone';
import LoadingSpinner from 'components/FormSpinner';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { SectionTitle } from 'components/Form/SectionText';
import { FormBodyProps } from 'types/common';
import NumberInput from 'components/Form/NumberInput';

function FormBody({ isLoading }: FormBodyProps) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <LoadingSpinner />}
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <Stack spacing={8}>
            <NumberInput
              name="position"
              label="Piętro"
              placeholder="Wpisz numer piętra"
            />
            <NumberInput
              name="poiId"
              label="Id w Mapsted"
              placeholder="Wpisz id"
            />
            <FormControl as="fieldset">
              <SectionTitle as="legend">Zdjęcie</SectionTitle>
              <DropzoneField
                name="plan"
                title="Dodaj zdjęcie w formacie .png, .jpg"
              />
            </FormControl>
          </Stack>
        </Box>
      </Card>
    </Flex>
  );
}

export default FormBody;
