import { ColumnDef } from '@tanstack/react-table';
import ImagePreview from 'components/ImagePreview';
import SchedulePreview from 'components/Schedule/SchedulePreview';
import WYSIWYGContent from 'components/WYSIWYGContent';

function GetColumns(): ColumnDef<Venue, string>[] {
  return [
    {
      header: 'Id',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Logo budynku',
      accessorKey: 'logo.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: 'Zdjęcie obiektu',
      accessorKey: 'image.image.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: 'Nazwa obiektu',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Opis',
      accessorKey: 'description',
      cell: (info) => <WYSIWYGContent content={info.getValue()} isTruncated />,
    },
    {
      header: 'Godziny',
      accessorKey: 'schedules',
      cell: ({
        row: {
          original: { schedules },
        },
      }) => <SchedulePreview days={schedules} />,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
