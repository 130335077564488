import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import get from 'lodash/get';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pl from 'date-fns/locale/pl';
import styled from '@emotion/styled';
import Input from 'components/Form/Date/input';

registerLocale('pl', pl);

const Popper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
`;

export const Wrapper = styled(FormControl, {
  shouldForwardProp: (prop: string) => !['showTimeInput'].includes(prop),
})<{ showTimeInput: boolean }>(({ showTimeInput }) => ({
  position: 'relative',
  '.react-datepicker__close-icon': {
    position: 'absolute',
    left: showTimeInput ? '160px' : '110px',
    marginRight: '72px',

    '&:after': {
      backgroundColor: '#000',
      fontSize: '14px',
      width: '12px',
      height: '12px',
      display: 'block',
      lineHeight: '14px',
    },
  },
}));

type DateProps = Omit<ReactDatePickerProps, 'onChange'> & {
  label: string;
  name: string;
  showError?: boolean;
  isRequired?: boolean;
  hidden?: boolean;
  mb?: number | string;
  rangeSelect?: boolean;
  isClearable?: boolean;
  showTimeInput?: boolean;
};

function Date({
  label,
  name,
  showError,
  isRequired,
  hidden,
  mb,
  rangeSelect,
  isClearable,
  showTimeInput = false,
  ...props
}: DateProps) {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;
  const dateFormat = showTimeInput ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy';

  return (
    <Wrapper
      hidden={hidden}
      isInvalid={Boolean(errorMessage)}
      isRequired={isRequired}
      mb={mb}
      showTimeInput={showTimeInput}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange: _onChange, onBlur, value } }) => {
          if (rangeSelect) {
            const { start, end } = value || {};
            return (
              <ReactDatePicker
                {...props}
                popperContainer={Popper}
                portalId="root-portal"
                startDate={start || null}
                endDate={end || null}
                onChange={(e) => {
                  const val = { start: e[0], end: e[1] };
                  _onChange(val);
                }}
                onBlur={onBlur}
                customInput={React.createElement(React.forwardRef(Input))}
                selectsRange
                dateFormat={dateFormat}
                locale="pl"
                strictParsing
                isClearable={isClearable}
                timeCaption="Godzina"
                timeInputLabel="Godzina"
                showTimeInput={showTimeInput}
                showTimeSelect={showTimeInput}
                popperProps={{
                  strategy: 'fixed',
                }}
              />
            );
          }

          return (
            <ReactDatePicker
              {...props}
              popperContainer={Popper}
              portalId="root-portal"
              selected={value}
              onChange={_onChange}
              onBlur={onBlur}
              customInput={React.createElement(React.forwardRef(Input))}
              dateFormat={dateFormat}
              locale="pl"
              strictParsing
              isClearable={isClearable}
              timeCaption="Godzina"
              timeInputLabel="Godzina"
              showTimeInput={showTimeInput}
              showTimeSelect={showTimeInput}
              popperProps={{
                strategy: 'fixed',
              }}
            />
          );
        }}
      />
      {showError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </Wrapper>
  );
}

Date.defaultProps = {
  hidden: false,
  isRequired: false,
  showError: false,
  mb: 0,
  rangeSelect: false,
  isClearable: true,
  showTimeInput: false,
};

export default Date;
