import omit from 'lodash/omit';
import { ContentContainer } from 'types/contentComponent';

const PARSED_VALUES: string[] = ['linkParameters', 'images'];

const parseDefaultValues = (dashboardContainer: ContentContainer) => {
  const result: Record<string, any> = {
    ...omit(dashboardContainer, PARSED_VALUES),
  };

  result.linkParametersAttributes = dashboardContainer?.linkParameters;
  result.imagesAttributes = dashboardContainer?.images;
  result.kind = dashboardContainer?.componentKind;

  return result;
};

export default parseDefaultValues;
