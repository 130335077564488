import { Text, Stack, HStack, Box } from '@chakra-ui/react';
import { IconTicket } from '@tabler/icons-react';
import Button from 'components/Previews/Button';
import { PreviewComponentProps } from 'types/common';

function TicketsPreview({ formValues }: PreviewComponentProps) {
  return (
    <Box w="100%">
      <Text variant="h2" mb={4}>
        Bilety
      </Text>
      <Stack spacing={4}>
        <Text fontSize={17} lineHeight={1.41}>
          {formValues?.ticketsInfo || 'Tu pojawi się opis (opcjonalnie)'}
        </Text>
        <HStack spacing={2}>
          <IconTicket />
          <Text w="100%">
            {formValues?.freeEntry
              ? 'Bezpłatny wstęp'
              : formValues?.ticketsTeaser || 'Tu pojawi się cena biletów'}
          </Text>
        </HStack>
        {!formValues?.freeEntry && formValues?.ticketsLink && (
          <Button label="KUP BILETY" />
        )}
      </Stack>
    </Box>
  );
}

export default TicketsPreview;
