import { useIsMutating } from 'react-query';
import { Link } from 'react-router-dom';
import useQueryParams from 'utils/useQueryParams';
import ListingLayout from 'components/Layout/ListingLayout';
import ROUTES from 'app/routes';
import Table from 'pages/News/Listing/components/Table';
import Footer from 'components/Footer';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetNews } from 'api/news';

function News() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });

  const isMutating = !!useIsMutating({
    mutationKey: ['newsMutation'],
  });

  const { isFetching, data: { data: news, totals } = { data: [], totals: 0 } } =
    useGetNews(queryString);

  const showLoading = isMutating || isFetching;

  return (
    <ListingLayout
      title="Aktualności"
      headerButtons={[
        {
          label: 'NOWY ARTYKUŁ',
          as: Link,
          to: ROUTES.news.create,
        },
      ]}
      searchKey="title"
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={news} />}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default News;
