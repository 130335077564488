import { Box, Flex, Stack } from '@chakra-ui/react';
import Card from 'components/Card';
import Input from 'components/Form/Input';
import PasswordInput from 'components/Form/PasswordInput';
import LoadingSpinner from 'components/FormSpinner';
import { FormBodyProps } from 'types/common';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

function FormBody({ isLoading }: FormBodyProps) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <LoadingSpinner />}
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <Stack spacing={8}>
            <Input label="Adres e-mail" name="email" />
            <Input label="Imię" name="firstName" />
            <Input label="Nazwisko" name="lastName" />
            <PasswordInput label="Hasło" name="password" />
            <PasswordInput label="Powtórz hasło" name="passwordConfirmation" />
          </Stack>
        </Box>
      </Card>
    </Flex>
  );
}

export default FormBody;
