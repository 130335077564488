import { ColumnDef } from '@tanstack/react-table';
import ImagePreview from 'components/ImagePreview';
import SchedulePreview from 'components/Schedule/SchedulePreview';
import { dateRangePrettierNoTime } from 'utils/date';
import { HOURS_KIND } from 'components/Schedule/constants';
import WYSIWYGContent from 'components/WYSIWYGContent';

function GetColumns(): ColumnDef<Attraction, string>[] {
  return [
    {
      header: 'Id',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Zdjęcie atrakcji',
      accessorKey: 'image.image.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: 'Nazwa atrakcji',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Opis',
      accessorKey: 'description',
      cell: (info) => <WYSIWYGContent content={info.getValue()} isTruncated />,
    },
    {
      header: 'Miejsce atrakcji',
      accessorKey: 'attractionVenue.venue.name',
      cell: (info) => info.getValue() || '-',
    },
    {
      header: 'Data wydarzenia',
      cell: ({
        row: {
          original: { activeFrom, activeTo },
        },
      }) => dateRangePrettierNoTime(activeFrom, activeTo),
    },
    {
      header: 'Godziny',
      accessorKey: 'schedules',
      cell: ({
        row: {
          original: { hoursKind, schedules, venue },
        },
      }) => (
        <SchedulePreview
          days={hoursKind === HOURS_KIND.OBJECT ? venue?.schedules : schedules}
        />
      ),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
