/* eslint-disable no-console */
import { IconLogout } from '@tabler/icons-react';
import { IconButton, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useAuth } from 'context/AuthContext';
import { API, APIRoutes } from 'api';

export const NAVBAR_HEIGHT = '56px';

function Navbar() {
  const { user, logout, isAuthenticated } = useAuth();

  const logoutHandler = useCallback(async () => {
    try {
      await API.delete(APIRoutes.auth.logout);
      logout();
    } catch (err) {
      console.log(err);
    }
  }, [logout]);

  return (
    <Flex
      as="nav"
      h={NAVBAR_HEIGHT}
      w="100%"
      wrap="wrap"
      columnGap="24px"
      align="center"
      position="sticky"
      left={0}
      top={0}
      paddingX="40px"
      justify="flex-end"
      backgroundColor="complementary.black"
      color="#fff"
      zIndex={10}
    >
      <Text fontSize={14}>{user?.email}</Text>
      <Tooltip label="Wyloguj">
        <IconButton
          h="100%"
          variant="unstyled"
          hidden={!isAuthenticated}
          aria-label="Logout"
          icon={<IconLogout size={24} />}
          onClick={logoutHandler}
        />
      </Tooltip>
    </Flex>
  );
}

export default Navbar;
