import { Stack } from '@chakra-ui/react';
import Input from 'components/Form/Input';
import LinkCreator from 'components/LinkCreator';

interface CarouselSectionProps {
  prepareFieldName: (name: string) => string;
}

function CarouselSection({ prepareFieldName }: CarouselSectionProps) {
  return (
    <Stack spacing={8}>
      <Input
        name={prepareFieldName('title')}
        label="Nagłówek karuzeli"
        placeholder="Wpisz nagłówek"
      />
      <LinkCreator prepareFieldName={prepareFieldName} component="carousel" />
    </Stack>
  );
}

export default CarouselSection;
