import * as yup from 'yup';
import { CONTENT_CONTAINER_SCHEMA } from 'components/Content/constants';
import { ERROR_MESSAGES } from 'utils/form';

export const BODY_SCHEMA = yup.object({
  header: yup
    .string()
    .trim()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  teaser: yup
    .string()
    .trim()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  contentContainersAttributes: yup.array().of(CONTENT_CONTAINER_SCHEMA),
});

export const DEFAULT_VALUES = {
  header: '',
  teaser: '',
};
