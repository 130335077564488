/* eslint-disable no-plusplus, no-underscore-dangle */
import 'swiper/css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Center,
  Flex,
  IconButton,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Controller } from 'swiper';
import { IconPlus, IconX } from '@tabler/icons-react';
import { filter, map, pullAt } from 'lodash';
import DropzoneField from 'components/Form/Dropzone';
import Textarea from 'components/Form/Textarea';
import SliderNavigation from 'components/SliderNavigation';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

interface ImageGalleryFieldsetProps {
  baseFieldName: string;
}

function ImageGalleryFieldset({ baseFieldName }: ImageGalleryFieldsetProps) {
  const [imageSwiperRef, setImageSwiperRef] = useState<SwiperCore>();
  const [descriptionSwiperRef, setDescriptionSwiperRef] =
    useState<SwiperCore>();

  const { control } = useFormContext();
  const { fields, append, replace } = useFieldArray({
    control,
    name: baseFieldName,
    keyName: 'fieldId',
  });

  const prepareFieldName = useCallback(
    (index: number, name: string) => `${baseFieldName}[${index}].${name}`,
    [baseFieldName],
  );

  const filteredSlides = useMemo(
    () => filter(fields, (field: Image) => !field._destroy),
    [fields],
  );

  const addSlide = useCallback(() => {
    append({
      image: null,
      description: '',
      position: filteredSlides.length,
    });
  }, [append, filteredSlides]);

  useEffect(() => {
    if (imageSwiperRef && !imageSwiperRef?.destroyed)
      imageSwiperRef?.slideTo(fields.length - 1);
  }, [fields.length, imageSwiperRef]);

  const handleSlideDelete = useCallback(
    (index: number) => {
      let slides: any[] = [...fields];

      if (slides[index]?.id) {
        slides[index]._destroy = true;
      } else {
        pullAt(slides, index);
      }

      let position = 0;
      slides = map(slides, (slide) => ({
        ...slide,
        position: slide?._destroy ? 0 : position++,
      }));

      replace(slides);
    },
    [fields, replace],
  );

  return (
    <VStack spacing={4} w="100%" maxWidth={FORM_BODY_MAX_WIDTH}>
      <Box w="100%">
        <Swiper
          onSwiper={setImageSwiperRef}
          spaceBetween={4}
          slidesPerView="auto"
          modules={[Controller]}
          controller={{ control: descriptionSwiperRef }}
        >
          {fields.map((field: any, index) => {
            if (field._destroy) return null;

            return (
              <SwiperSlide
                key={`${field.fieldId}_image`}
                style={{ width: 'calc(100% - 114px - 4px)' }}
              >
                {filteredSlides.length > 1 && (
                  <Tooltip label="Usuń slajd" openDelay={1000}>
                    <IconButton
                      variant="ghost"
                      aria-label="remove slide"
                      icon={<IconX size={18} />}
                      size="sm"
                      sx={{
                        position: 'absolute',
                        left: 1,
                        top: 1,
                        zIndex: 1,
                      }}
                      isRound
                      onClick={() => handleSlideDelete(index)}
                    />
                  </Tooltip>
                )}
                <DropzoneField
                  name={prepareFieldName(index, 'image')}
                  title="Dodaj zdjęcie w formacie .png, .jpg"
                  isRemovable
                />
              </SwiperSlide>
            );
          })}
          <SwiperSlide style={{ width: '114px' }}>
            <Center
              border="1px solid"
              borderColor="complementary.black"
              borderRadius="4px"
              minHeight={184}
            >
              <VStack gap={1} align="center">
                <Text>dodaj zdjęcie</Text>
                <IconButton
                  onClick={addSlide}
                  aria-label="Add slide"
                  variant="solidSecondary"
                  icon={<IconPlus />}
                  isRound
                />
              </VStack>
            </Center>
          </SwiperSlide>
        </Swiper>
      </Box>
      <Flex gap={2} align="center" w="100%">
        <Swiper
          onSwiper={setDescriptionSwiperRef}
          spaceBetween={4}
          slidesPerView={1}
          modules={[Controller]}
          controller={{ control: imageSwiperRef }}
        >
          {fields.map((field: any, index) => {
            if (field._destroy) return null;

            return (
              <SwiperSlide key={`${field.fieldId}_description`}>
                <Flex width="100%">
                  <Textarea
                    name={prepareFieldName(index, 'description')}
                    label="Opis zdjęcia (opcjonalnie)"
                    placeholder="Dodaj swój opis"
                  />
                </Flex>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <SliderNavigation sliderRef={descriptionSwiperRef} />
      </Flex>
    </VStack>
  );
}

export default ImageGalleryFieldset;
