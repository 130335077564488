import WYSIWYGContent from 'components/WYSIWYGContent';
import { PreviewComponentProps } from 'types/common';

function DescriptionPreview({ formValues }: PreviewComponentProps) {
  return (
    <WYSIWYGContent
      content={formValues?.content}
      placeholder="Tu pojawi się Twój opis"
    />
  );
}

export default DescriptionPreview;
