import {
  FormControl,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';

type CheckboxProps = ChakraCheckboxProps & {
  name: string;
  label?: string | React.ReactNode;
};

function Checkbox({
  label,
  name,
  isRequired,
  defaultChecked,
  isDisabled,
  ...rest
}: CheckboxProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={Boolean(errorMessage)}
      isDisabled={isDisabled}
    >
      <FormLabel htmlFor={name}>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultChecked}
          render={({ field: { onChange, value } }) => (
            <ChakraCheckbox isChecked={value} onChange={onChange} {...rest}>
              {label}
            </ChakraCheckbox>
          )}
        />
      </FormLabel>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

Checkbox.defaultProps = {
  label: '',
};

export default Checkbox;
