/* eslint-disable react/no-array-index-key */
import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import HeaderNavigation from 'components/HeaderNavigation';
import { FORM_MAX_WIDTH } from 'utils/constants';
import FooterButtons, { FOOTER_HEIGHT } from 'components/FooterButtons';
import FormBody from 'pages/Events/AttractionFacilities/Form/components/FormBody';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
  preparePayload,
  TRANSFORMED_KEYS,
} from 'pages/Events/AttractionFacilities/Form/constants';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';
import transformErrors from 'utils/api';
import LoadingIndicator from 'components/LoadingIndicator';
import parseDefaultValues from 'pages/Events/AttractionFacilities/Form/Edit/parseDefaultValues';
import ComponentPreview from 'components/PreviewWrapper';
import { useGetEvent, useGetEventAttractionFacility } from 'api/events';
import FormWrapper from 'components/FormWrapper';

function EventAttractionFacilityEdit() {
  const { eventId = '', id = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const backPath = useMemo(
    () =>
      generatePath(ROUTES.events.attractionFacilities.base, {
        id: eventId,
      }),
    [eventId],
  );

  const methods = useForm<AttractionFacility>({
    mode: 'onChange',
    resolver: yupResolver(BODY_SCHEMA),
    defaultValues: DEFAULT_VALUES,
  });

  const { handleSubmit, setError, reset } = methods;

  const { data: { data: event } = {}, isFetching: isFetchingEvent } =
    useGetEvent(+eventId);

  const {
    data: { data: atttractionFacility } = {},
    isFetching: isFetchingAtttractionFacility,
  } = useGetEventAttractionFacility(+eventId, +id);

  const { mutate: editAttractionFacility, isLoading: isEditing } = useMutation({
    mutationFn: (data: AttractionFacility) => {
      const payload = preparePayload(data);
      return API.put(
        APIRoutes.events.attractionFacilities.byId(+eventId, +id!),
        payload,
      );
    },
    mutationKey: 'eventAttractionFacilitiesMutation',
    onSuccess: () => {
      toast.success('Zmiany w wydarzeniu agendy zostały zastosowane');
      navigate(backPath);
      queryClient.invalidateQueries('eventAttractionFacilities');
    },
    onError: ({ errors }) => {
      const transformedErrors = transformErrors(errors, null);
      Object.keys(transformedErrors).forEach((field: string) => {
        const fieldName = TRANSFORMED_KEYS[field] || field;
        setError(fieldName as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      toast.error('Wystąpił problem podczas edycji wydarzenia agendy');
    },
  });

  const onSubmit = handleSubmit(async (data: AttractionFacility) =>
    editAttractionFacility(data),
  );

  useEffect(() => {
    if (atttractionFacility)
      reset({
        ...DEFAULT_VALUES,
        ...parseDefaultValues(atttractionFacility),
      });
  }, [atttractionFacility, reset]);

  const isLoading =
    isFetchingEvent || isFetchingAtttractionFacility || isEditing;

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: event?.name!,
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj wydarzenie agendy' }]}
      />
      {isFetchingAtttractionFacility && <LoadingIndicator />}
      <FormWrapper {...methods}>
        <Flex gap={4} mb={4} alignItems="flex-start" pb={FOOTER_HEIGHT}>
          <Box
            w="100%"
            as="form"
            id="attraction-facility-editor"
            onSubmit={onSubmit}
            maxW={FORM_MAX_WIDTH}
          >
            <FormBody isLoading={isLoading} eventId={+eventId} />
          </Box>
          <ComponentPreview kind="attraction_facility" isEditMode />
        </Flex>
      </FormWrapper>
      <FooterButtons
        isLoading={isLoading}
        formId="attraction-facility-editor"
        onCancel={() => navigate(-2)}
      />
    </Box>
  );
}

export default EventAttractionFacilityEdit;
