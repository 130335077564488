import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  NumberInput as ChakraNumberInput,
  NumberInputProps as ChakraNumberInputProps,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputField,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';

type NumberInputProps = ChakraNumberInputProps & {
  name: string;
  label?: string | React.ReactNode;
  placeholder?: string;
  defaultValue?: number;
};

function NumberInput({
  label,
  name,
  isRequired,
  defaultValue,
  placeholder,
  ...rest
}: NumberInputProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl isRequired={isRequired} isInvalid={Boolean(errorMessage)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <ChakraNumberInput
            label={label}
            name={name}
            onChange={onChange}
            value={value === null ? '' : value}
            {...rest}
          >
            <NumberInputField placeholder={placeholder} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </ChakraNumberInput>
        )}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

NumberInput.defaultProps = {
  label: '',
  placeholder: '',
  defaultValue: 0,
};

export default NumberInput;
