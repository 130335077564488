/* eslint-disable @typescript-eslint/naming-convention */
import * as yup from 'yup';
import { isValidTime, isValidTimeRange } from 'utils/date';
import { ERROR_MESSAGES } from 'utils/form';

enum HOURS_KIND {
  OBJECT = 'object',
  RANGE = 'range',
  MULTIPLE_SLOTS = 'multiple_slots',
}

const schedulesAttributesSchema = yup
  .array()
  .when(['hoursKind'], ([hoursKind]) => {
    if (hoursKind === HOURS_KIND.RANGE) {
      return yup.array().of(
        yup.object().shape({
          hours: yup.string().when(['closed'], ([closed]) =>
            closed
              ? yup.string().nullable().notRequired()
              : yup
                  .string()
                  .trim()
                  .required(ERROR_MESSAGES.required)
                  .test({
                    test(hours, context) {
                      return isValidTimeRange(hours)
                        ? true
                        : context.createError({
                            message: ERROR_MESSAGES.invalidFormat,
                          });
                    },
                  }),
          ),
          closed: yup.boolean().notRequired(),
        }),
      );
    }
    if (hoursKind === HOURS_KIND.MULTIPLE_SLOTS) {
      return yup.array().of(
        yup.object().shape({
          slots: yup.array().when(['closed'], ([closed]) =>
            closed
              ? yup.array().of(yup.string().nullable().notRequired())
              : yup.array().of(
                  yup
                    .string()
                    .trim()
                    .required(ERROR_MESSAGES.required)
                    .test({
                      test(slot, context) {
                        return isValidTime(slot)
                          ? true
                          : context.createError({
                              message: ERROR_MESSAGES.invalidFormat,
                            });
                      },
                    }),
                ),
          ),
          closed: yup.boolean().notRequired(),
        }),
      );
    }
    return yup.array().nullable().notRequired();
  });

const schedulesAttributesDefaultValues = [
  { hours: '', closed: false },
  { hours: '', closed: false },
  { hours: '', closed: false },
  { hours: '', closed: false },
  { hours: '', closed: false },
  { hours: '', closed: false },
  { hours: '', closed: false },
];

const prepareSchedulesAttributes = (values: any) => {
  const data = { ...values };

  if (data?.hoursKind === HOURS_KIND.OBJECT) {
    data.schedulesAttributes = [];
  } else {
    data.schedulesAttributes = data?.schedulesAttributes?.map(
      (schedule: Schedule, day: number) => {
        return {
          ...schedule,
          day,
          hours:
            values?.hoursKind === HOURS_KIND.MULTIPLE_SLOTS
              ? schedule?.slots?.join(', ')?.trim()
              : schedule?.hours,
        };
      },
    );
  }

  return data;
};

const parseSchedulesAttributes = (values: any) => {
  const data = { ...values };
  data.schedulesAttributes = data?.schedulesAttributes?.map(
    (schedule: Schedule) => ({
      ...schedule,
      slots:
        values?.hoursKind === HOURS_KIND.MULTIPLE_SLOTS
          ? schedule?.hours?.split(', ')
          : [],
    }),
  );

  return data;
};

export {
  HOURS_KIND,
  schedulesAttributesSchema,
  schedulesAttributesDefaultValues,
  prepareSchedulesAttributes,
  parseSchedulesAttributes,
};
