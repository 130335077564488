import { Box, Flex } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import Card from 'components/Card';
import LoadingSpinner from 'components/FormSpinner';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponents } from 'components/Content/constants';

interface ComponentWrapperProps {
  kind: string;
  isLoading: boolean;
}

function ComponentWrapper({ kind, isLoading }: ComponentWrapperProps) {
  const FormComponent: FunctionComponent<any> = FormComponents[kind];

  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <LoadingSpinner />}
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          {FormComponent && (
            <FormComponent prepareFieldName={(name: string) => name} />
          )}
        </Box>
      </Card>
    </Flex>
  );
}

export default ComponentWrapper;
