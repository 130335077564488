import Input from './input';
import Button from './button';
import FormError from './formError';
import FormLabel from './formLabel';
import Tooltip from './tooltip';
import Text from './text';
import Checkbox from './checkbox';
import Textarea from './textarea';
import Modal from './modal';
import Tabs from './tabs';
import Switch from './switch';
import NumberInput from './numberInput';
import Tag from './tag';

const components = {
  Input,
  Button,
  FormError,
  FormLabel,
  Tooltip,
  Text,
  Checkbox,
  Textarea,
  Modal,
  Tabs,
  Switch,
  NumberInput,
  Tag,
};

export default components;
