/* eslint-disable react/no-array-index-key */
import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import HeaderNavigation from 'components/HeaderNavigation';
import { FORM_MAX_WIDTH } from 'utils/constants';
import FooterButtons, { FOOTER_HEIGHT } from 'components/FooterButtons';
import FormBody from 'pages/Pathways/Form/components/FormBody';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
  preparePayload,
} from 'pages/Pathways/Form/constants';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';
import transformErrors from 'utils/api';
import LoadingIndicator from 'components/LoadingIndicator';
import ComponentPreview from 'components/PreviewWrapper';
import parseDefaultValues from 'pages/Pathways/Form/parseDefaultValues';
import { useGetEvent, useGetEventPathway } from 'api/events';
import FormWrapper from 'components/FormWrapper';

function EventPathwayEdit() {
  const { id = '', eventId = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const backPath = useMemo(
    () =>
      generatePath(ROUTES.events.pathways.base, {
        id: eventId,
      }),
    [eventId],
  );

  const methods = useForm<Pathway>({
    mode: 'onChange',
    resolver: yupResolver(BODY_SCHEMA),
    defaultValues: DEFAULT_VALUES,
  });

  const { handleSubmit, setError, reset } = methods;

  const { data: { data: event } = {}, isFetching: isFetchingEvent } =
    useGetEvent(+eventId);

  const { data: { data: pathway } = {}, isFetching: isFetchingPathway } =
    useGetEventPathway(+eventId, +id);

  const { mutate: editPathway, isLoading: isEditing } = useMutation(
    (data: Pathway) =>
      API.put(APIRoutes.events.pathways.byId(+eventId, +id), {
        pathway: preparePayload(data),
      }),
    {
      mutationKey: 'eventPathwaysMutation',
      onSuccess: () => {
        toast.success('Zmiany w ścieżce zostały zastosowane');
        navigate(backPath);
        queryClient.invalidateQueries('eventPathways');
      },
      onError: ({ errors }) => {
        const transformedErrors = transformErrors(errors, null);
        Object.keys(transformedErrors).forEach((field: string) => {
          setError(field as never, {
            type: 'custom',
            message: transformedErrors[field],
          });
        });
        toast.error('Wystąpił problem podczas edycji ścieżki');
      },
    },
  );

  const onSubmit = handleSubmit(async (data: Pathway) => editPathway(data));

  useEffect(() => {
    if (pathway) reset({ ...DEFAULT_VALUES, ...parseDefaultValues(pathway) });
  }, [pathway, reset]);

  const isLoading = isFetchingEvent || isFetchingPathway || isEditing;

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: event?.name!,
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj ścieżkę' }]}
      />
      {isFetchingPathway && <LoadingIndicator />}
      <FormWrapper {...methods}>
        <Flex gap={4} mb={4} alignItems="flex-start" pb={FOOTER_HEIGHT}>
          <Box
            w="100%"
            as="form"
            id="pathway-editor"
            onSubmit={onSubmit}
            maxW={FORM_MAX_WIDTH}
          >
            <FormBody isLoading={isLoading} id={+eventId} isEventsForm />
          </Box>
          <ComponentPreview kind="pathway" isEditMode />
        </Flex>
      </FormWrapper>
      <FooterButtons
        isLoading={isLoading}
        formId="pathway-editor"
        onCancel={() => navigate(-2)}
      />
    </Box>
  );
}

export default EventPathwayEdit;
