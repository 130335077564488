import { useMemo } from 'react';
import { Tab, TabList, Tabs } from '@chakra-ui/react';
import map from 'lodash/map';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import useQueryParams from 'utils/useQueryParams';
import { OptionType } from 'types/common';

export interface ListingTabsProps {
  options: OptionType[];
  paramName: string;
}

function ListingTabs({ options, paramName, ...props }: ListingTabsProps) {
  const { search, setSearch } = useQueryParams();
  const tabValue = search.get(paramName);

  const onTabChange = (index: number) => {
    const value = find(options, (_, idx) => idx === index)?.value;
    if (value) {
      search.set('page', '1');
      search.set(paramName, value.toString());
      setSearch(search);
    }
  };

  const index = useMemo(() => {
    const idx = findIndex(options, (opt) => opt.value === tabValue);
    return idx >= 0 ? idx : 0;
  }, [tabValue, options]);

  return (
    <Tabs onChange={onTabChange} index={index} mt={-6} {...props}>
      <TabList>
        {map(options, ({ label }, idx) => (
          <Tab minWidth="170px" key={`listing-tab-${idx}`}>
            {label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
}

export default ListingTabs;
