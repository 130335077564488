import { ColumnDef } from '@tanstack/react-table';
import { PAGE_KIND_DICT } from 'pages/ContentPages/Form/constants';

function GetColumns(): ColumnDef<ContentPage, string>[] {
  return [
    {
      header: 'Id',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Nazwa',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Rodzaj',
      accessorKey: 'kind',
      cell: (info) => PAGE_KIND_DICT[info.getValue()],
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
