/* eslint-disable react/no-array-index-key */
import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex } from '@chakra-ui/react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { serialize } from 'object-to-formdata';
import HeaderNavigation from 'components/HeaderNavigation';
import { FORM_MAX_WIDTH } from 'utils/constants';
import FooterButtons, { FOOTER_HEIGHT } from 'components/FooterButtons';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
  prepareDashboardContainer,
} from 'pages/Dashboard/Containers/Form/constants';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';
import transformErrors from 'utils/api';
import ComponentPreview from 'components/PreviewWrapper';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { convertKeysToSnakeCase } from 'utils/helpers';
import DndWrapper from 'components/DndWrapper';
import ContentComponentsForm from 'components/Content/ContentComponentsForm';
import AddButton from 'components/AddButton';
import FormWrapper from 'components/FormWrapper';

function ContainerCreate() {
  const { dashboardId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let debounce: ReturnType<typeof setTimeout>;

  const methods = useForm<Dashboard>({
    mode: 'onChange',
    resolver: yupResolver(BODY_SCHEMA),
    defaultValues: { contentContainersAttributes: [DEFAULT_VALUES] },
  });

  const { handleSubmit, control } = methods;

  const {
    fields: containers,
    append,
    remove,
    move,
  } = useFieldArray({
    control,
    name: 'contentContainersAttributes',
    keyName: 'fieldId',
    shouldUnregister: true,
  });

  const addContainerHandler = useCallback(() => {
    append({ kind: '' });
  }, [append]);

  const reorderContainers = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      clearTimeout(debounce);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      debounce = setTimeout(() => move(dragIndex, hoverIndex), 500);
    },
    [move],
  );

  const createContainer = useMutation({
    mutationKey: 'dashboardContainersMutation',
    mutationFn: (data: any) => {
      const snakePayload = convertKeysToSnakeCase(
        prepareDashboardContainer(data),
      );
      const formData = serialize(
        snakePayload,
        SERIALIZER_OPTIONS,
        new FormData(),
        'content_container',
      );
      return API.post(
        APIRoutes.dashboard.containers.index(+dashboardId!),
        formData,
      );
    },
  });

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      const responses = await Promise.all(
        data.contentContainersAttributes.map(
          async (formValues: any, index: number) => {
            const response: any = await createContainer.mutateAsync(formValues);
            return { ...response.data, index };
          },
        ),
      );
      const hasErrors = [];
      const createdIds: number[] = [];
      responses.forEach((response) => {
        const hasError = Object.hasOwnProperty.call(response, 'error');
        if (hasError) {
          hasErrors.push(true);
          const {
            error: { data: errors },
            index,
          } = response;
          const transformedErrors = transformErrors(errors, index);
          Object.keys(transformedErrors).forEach((field: string) => {
            methods.setError(
              `contentContainersAttributes[${index}].${field}` as never,
              {
                type: 'custom',
                message: transformedErrors[field],
              },
            );
          });
        } else {
          const { data: container } = response;
          createdIds.push(container.id);
        }
      });

      if (createdIds.length > 0) {
        toast.success(
          responses.length > 1
            ? 'Elementy zostały pomyślnie utworzone!'
            : 'Element został pomyślnie utworzony!',
        );
      }

      if (!hasErrors.length) {
        navigate(ROUTES.dashboard.base);
        queryClient.invalidateQueries('dashboardContainers');
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log({ error });
      toast.error('Wystąpił problem');
    }
  });

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: 'Dashboard',
          to: ROUTES.dashboard.base,
        }}
        crumbs={[{ to: '', label: 'Nowy element' }]}
      />
      <FormWrapper {...methods}>
        <Flex gap={4} mb={4} alignItems="flex-start" pb={FOOTER_HEIGHT}>
          <Box
            w="100%"
            as="form"
            id="containers-creator"
            onSubmit={onSubmit}
            maxW={FORM_MAX_WIDTH}
          >
            {containers.map((container, index) => (
              <DndWrapper
                key={`containers-creator-${container.fieldId}`}
                id={container.fieldId}
                isLoading={createContainer.isLoading}
                index={index}
                reorderContainers={reorderContainers}
                removeContainer={remove}
                ContainerWrapper={ContentComponentsForm}
                multipleContainers
                isDashboard
              />
            ))}
            <AddButton addHandler={addContainerHandler} mt={2} />
          </Box>
          <ComponentPreview kind="dashboard" />
        </Flex>
      </FormWrapper>
      <FooterButtons
        isLoading={createContainer.isLoading}
        isCreate
        formId="containers-creator"
        onCancel={() => navigate(-2)}
      />
    </Box>
  );
}

export default ContainerCreate;
