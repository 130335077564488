const Tooltip = {
  baseStyle: {
    backgroundColor: 'complementary.black',
    padding: '8px 16px',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    textAlign: 'center',
  },
};

export default Tooltip;
