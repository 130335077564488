import { SystemStyleObject } from '@chakra-ui/react';

const h1: SystemStyleObject = {
  fontFamily: 'Bebas Neue',
  fontSize: '40px',
  fontWeight: 400,
  lineHeight: '48px',
};

const h2: SystemStyleObject = {
  fontFamily: 'Bebas Neue',
  fontSize: '32px',
  fontWeight: 400,
  lineHeight: '40px',
};

const h3: SystemStyleObject = {
  fontFamily: 'Bebas Neue',
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '32px',
};

const navigationHistory: SystemStyleObject = {
  fontSize: '17px',
  lineHeight: '24px',
  fontWeight: 600,
  color: 'black',
  display: 'inline-flex',
  alignItems: 'center',
  gap: 2,
  textDecoration: 'none',
  cursor: 'pointer',
};

const Text = {
  baseStyle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
  },
  variants: {
    h1,
    h2,
    h3,
    navigationHistory,
  },
};

export default Text;
