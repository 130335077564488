import { useEffect } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import isNull from 'lodash/isNull';

const useQueryParams = (defaultInit?: URLSearchParamsInit | undefined) => {
  const [search, setSearch] = useSearchParams(defaultInit);

  useEffect(() => {
    setSearch(search);
    // eslint-disable-next-line
  }, []);

  const deleteParams = (omitParams: string[]) => {
    omitParams.forEach((key) => search.delete(key));
    search.set('page', '1');
    setSearch(search);
  };

  const isSearchActive = (searchKey: string) => {
    return !isNull(search.get(searchKey));
  };

  return {
    search,
    queryString: `?${search.toString()}`,
    setSearch,
    deleteParams,
    isSearchActive,
  };
};

export default useQueryParams;
