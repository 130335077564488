import {
  FormControl,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IconEye, IconEyeOff } from '@tabler/icons-react';

type InputProps = ChakraInputProps & {
  name: string;
  label?: string | React.ReactNode;
  defaultValue?: number | string;
};

function PasswordInput({
  label,
  name,
  isRequired,
  defaultValue,
  isDisabled,
  ...rest
}: InputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [show, setShow] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShow(!show);
  };

  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={Boolean(errorMessage)}
      isDisabled={isDisabled}
    >
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup>
        <ChakraInput
          type={show ? 'text' : 'password'}
          {...register(name)}
          defaultValue={defaultValue}
          paddingRight="50px"
          {...rest}
        />
        <InputRightElement cursor="pointer" onClick={handleClick} w="60px">
          {show ? <IconEye /> : <IconEyeOff />}
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

PasswordInput.defaultProps = {
  label: '',
  defaultValue: null,
};

export default PasswordInput;
