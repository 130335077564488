import omit from 'lodash/omit';
import { parseContentComponents } from 'components/Content/constants';
import { NEWSABLE_TYPE } from '../constants';

const PARSED_VALUES: string[] = [
  'date',
  'images',
  'venue',
  'event',
  'attraction',
];

const parseDefaultValues = (news: News) => {
  const result: Record<string, any> = {
    ...omit(parseContentComponents(news), PARSED_VALUES),
  };

  result.date = new Date(news?.date);
  result.imagesAttributes = news?.images;
  result.venueId = news?.venue?.id;
  result.logo = news?.venue?.logo;
  if (news?.event) {
    result.newsableType = NEWSABLE_TYPE.EVENT;
    result.newsableId = news?.event?.id;
  }
  if (news?.attraction) {
    result.newsableType = NEWSABLE_TYPE.ATTRACTION;
    result.newsableId = news?.attraction?.id;
  }

  return result;
};

export default parseDefaultValues;
