import { FormLabel, FormErrorMessage } from '@chakra-ui/react';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EditorWrapper, CONFIG } from 'components/Form/Editor/constants';

type EditorProps = {
  name: string;
  label?: string;
  placeholder?: string;
};

function Editor({ name, label, placeholder }: EditorProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <EditorWrapper isInvalid={Boolean(errorMessage)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ...rest } }) => (
          <ReactQuill
            placeholder={placeholder}
            onChange={(content, _delta, _source, editor) => {
              const contentLength = editor.getText().trim().length;
              if (contentLength) {
                onChange(content);
              } else {
                onChange('');
              }
            }}
            {...CONFIG}
            {...rest}
          />
        )}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </EditorWrapper>
  );
}

Editor.defaultProps = {
  label: '',
  placeholder: '',
};

export default Editor;
