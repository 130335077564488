import {
  differenceInMinutes,
  format,
  intervalToDuration,
  parse,
  startOfDay,
} from 'date-fns';
import isNil from 'lodash/isNil';

const datePrettier = (dateString: string | undefined): string => {
  if (isNil(dateString)) return '';
  const dt = new Date(dateString);
  const date = format(dt, 'dd.MM.yyyy');
  const time = format(dt, 'HH:mm');
  return `${date} ${time}`;
};

const datePrettierNoTime = (dateString: string | undefined): string => {
  if (isNil(dateString)) return '';
  const dt = new Date(dateString);
  const date = format(dt, 'dd.MM.yyyy');
  return `${date}`;
};

const datePrettierNoTimeAndYear = (dateString: string | undefined): string => {
  if (isNil(dateString)) return '';
  const dt = new Date(dateString);
  const date = format(dt, 'dd.MM');
  return `${date}`;
};

const dateRangePrettier = (
  startDate: string | undefined,
  endDate: string | undefined,
) => {
  if (isNil(startDate) || isNil(endDate)) return '';
  if (startDate === endDate) return datePrettier(startDate);
  return `${datePrettier(startDate)} - ${datePrettier(endDate)}`;
};

const dateRangePrettierNoTime = (
  startDate: string | undefined,
  endDate: string | undefined,
) => {
  if (isNil(startDate) || isNil(endDate)) return '';
  if (startDate === endDate) return datePrettierNoTime(startDate);
  return `${datePrettierNoTime(startDate)} - ${datePrettierNoTime(endDate)}`;
};

const dateRangePrettierNoTimeAndYear = (
  startDate: string | undefined,
  endDate: string | undefined,
) => {
  if (isNil(startDate) || isNil(endDate)) return '';
  if (startDate === endDate) return datePrettierNoTimeAndYear(startDate);
  return `${datePrettierNoTimeAndYear(startDate)} - ${datePrettierNoTimeAndYear(
    endDate,
  )}`;
};

const prettierTime = (duration: number) => {
  if (Number.isNaN(duration)) return null;
  const { hours, minutes } = intervalToDuration({
    start: 0,
    end: duration * 60 * 1000,
  });
  const dt = startOfDay(new Date());
  const pd = parse(`${hours}:${minutes}`, 'HH:mm', dt);
  const timeFormat = `${hours ? "H'h'" : ''} ${minutes ? "mm'm'" : ''}`;
  return format(pd, timeFormat);
};

const durationToTime = (duration: number) => {
  if (Number.isNaN(duration)) return null;
  const { hours, minutes } = intervalToDuration({
    start: 0,
    end: duration * 60 * 1000,
  });
  const dt = startOfDay(new Date());
  const pd = parse(`${hours}:${minutes}`, 'HH:mm', dt);
  return format(pd, 'HH:mm');
};

const timeToDuration = (time: string) => {
  const dt = startOfDay(new Date());
  const pd = parse(time, 'HH:mm', dt);
  return differenceInMinutes(pd, dt);
};

const timeRangePrettier = (
  startDate: string | null,
  endDate: string | null,
) => {
  if (isNil(startDate) || isNil(endDate)) return '';
  const start = new Date(startDate);
  const end = new Date(endDate);
  return `${format(start, 'HH:mm')}-${format(end, 'HH:mm')}`;
};

const isValidTime = (time: string) => {
  const dt = startOfDay(new Date());
  return parse(time, 'HH:mm', dt).toString() !== 'Invalid Date';
};

const isValidTimeRange = (time: string) => {
  const [start, end] = time.split(' - ');
  if (isNil(start) || isNil(end)) return false;
  return isValidTime(start) && isValidTime(end);
};

const joinDate = (date: Date, time: Date): string => {
  const stringDate = format(date, 'yyyy-MM-dd');
  const stringTime = format(time, 'HH:mm:00');
  return `${stringDate}T${stringTime}`;
};

const transformActivityDates = (object: any) => {
  const newObject = { ...object };

  if (object.dateFrom) {
    if (object.timeFrom) {
      newObject.activeFrom = joinDate(object.dateFrom, object.timeFrom);
    } else {
      newObject.activeFrom = `${format(
        object.dateFrom,
        'yyyy-MM-dd',
      )}T00:00:00`;
    }
  } else {
    newObject.activeFrom = ' ';
  }

  if (object.dateTo) {
    if (object.timeTo) {
      newObject.activeTo = joinDate(object.dateTo, object.timeTo);
    } else {
      newObject.activeTo = `${format(object.dateTo, 'yyyy-MM-dd')}T23:59:59`;
    }
  } else {
    newObject.activeTo = ' ';
  }

  delete newObject.dateFrom;
  delete newObject.dateTo;
  delete newObject.timeFrom;
  delete newObject.timeTo;

  return newObject;
};

type TransformedDates = {
  dateFrom?: Date;
  timeFrom?: Date;
  dateTo?: Date;
  timeTo?: Date;
};

const transformStringDatesToDateObject = (object: Record<string, any>) => {
  const newObject: TransformedDates = {};
  const { activeFrom, activeTo } = object;

  if (activeFrom) {
    newObject.dateFrom = new Date(activeFrom);
    newObject.timeFrom = new Date(activeFrom);
  }

  if (activeTo) {
    newObject.dateTo = new Date(activeTo);
    newObject.timeTo = new Date(activeTo);
  }

  return newObject;
};

export {
  datePrettier,
  datePrettierNoTime,
  dateRangePrettier,
  dateRangePrettierNoTime,
  dateRangePrettierNoTimeAndYear,
  prettierTime,
  durationToTime,
  timeToDuration,
  timeRangePrettier,
  isValidTime,
  isValidTimeRange,
  transformActivityDates,
  transformStringDatesToDateObject,
};
