import { useIsMutating } from 'react-query';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Table from 'pages/Venues/Pathways/Listing/components/Table';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import LoadingIndicator from 'components/LoadingIndicator';
import HeaderNavigation from 'pages/Venues/Pathways/Listing/components/HeaderNavigation';
import useQueryParams from 'utils/useQueryParams';
import { useGetVenue, useGetVenuePathways } from 'api/venues';

function VenuePathways() {
  const { id = '' } = useParams();
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });

  const isMutating = !!useIsMutating({
    mutationKey: ['venuePathwaysMutation'],
  });

  const { data: { data: venue } = {}, isFetching: isFetchingVenue } =
    useGetVenue(+id);

  const {
    isFetching: isFetchingPathways,
    data: { data: pathways, totals } = { data: [], totals: 0 },
  } = useGetVenuePathways(+id, queryString);

  const showLoading = isMutating || isFetchingVenue || isFetchingPathways;

  return (
    <Box mb={FOOTER_HEIGHT}>
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <>
          <HeaderNavigation name={venue?.name!} id={id} />
          <Table data={pathways} venueId={+id} />
        </>
      )}
      <Footer totals={totals} footerButtons={null} />
    </Box>
  );
}

export default VenuePathways;
