const FormLabel = {
  baseStyle: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
    marginRight: 0,
    marginBottom: 1,
    _disabled: {
      opacity: 1,
      color: 'complementary.disabled',
    },
  },
};

export default FormLabel;
