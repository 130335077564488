import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { IconChevronDown } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from 'context/AppContext';
import {
  LANGUAGES_LABELS,
  LANGUAGES_OPTIONS,
} from 'components/LanguageMenu/dictionary';
import ROUTES from 'app/routes';
import { setLanguageHeader } from 'api';

function LanguageMenu() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentLanguage, setCurrentLanguage } = useAppContext();

  return (
    <Flex align="center" gap={2} height="fit-content" color="#fff" margin={4}>
      <Flex align="center" gap={1}>
        <Menu placement="bottom-end">
          <MenuButton
            as={Button}
            variant="unstyled"
            rightIcon={<IconChevronDown size={24} />}
            display="flex"
            fontWeight="600"
            fontSize="14px"
          >
            Język: {LANGUAGES_LABELS[currentLanguage]}
          </MenuButton>
          <MenuList
            bgColor="complementary.black"
            border="none"
            minWidth="173px"
          >
            {LANGUAGES_OPTIONS.map(({ value, label }) => (
              <MenuItem
                bgColor="complementary.black"
                justifyContent="end"
                fontSize="14px"
                fontWeight="600"
                key={value}
                onClick={() => {
                  setLanguageHeader(value);
                  setCurrentLanguage(value);
                  if (pathname !== ROUTES.onboardScreens.base) {
                    navigate(ROUTES.onboardScreens.base);
                  }
                }}
              >
                {label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
}

export default LanguageMenu;
