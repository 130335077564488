import omit from 'lodash/omit';
import { durationToTime, transformStringDatesToDateObject } from 'utils/date';

const PARSED_VALUES: string[] = ['attraction'];

const parseDefaultValues = (facilityAttraction: AttractionFacility) => {
  const result: Record<string, any> = {
    ...omit(facilityAttraction, PARSED_VALUES),
  };

  const { attraction } = facilityAttraction;

  result.attractionAttributes = {
    ...attraction,
    ...transformStringDatesToDateObject(attraction),
    venueId: attraction?.venue?.id,
    imagesAttributes: [{ ...attraction?.image, position: 0 }],
    duration: attraction?.duration
      ? durationToTime(Number(attraction?.duration))
      : '',
  };

  return result;
};

export default parseDefaultValues;
