import styled from '@emotion/styled';

const Td = styled.td`
  padding-inline: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  height: 136px;
  vertical-align: top;
  padding-top: 8px;
  padding-bottom: 8px;

  :nth-of-type(1) {
    width: 64px;
    font-weight: 600;
    text-align: center;
  }
  :nth-of-type(2) {
    padding-top: 0;
    width: 168px;
    text-align: center;
  }
  :nth-of-type(3) {
    width: 184px;
  }
  :nth-of-type(4) {
    width: 256px;
  }
  :nth-of-type(5) {
    width: 188px;
    font-weight: 600;
  }
  :nth-of-type(6) {
    padding-top: 0;
    display: flex;
  }
`;

export default Td;
