import { AspectRatio, Box, Center } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import Lottie from 'lottie-react';
import MediaPlaceholder from 'components/MediaPlaceholder';
import { PreviewComponentProps } from 'types/common';

const isValidJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

function SplashScreenPreview({ formValues }: PreviewComponentProps) {
  return (
    <AspectRatio w="100%" ratio={0.72}>
      {isEmpty(formValues) ? (
        <Box bgColor="complementary.gray">
          <Center>
            <MediaPlaceholder text="Wypełnij dane w formularzu, aby wygenerować podgląd" />
          </Center>
        </Box>
      ) : (
        <Box bgColor={formValues?.animationBackgroundColor}>
          <Lottie
            animationData={
              isValidJson(formValues?.animation)
                ? JSON.parse(formValues?.animation)
                : null
            }
            width="100%"
          />
        </Box>
      )}
    </AspectRatio>
  );
}

export default SplashScreenPreview;
