import { Box, Flex } from '@chakra-ui/react';
import Sidebar from 'components/Sidebar';
import {
  SIDEBAR_CLOSED_WIDTH,
  SIDEBAR_OPENED_WIDTH,
} from 'components/Layout/constants';
import Navbar from 'components/Layout/Navbar';
import { useAppContext } from 'context/AppContext';

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  const { isSidebarExpanded, toggleSidebar } = useAppContext();

  return (
    <Flex w="100%">
      <Sidebar
        isExpanded={isSidebarExpanded}
        expand={toggleSidebar}
        openWidth={SIDEBAR_OPENED_WIDTH}
        closeWidth={SIDEBAR_CLOSED_WIDTH}
      />
      <Box backgroundColor="complementary.lightGray" minH="100vh" flex={1}>
        <Navbar />
        <Box px={6} py={8}>
          {children}
        </Box>
      </Box>
    </Flex>
  );
}

export default Layout;
