import * as yup from 'yup';
import { ERROR_MESSAGES } from 'utils/form';

export const BODY_SCHEMA = yup.object({
  position: yup
    .number()
    .nullable()
    .required(ERROR_MESSAGES.required)
    .typeError(ERROR_MESSAGES.invalidNumberType),
  poiId: yup.string().nullable().required(ERROR_MESSAGES.required),
  plan: yup.mixed().nullable().required(ERROR_MESSAGES.required),
});

export const DEFAULT_VALUES = {
  position: 0,
  poiId: null,
  plan: null,
};
