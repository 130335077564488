import { Box, Flex, Stack } from '@chakra-ui/react';
import Card from 'components/Card';
import Input from 'components/Form/Input';
import { SectionTitle } from 'components/Form/SectionText';
import LoadingSpinner from 'components/FormSpinner';
import { FormBodyProps } from 'types/common';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

function FormBody({ isLoading }: FormBodyProps) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <LoadingSpinner />}
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <Stack spacing={8}>
            <SectionTitle as="legend">Nagłówek dashboardu</SectionTitle>
            <Input
              label="Nagłówek"
              placeholder="Wpisz nagłówek"
              name="header"
            />
            <Input label="Teaser" placeholder="Wpisz teaser" name="teaser" />
          </Stack>
        </Box>
      </Card>
    </Flex>
  );
}

export default FormBody;
