import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

const StyledAudio = styled.audio`
  cursor: pointer;
  width: 100%;
  height: 50px;
`;

interface AudioPlayerProps {
  src: any;
}

function AudioPlayer({ src }: AudioPlayerProps) {
  const ref = useRef<null | HTMLVideoElement>(null);
  const [audioPreview, setAudioPreview] = useState<string | undefined>();

  useEffect(() => {
    if (!src || (!src?.url && !src?.path)) {
      setAudioPreview(undefined);
      return;
    }

    if (!src.url) {
      setAudioPreview(URL.createObjectURL(src));
      return;
    }

    setAudioPreview(src.url);
  }, [src]);

  return <StyledAudio src={audioPreview} ref={ref} loop controls />;
}

export default AudioPlayer;
