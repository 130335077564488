import { camelCase, isArray, isPlainObject, isString, snakeCase } from 'lodash';
import dot from 'dot-object';

const convertKeysToSnakeCase = (obj: any): any => {
  if (isArray(obj)) {
    return obj.map((v) => convertKeysToSnakeCase(v));
  }
  if (isPlainObject(obj)) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [`${key.startsWith('_') ? '_' : ''}${snakeCase(key)}`]:
          convertKeysToSnakeCase(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

const convertKeysToCamelCase = (obj: any): any => {
  if (isString(obj)) {
    return obj;
  }
  if (isArray(obj)) {
    return obj.map((v) => convertKeysToCamelCase(v));
  }
  if (isPlainObject(obj)) {
    const convertedObject = dot.object(obj);
    return Object.keys(convertedObject).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: convertKeysToCamelCase(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

const transformRemoved = (containers: any[]) =>
  containers?.map((container) => ({
    ...container,
    _destroy: true,
  }));

const transformPosition = (containers: any[]) =>
  containers?.map((container, index) => ({
    ...container,
    position: index,
  }));

export {
  convertKeysToSnakeCase,
  convertKeysToCamelCase,
  transformRemoved,
  transformPosition,
};
