import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
} from '@chakra-ui/react';
import { generatePath, Link } from 'react-router-dom';
import ROUTES from 'app/routes';
import BackButton from 'components/BackButton';

interface HeaderNavigationProps {
  name: string;
  id: string;
}

function HeaderNavigation({ name, id }: HeaderNavigationProps) {
  const backPath = generatePath(ROUTES.events.base);

  return (
    <Flex mb={8} align="center" justify="space-between">
      <Box>
        <BackButton backPath={backPath} />
        <Breadcrumb fontSize="17px">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={backPath} fontWeight={700}>
              {name}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Agenda</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>
      <Button
        as={Link}
        to={generatePath(ROUTES.events.attractionFacilities.create, {
          id,
        })}
      >
        NOWE WYDARZENIE
      </Button>
    </Flex>
  );
}

export default HeaderNavigation;
