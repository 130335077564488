import { Controller, useFormContext } from 'react-hook-form';
import { Props as ReactSelectProps } from 'react-select';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
} from '@chakra-ui/react';
import { get, find, map, pullAt } from 'lodash';
import SingleSelect from 'components/Form/Select/SingleSelect';
import MultiSelect from 'components/Form/Select/MultiSelect';
import { OptionType } from 'types/common';
import SelectedOptions from 'components/Form/Select/ExtendedSelect/SelectedOptions';

const isOptionType = (a: unknown): a is OptionType => {
  return (a as OptionType)?.value !== undefined;
};

interface SelectProps extends ReactSelectProps {
  options: OptionType[];
  isMulti?: boolean;
  disabled?: boolean;
  openMenuOnFocus?: boolean;
  autoFocus?: boolean;
  label: string;
  name: string;
  placeholder?: string;
  isRequired?: boolean;
  hidden?: boolean;
  showError?: boolean;
  isLoading?: boolean;
}

function Select({
  label,
  name,
  options,
  placeholder,
  isRequired,
  isMulti,
  disabled,
  openMenuOnFocus,
  autoFocus,
  hidden,
  showError,
  isLoading,
  ...props
}: SelectProps) {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const errorMessage = get(errors, name)?.message as string;
  const SelectComponent = isMulti ? MultiSelect : SingleSelect;

  return (
    <FormControl
      hidden={hidden}
      isInvalid={Boolean(errorMessage)}
      isRequired={isRequired}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          const pickedValues = Array.isArray(value)
            ? value.map((option) => find(options, { value: option }))
            : [options.find((option) => option.value === value)];

          const handleReorder = (sourceIndex: number, targetIndex: number) => {
            [pickedValues[sourceIndex], pickedValues[targetIndex]] = [
              pickedValues[targetIndex],
              pickedValues[sourceIndex],
            ];
            onChange(map(pickedValues, 'value'));
          };

          const handleDelete = (index: number) => {
            pullAt(pickedValues, index);
            onChange(map(pickedValues, 'value'));
          };

          return (
            <Stack spacing={2}>
              <SelectComponent
                placeholder={placeholder || 'Wybierz'}
                options={options}
                inputId={name}
                value={pickedValues || null}
                onChange={(newValue: OptionType | OptionType[] | unknown) => {
                  if (Array.isArray(newValue)) {
                    onChange(newValue.map((option) => option.value));
                  } else if (isOptionType(newValue)) {
                    onChange(newValue.value);
                  } else {
                    onChange(newValue);
                  }
                }}
                isMulti={isMulti}
                isDisabled={disabled}
                isLoading={isLoading}
                openMenuOnFocus={openMenuOnFocus}
                autoFocus={autoFocus}
                instanceId={name}
                closeMenuOnSelect={!isMulti}
                withError={Boolean(errorMessage)}
                noOptionsMessage={({ inputValue }) =>
                  !inputValue ? null : 'Brak wyników'
                }
                controlShouldRenderValue={false}
                hideSelectedOptions
                {...props}
              />
              {!isLoading && (
                <Stack spacing={2}>
                  {pickedValues.map((item, index) => (
                    <SelectedOptions
                      item={item}
                      index={index}
                      handleReorder={handleReorder}
                      handleDelete={handleDelete}
                    />
                  ))}
                </Stack>
              )}
            </Stack>
          );
        }}
      />
      {showError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}
Select.defaultProps = {
  isMulti: false,
  disabled: false,
  openMenuOnFocus: false,
  autoFocus: false,
  placeholder: undefined,
  isRequired: false,
  hidden: false,
  showError: true,
  isLoading: false,
};

export default Select;
