import { SystemStyleObject } from '@chakra-ui/react';

const ghost: SystemStyleObject = {
  _hover: {
    bg: 'blackAlpha.50',
  },
};

const unstyled: SystemStyleObject = {
  display: 'flex',
};

const solid: SystemStyleObject = {
  fontSize: '17px',
  color: 'complementary.black',
  backgroundColor: 'complementary.primary',
  padding: '12px 8px',
  _disabled: {
    backgroundColor: 'complementary.gray',
    color: 'white',
    opacity: 'unset',
  },
  _hover: {
    color: 'complementary.black',
    backgroundColor: 'complementary.primary',
    opacity: 0.7,
    _disabled: {
      backgroundColor: 'complementary.gray',
      opacity: 'unset',
      color: 'white',
    },
  },
};

const outlined: SystemStyleObject = {
  fontSize: '17px',
  color: 'complementary.black',
  borderColor: 'complementary.black',
  border: 'solid 1px',
  padding: '12px 8px',
  _disabled: {
    backgroundColor: 'complementary.gray',
    color: 'white',
    opacity: 'unset',
  },
  _hover: {
    color: 'complementary.black',
    borderColor: 'complementary.black',
    opacity: 0.7,
    _disabled: {
      backgroundColor: 'complementary.gray',
      opacity: 'unset',
      color: 'white',
    },
  },
};

const solidSecondary: SystemStyleObject = {
  fontSize: '17px',
  color: '#fff',
  backgroundColor: 'complementary.black',
  padding: '12px 8px',
  _disabled: {
    backgroundColor: 'complementary.gray',
    color: 'white',
    opacity: 'unset',
  },
  _hover: {
    color: '#fff',
    backgroundColor: 'complementary.black',
    opacity: 0.7,
    _disabled: {
      backgroundColor: 'complementary.gray',
      opacity: 'unset',
      color: 'white',
    },
  },
};

const link: SystemStyleObject = {
  fontSize: '17px',
  color: 'complementary.black',
  textAlign: 'center',
  padding: '12px 8px',
  _disabled: {
    color: 'complementary.gray',
  },
  _hover: {
    opacity: 0.7,
    _disabled: {
      textDecoration: 'underline',
      color: 'complementary.gray',
      opacity: 0.4,
    },
  },
};

const Button = {
  baseStyle: {
    borderRadius: '4px',
    lineHeight: '24px',
    fontWeight: 'normal',
  },
  variants: {
    ghost,
    unstyled,
    solid,
    outlined,
    solidSecondary,
    link,
  },
};

export default Button;
