/* eslint-disable react-hooks/exhaustive-deps */
import { VStack, Text, TabPanel, HStack } from '@chakra-ui/react';
import { IconCalendarEvent, IconMapPin } from '@tabler/icons-react';
import { find } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import TicketsPreview from 'components/Content/Previews/TicketsPreview';
import SchedulePreview from 'components/Previews/SchedulePreview';
import { dateRangePrettierNoTime } from 'utils/date';
import ImageGallery from 'components/Content/Previews/ImageGallery';
import VenueSelect from 'components/Previews/EventPreview/VenueSelect';
import { HOURS_KIND } from 'components/Schedule/constants';
import WYSIWYGContent from 'components/WYSIWYGContent';

interface InformationsTabProps {
  formValues: EventItem;
  venues: Venue[];
}

function InformationsTab({ formValues, venues }: InformationsTabProps) {
  const methods = useForm({
    mode: 'onChange',
  });
  const { control, setValue } = methods;
  const venueId = useWatch({
    control,
    name: 'venueId',
  });

  useEffect(() => {
    const defaultValue = venues?.[0]?.id;
    if (defaultValue) {
      setValue('venueId', defaultValue);
    }
  }, [venues]);

  const selectedVenue = useMemo(() => find(venues, { id: venueId }), [venueId]);

  return (
    <TabPanel>
      <VStack spacing={4}>
        <HStack spacing="12px" w="100%">
          <IconCalendarEvent />
          <Text fontSize={17} lineHeight="24px" w="100%">
            {formValues?.dateFrom && formValues?.dateTo
              ? dateRangePrettierNoTime(
                  formValues?.dateFrom?.toString(),
                  formValues?.dateTo?.toString(),
                )
              : 'Data imprezy'}
          </Text>
        </HStack>
        {venues?.map((venue) => (
          <HStack key={venue?.id} spacing={3} w="100%">
            <IconMapPin />
            <Text fontSize={17} lineHeight="24px" w="100%">
              {venue?.name}
            </Text>
          </HStack>
        ))}
        <WYSIWYGContent
          content={formValues?.description}
          placeholder="Tu pojawi się opis imprezy"
        />
      </VStack>
      <VStack spacing={6} mt={6}>
        <TicketsPreview formValues={formValues} />
        <SchedulePreview
          label="Godziny wydarzenia"
          hoursKind={formValues?.hoursKind}
          schedules={
            formValues?.hoursKind === HOURS_KIND.OBJECT
              ? venues?.[0]?.schedules
              : formValues?.schedulesAttributes
          }
        />
        {selectedVenue && (
          <VStack w="100%" spacing={4}>
            <Text variant="h2" w="100%">
              WEJŚCIA DO OBIEKTU
            </Text>
            {venues?.length > 1 && (
              <FormProvider {...methods}>
                <VenueSelect label="Wybierz obiekt" venues={venues} />
              </FormProvider>
            )}
            <ImageGallery slides={selectedVenue?.entriesImages} />
          </VStack>
        )}
      </VStack>
    </TabPanel>
  );
}

export default InformationsTab;
