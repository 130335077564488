import { useQuery } from 'react-query';
import qs from 'query-string';
import { isNumber } from 'lodash';
import { API, APIRoutes, DEFAULT_STALE_TIME, EXTENDED_STALE_TIME } from 'api';
import { ATTRACTION_KIND } from 'pages/Attractions/Form/constants';

const fetchVenues = async (queryString: string) => {
  const { data: response } = await API.get(APIRoutes.venues.index(queryString));
  return {
    data: response?.data,
    totals: response?.meta?.pagination?.objects,
  };
};

const fetchVenue = async (id: number) => {
  const { data } = await API.get(APIRoutes.venues.byId(id));
  return data;
};

const fetchVenuePathways = async (id: number, queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.venues.pathways.index(id, queryString),
  );
  return {
    data: response?.data,
    totals: response?.meta?.pagination?.objects,
  };
};

const fetchVenuePathway = async (venudeId: number, id: number) => {
  const { data } = await API.get(APIRoutes.venues.pathways.byId(venudeId, id));
  return data;
};

const fetchVenuePlans = async (id: number, queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.venues.plans.index(id, queryString),
  );
  return {
    data: response?.data,
    totals: response?.meta?.pagination?.objects,
  };
};

const fetchVenuePlan = async (venudeId: number, id: number) => {
  const { data } = await API.get(APIRoutes.venues.plans.byId(venudeId, id));
  return data;
};

const fetchMobileVenueAttractions = async (
  venueId: number,
  kind: ATTRACTION_KIND,
) => {
  const { data } = await API.get(
    APIRoutes.venues.mobile.attractions(venueId, kind),
  );
  return data;
};

const fetchMobileVenues = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.venues.mobile.index);
  return data;
};

const fetchMobileVenuePathways = async (id: number) => {
  const {
    data: { data },
  } = await API.get(APIRoutes.venues.mobile.pathways(id));
  return data;
};

const useGetVenues = (queryString: string, enabled = true) =>
  useQuery<VenuesResponse>(
    ['venues', queryString],
    () => fetchVenues(queryString),
    {
      staleTime: DEFAULT_STALE_TIME,
      enabled,
    },
  );

const useGetVenue = (id: number, isPreview = false) =>
  useQuery<VenueReponse>(['venue', id, isPreview], () => fetchVenue(id), {
    enabled: isNumber(id),
    staleTime: isPreview ? EXTENDED_STALE_TIME : 0,
  });

const useGetVenuePathways = (id: number, queryString: string, enabled = true) =>
  useQuery<PathwaysResponse>(
    ['venuePathways', id, queryString],
    () => fetchVenuePathways(id, queryString),
    { enabled, staleTime: DEFAULT_STALE_TIME },
  );

const useGetVenuePathway = (venueId: number, id: number) =>
  useQuery<PathwayResponse>(['venuePathway', venueId, id], () =>
    fetchVenuePathway(+venueId, +id),
  );

const useGetAllVenues = (enabled = true) => {
  const queryString = qs.stringify({
    page_size: 'all',
  });
  return useQuery<VenuesResponse>(
    ['allVenues'],
    () => fetchVenues(`?${queryString}`),
    {
      enabled,
      staleTime: EXTENDED_STALE_TIME,
    },
  );
};

const useGetVenuePlans = (id: number, queryString: string, enabled = true) =>
  useQuery<PlansResponse>(
    ['venuePlans', id, queryString],
    () => fetchVenuePlans(id, queryString),
    {
      enabled,
    },
  );

const useGetVenuePlan = (venueId: number, id: number) =>
  useQuery<PlanResponse>(['venuePlan', venueId, id], () =>
    fetchVenuePlan(+venueId, +id),
  );

const useGetMobileVenueAttractions = (
  venueId: number,
  kind: ATTRACTION_KIND,
  enabled: boolean,
) =>
  useQuery<AttractionsResponse>(
    ['mobileVenueAttractions', venueId, kind],
    () => fetchMobileVenueAttractions(venueId, kind),
    { enabled, staleTime: EXTENDED_STALE_TIME },
  );

const useGetMobileVenues = (enabled = true) =>
  useQuery<Venue[]>(['mobileVenues'], () => fetchMobileVenues(), {
    enabled,
    staleTime: DEFAULT_STALE_TIME,
  });

const useGetMobileVenuePathways = (id: number, enabled = true) =>
  useQuery<Pathway[]>(
    ['mobileVenuePathways', id],
    () => fetchMobileVenuePathways(id),
    { enabled, staleTime: DEFAULT_STALE_TIME },
  );

export {
  useGetVenues,
  useGetVenue,
  useGetVenuePathways,
  useGetVenuePathway,
  useGetAllVenues,
  useGetVenuePlans,
  useGetVenuePlan,
  useGetMobileVenueAttractions,
  useGetMobileVenues,
  useGetMobileVenuePathways,
};
