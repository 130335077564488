import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

interface TableWrapperProps extends BoxProps {
  children: React.ReactNode;
}

function TableWrapper({ children, ...props }: TableWrapperProps) {
  return (
    <Box
      overflow="auto"
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export default TableWrapper;
