/* eslint-disable @typescript-eslint/dot-notation, no-return-assign */
import axios from 'axios';
import { ATTRACTION_KIND } from 'pages/Attractions/Form/constants';
import { getCredentials } from 'utils/auth';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const setHeaders = ({
  client,
  accessToken,
  tokenType,
  uid,
  acceptLanguage,
}: Credentials) => {
  API.defaults.headers.common['client'] = client;
  API.defaults.headers.common['access-token'] = accessToken;
  API.defaults.headers.common['uid'] = uid;
  API.defaults.headers.common['token-type'] = tokenType;
  API.defaults.headers.common['accept-language'] = acceptLanguage;
};

const setLanguageHeader = (acceptLanguage: string) =>
  (API.defaults.headers.common['accept-language'] = acceptLanguage);

const credentials = getCredentials();

if (credentials.accessToken) {
  setHeaders(credentials);
}

const destroySession: () => void = () => {
  delete API.defaults.headers.common['client'];
  delete API.defaults.headers.common['access-token'];
  delete API.defaults.headers.common['uid'];
  delete API.defaults.headers.common['token-type'];
  delete API.defaults.headers.common['accept-language'];
  localStorage.clear();
};

const APIRoutes = {
  auth: {
    signIn: 'admin_auth/sign_in',
    logout: 'admin_auth/sign_out',
    currentUser: 'admins/current',
  },
  users: {
    index: (queryString?: string) => `/admins${queryString ?? ''}`,
    byId: (id: number) => `/admins/${id}`,
    edit: (id: number) => `admins/${id}/edit`,
  },
  splashScreens: {
    index: (queryString?: string) => `/splash_screens${queryString ?? ''}`,
    byId: (id: number) => `/splash_screens/${id}`,
  },
  onboardScreens: {
    index: (queryString?: string) => `/onboard_screens${queryString ?? ''}`,
    byId: (id: number) => `/onboard_screens/${id}`,
    sort: (id: number) => `/onboard_screens/${id}/sort`,
  },
  venues: {
    index: (queryString?: string) => `/venues${queryString ?? ''}`,
    byId: (id: number) => `/venues/${id}`,
    pathways: {
      index: (id: number, queryString?: string) =>
        `/venues/${id}/pathways${queryString ?? ''}`,
      byId: (venueId: number, id: number) =>
        `/venues/${venueId}/pathways/${id}`,
    },
    plans: {
      index: (id: number, queryString?: string) =>
        `/venues/${id}/plans${queryString ?? ''}`,
      byId: (venueId: number, id: number) => `/venues/${venueId}/plans/${id}`,
      sort: (venueId: number, id: number) =>
        `/venues/${venueId}/plans/${id}/sort`,
    },
    mobile: {
      index: '/mobile/venues',
      attractions: (venueId: number, kind: ATTRACTION_KIND, limit = 20) =>
        `/mobile/venues/${venueId}/attractions?kind=${kind}&limit=${limit}`,
      pathways: (venueId: number) => `mobile/venues/${venueId}/pathways`,
    },
  },
  news: {
    index: (queryString?: string) => `/news_items${queryString ?? ''}`,
    byId: (id: number) => `/news_items/${id}`,
  },
  contentComponents: (queryString?: string) =>
    `/content_components?${queryString ?? ''}`,
  attractions: {
    index: (queryString?: string) => `/attractions${queryString ?? ''}`,
    byId: (id: number) => `/attractions/${id}`,
  },
  pathways: {
    index: (queryString?: string) => `/pathways${queryString ?? ''}`,
    byId: (id: number) => `/pathways/${id}`,
  },
  events: {
    index: (queryString?: string) => `/events${queryString ?? ''}`,
    byId: (id: number) => `/events/${id}`,
    pathways: {
      index: (id: number, queryString?: string) =>
        `/events/${id}/pathways${queryString ?? ''}`,
      byId: (eventId: number, id: number) =>
        `/events/${eventId}/pathways/${id}`,
    },
    attractionFacilities: {
      index: (id: number, queryString?: string) =>
        `/events/${id}/attraction_facilities${queryString ?? ''}`,
      byId: (eventId: number, id: number) =>
        `/events/${eventId}/attraction_facilities/${id}`,
    },
    mobile: {
      index: (limit = 5) => `/mobile/events?limit=${limit}`,
      agenda: {
        index: (id: number, queryString?: string) =>
          `/mobile/events/${id}/attractions${queryString ?? ''}`,
        days: (id: number) => `/mobile/events/${id}/agenda_days`,
      },
      pathways: (eventId: number) => `mobile/events/${eventId}/pathways`,
      attractions: (eventId: number) => `mobile/events/${eventId}/attractions`,
    },
  },
  dashboard: {
    index: () => '/dashboards',
    byId: (id: number) => `/dashboards/${id}`,
    containers: {
      index: (id: number, queryString?: string) =>
        `/dashboards/${id}/content_containers${queryString ?? ''}`,
      byId: (dashboardId: number, id: number) =>
        `/dashboards/${dashboardId}/content_containers/${id}`,
    },
    currentForecast: (queryString?: string) =>
      `/mobile/weather/current_forecast${queryString ?? ''}`,
  },
  linkTypes: (queryString?: string) => `/link_types${queryString ?? ''}`,
  contentPages: {
    index: (queryString?: string) => `/content_pages${queryString ?? ''}`,
    byId: (id: number) => `/content_pages/${id}`,
  },
};

const DEFAULT_STALE_TIME = 10 * 1000;
const EXTENDED_STALE_TIME = 30 * 1000;

export {
  API,
  APIRoutes,
  DEFAULT_STALE_TIME,
  EXTENDED_STALE_TIME,
  setHeaders,
  setLanguageHeader,
  destroySession,
};
