import { Icon, IconProps } from '@chakra-ui/react';

function BrandLogo(props: IconProps) {
  return (
    <Icon
      width="111px"
      height="109.4px"
      viewBox="0 0 111 109.4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.385 48.538h-7.452V35.011h10.883v2.553H37.48v8.42h4.904v2.553zM37.481 56.983v-4.977h-2.548v7.543h10.883v-2.566H37.48zM77.08 59.549h-2.535V37.565h-2.587c-1.677 6.421-7.49 10.973-14.198 10.973h-2.727v-2.553h2.727c5.851 0 10.87-4.178 11.945-9.929l.192-1.044h7.195v24.537h-.013z"
        fill="#fff"
      />
      <path
        d="M61.243 37.565v-2.553c-6.286 0-11.471 4.822-12.111 10.972h-3.316v2.553h3.316c.627 6.177 5.825 11.012 12.123 11.012v-2.553c-5.313 0-9.64-4.358-9.64-9.71-.013-5.363 4.315-9.721 9.628-9.721zM36.956 70.276v2.283h4.673v1.882h-6.696V71.18l-.998.439v-1.935l.998-.438v-4.204h2.023v3.301l1.985-.89v1.935l-1.985.89zM44.151 69.76v-.025c0-2.67 2.087-4.848 4.968-4.848 2.88 0 4.941 2.153 4.941 4.822v.026c0 2.669-2.086 4.848-4.967 4.848-2.88.013-4.942-2.153-4.942-4.822zm7.759 0v-.025c0-1.612-1.178-2.953-2.817-2.953-1.652 0-2.79 1.315-2.79 2.927v.026c0 1.612 1.177 2.953 2.816 2.953 1.651 0 2.79-1.316 2.79-2.927zM57.799 65.041h3.636c2.931 0 4.954 2.025 4.954 4.668v.026c0 2.643-2.022 4.693-4.954 4.693h-3.636v-9.386zm2.048 1.87v5.66h1.588c1.677 0 2.803-1.134 2.803-2.797v-.026c0-1.663-1.126-2.824-2.803-2.824h-1.588v-.013zM69.321 72.868l5.121-6.008h-4.967v-1.805h7.604v1.573l-5.12 6.008h5.12v1.806h-7.758v-1.573zM50.783 62.334l-.781 1.844h-2.19l.782-1.844h2.189zM74.954 62.334l-.78 1.844h-2.19l.781-1.844h2.19z"
        fill="#fff"
      />
    </Icon>
  );
}

export default BrandLogo;
