import { useMemo } from 'react';
import { find } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import Select from 'components/Form/Select';
import { useGetAllVenues } from 'api/venues';

function VenueSelect() {
  const { control } = useFormContext();
  const venueId = useWatch({
    control,
    name: 'venueId',
  });

  const { isFetching, data: { data: venues } = { data: [] } } =
    useGetAllVenues();

  const venuesOptions = useMemo(
    () =>
      venues?.map((venue) => ({
        label: venue.name,
        value: venue.id,
      })),
    [venues],
  );

  const floorOptions = useMemo(() => {
    const selectedVenue = find(venues, { id: venueId });
    const options = selectedVenue?.plans?.map(({ position, id }) => ({
      label: position.toString(),
      value: id,
    }));
    return options || [];
  }, [venueId, venues]);

  return (
    <>
      <Select
        label="Obiekt, w którym znajduje się atrakcja"
        placeholder="Wybierz obiekt"
        name="venueId"
        isLoading={isFetching}
        options={venuesOptions}
        isClearable
      />
      <Select
        label="Piętro w obiekcie"
        placeholder="Wybierz piętro"
        name="planId"
        isLoading={isFetching}
        options={floorOptions}
      />
    </>
  );
}

export default VenueSelect;
