/* eslint-disable @typescript-eslint/naming-convention */
import * as yup from 'yup';
import { CONTENT_CONTAINER_SCHEMA } from 'components/Content/constants';
import { ERROR_MESSAGES } from 'utils/form';

export enum NEWSABLE_TYPE {
  EVENT = 'Event',
  ATTRACTION = 'Attraction',
}

export const BODY_SCHEMA = yup.object({
  imagesAttributes: yup.array().of(
    yup.object().shape({
      image: yup.mixed().nullable().required(ERROR_MESSAGES.required),
      position: yup.number().required(),
    }),
  ),
  title: yup
    .string()
    .trim()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  author: yup
    .string()
    .trim()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  date: yup.date().nullable().required(ERROR_MESSAGES.required),
  description: yup.string().trim().required(ERROR_MESSAGES.required),
  shortDescription: yup
    .string()
    .trim()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  venueId: yup.number().nullable(),
  newsableType: yup.string().notRequired(),
  newsableId: yup
    .number()
    .nullable()
    .when('newsableType', {
      is: (newsableType: string) => newsableType,
      then: (schema) => schema.required(ERROR_MESSAGES.required),
    }),
  contentContainersAttributes: yup.array().of(CONTENT_CONTAINER_SCHEMA),
});

export const DEFAULT_VALUES = {
  imagesAttributes: [
    {
      image: null,
      position: 0,
    },
  ],
  title: '',
  author: '',
  date: new Date(),
  description: '',
  shortDescription: '',
  venueId: null,
  newsableId: null,
  newsableType: '',
  contentContainersAttributes: [],
};
