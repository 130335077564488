/* eslint-disable react/no-array-index-key */
import {
  Box,
  AspectRatio,
  Center,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
} from '@chakra-ui/react';
import { useState } from 'react';
import useImage from 'utils/useImage';
import MediaPlaceholder from 'components/MediaPlaceholder';
import InformationsTab from 'components/Previews/VenuePreview/InformationsTab';
import AttractionsTab from 'components/Previews/VenuePreview/AttractionsTab';
import PlansTab from 'components/Previews/VenuePreview/PlansTab';
import { PreviewComponentProps } from 'types/common';

const TAB_LIST = ['INFORMACJE', 'ATRAKCJE', 'PLAN'];

function VenuePreview({ formValues }: PreviewComponentProps) {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const imagePreview = useImage(formValues?.commonImagesAttributes?.[0]?.image);
  const logoPreview = useImage(formValues?.logo);

  return (
    <Box>
      <AspectRatio width="100%" ratio={1.37}>
        <Center
          bgColor="complementary.gray"
          bgImage={imagePreview}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition="50% 50%"
          position="relative"
        >
          {!imagePreview && (
            <MediaPlaceholder text="Dodaj zdjęcie, aby wygenerować podgląd" />
          )}
          <Center
            position="absolute"
            left="-1px"
            top="50px"
            width="88px"
            height="88px"
            bgImage={logoPreview}
            bgSize="cover"
            bgRepeat="no-repeat"
            bgPosition="50% 50%"
            border={logoPreview ? 'none' : 'solid 1px #000'}
          >
            {!logoPreview && (
              <Text fontSize={13} textAlign="center" lineHeight={1.23}>
                Logo budynku
              </Text>
            )}
          </Center>
        </Center>
      </AspectRatio>
      <Box padding="24px 16px 32px 16px">
        <Text variant="h2" w="100%" mb={4} lineHeight={1.2}>
          {formValues?.name || 'tu pojawi się nazwa obiektu'}
        </Text>
        <Tabs
          width="100%"
          isFitted
          index={currentTabIndex}
          onChange={setCurrentTabIndex}
        >
          <TabList>
            {TAB_LIST.map((tab, index) => (
              <Tab key={`tab_${index}`}>{tab}</Tab>
            ))}
          </TabList>

          <TabPanels>
            <InformationsTab attraction={formValues} />
            <AttractionsTab
              venueId={formValues?.id}
              isActive={currentTabIndex === 1}
              pathwaysDescription={formValues?.pathwaysDescription}
            />
            <PlansTab
              venueId={formValues?.id}
              isActive={currentTabIndex === 2}
            />
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}

export default VenuePreview;
