import { Box, Button, Icon, Stack } from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  // IconDeviceMobile,
  IconPlayerPlayFilled,
  IconDeviceDesktop,
  // IconRoute,
  IconNews,
  IconBrandLaravel,
  IconCalendarEvent,
  IconConfetti,
  IconUsersGroup,
  IconPencil,
} from '@tabler/icons-react';
import ROUTES from 'app/routes';
import useQueryParams from 'utils/useQueryParams';

const NAV_OPTIONS: Array<NavOption> = [
  // {
  //   title: 'Splash screen',
  //   icon: IconDeviceMobile,
  //   link: ROUTES.splashScreens.base,
  // },
  {
    title: 'On-boarding',
    icon: IconPlayerPlayFilled,
    link: ROUTES.onboardScreens.base,
  },
  {
    title: 'Dashboard',
    icon: IconDeviceDesktop,
    link: ROUTES.dashboard.base,
  },
  // {
  //   title: 'Ścieżki zwiedzania',
  //   icon: IconRoute,
  //   link: ROUTES.pathways.base,
  // },
  {
    title: 'Aktualności',
    icon: IconNews,
    link: ROUTES.news.base,
  },
  {
    title: 'Obiekty',
    icon: IconBrandLaravel,
    link: ROUTES.venues.base,
  },
  {
    title: 'Atrakcje',
    icon: IconCalendarEvent,
    link: ROUTES.attractions.base,
  },
  {
    title: 'Imprezy',
    icon: IconConfetti,
    link: ROUTES.events.base,
  },
  {
    title: 'Użytkownicy',
    icon: IconUsersGroup,
    link: ROUTES.users.base,
  },
  {
    title: 'Kontent',
    icon: IconPencil,
    link: ROUTES.contentPages.base,
  },
];

interface NavigationProps {
  isSidebarExpanded: boolean;
}

function Navigation({ isSidebarExpanded }: NavigationProps) {
  const { queryString } = useQueryParams();
  const { pathname } = useLocation();

  return (
    <Stack spacing={0} h="100%" overflowY="auto">
      {NAV_OPTIONS.map((option, idx) => {
        const optionLink = option.link === pathname ? queryString : option.link;

        return (
          <Box
            key={`nav_option_${option.title}`}
            position="relative"
            borderTopWidth={idx === 0 ? 0 : '0.5px'}
          >
            <NavLink to={optionLink}>
              {({ isActive }) => (
                <Button
                  variant="link"
                  fontSize="17px"
                  fontWeight={isActive ? 600 : 'normal'}
                  w="100%"
                  px={4}
                  py={5}
                  color="#fff"
                  leftIcon={<Icon as={option.icon} fontSize={24} />}
                  justifyContent={isSidebarExpanded ? 'flex-start' : 'center'}
                  gap={2}
                  {...(isActive && {
                    _before: {
                      width: '4px',
                      height: '100%',
                      bg: '#f0bb00',
                      position: 'absolute',
                      left: 0,
                      content: `""`,
                    },
                  })}
                >
                  <Box hidden={!isSidebarExpanded}>{option.title}</Box>
                </Button>
              )}
            </NavLink>
          </Box>
        );
      })}
    </Stack>
  );
}

export default Navigation;
