import { Flex, Icon } from '@chakra-ui/react';
import { IconChevronsLeft } from '@tabler/icons-react';

interface ResizeButtonProps {
  onClick: () => void;
  isExpanded: boolean;
}

function ResizeButton({ isExpanded, onClick }: ResizeButtonProps) {
  return (
    <Flex
      as="button"
      display="flex"
      h="64px"
      flexShrink={0}
      borderTopWidth={0.5}
      align="center"
      justify="flex-end"
      transition="all 0.25s ease"
      onClick={onClick}
    >
      <Icon
        as={IconChevronsLeft}
        transform={isExpanded ? 'rotateZ(0deg)' : 'rotateZ(-180deg)'}
        transition="all 0.275s ease"
        marginX={4}
        color="#fff"
        fontSize={24}
      />
    </Flex>
  );
}

export default ResizeButton;
