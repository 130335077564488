import { VStack, HStack, Text, IconButton } from '@chakra-ui/react';
import {
  IconMapPin,
  IconClock,
  IconArrowNarrowRight,
} from '@tabler/icons-react';
import { timeRangePrettier } from 'utils/date';

interface AttractionFacilityCardProps {
  name: string;
  activeFrom: string;
  activeTo: string;
  venueName: string;
}

function AttractionFacilityCard({
  name,
  activeFrom,
  activeTo,
  venueName,
}: AttractionFacilityCardProps) {
  return (
    <VStack
      p={4}
      spacing={4}
      w="100%"
      border="solid 1px"
      borderColor="complementary.black"
      borderRadius="8px"
    >
      <Text w="100%" fontSize={17} fontWeight={600} lineHeight="24px">
        {name || 'Tutaj pojawi się nazwa atrakcji'}
      </Text>
      <HStack spacing={2} w="100%" align="end">
        <VStack spacing={2} w="100%">
          <HStack spacing={2} w="100%" color="complementary.previewGray">
            <IconClock />
            <Text fontSize={17} lineHeight={1.41} w="100%">
              {timeRangePrettier(activeFrom, activeTo) ||
                'Czas trwania atrakcji'}
            </Text>
          </HStack>
          <HStack
            spacing={2}
            w="100%"
            color="complementary.previewGray"
            align="start"
          >
            <IconMapPin />
            <Text fontSize={17} lineHeight={1.41} w="100%" noOfLines={2}>
              {venueName || 'Miejsce atrakcji'}
            </Text>
          </HStack>
        </VStack>
        <IconButton
          icon={<IconArrowNarrowRight />}
          aria-label="Link"
          variant="ghost"
          border="solid 1px"
          borderColor="complementary.black"
        />
      </HStack>
    </VStack>
  );
}

export default AttractionFacilityCard;
