import { ColumnDef } from '@tanstack/react-table';
import ImagePreview from 'components/ImagePreview';

function GetColumns(): ColumnDef<Plan, string>[] {
  return [
    {
      header: 'Id',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Piętro',
      accessorKey: 'position',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Id w Mapsted',
      accessorKey: 'poiId',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Plan',
      accessorKey: 'plan.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
