import styled from '@emotion/styled';
import { Text, VStack } from '@chakra-ui/react';

const SectionTitle = styled(Text)`
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
  margin-bottom: ${({ mb }) => mb || 8}px;
`;

const SectionSubtitle = styled(Text)`
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  margin-bottom: ${({ mb }) => mb || 8}px;
`;

interface SectionTextBoxProps {
  title: string;
  subtitle?: string;
}

function SectionTextBox({ title, subtitle }: SectionTextBoxProps) {
  return (
    <VStack
      spacing={2}
      bgColor="complementary.gray"
      px={2}
      py={4}
      borderRadius="4px"
    >
      <Text fontSize={20} lineHeight="24px" fontWeight={600} w="100%">
        {title}
      </Text>
      {subtitle && <SectionSubtitle mb={0}>{subtitle}</SectionSubtitle>}
    </VStack>
  );
}

SectionTextBox.defaultProps = {
  subtitle: '',
};

export { SectionTitle, SectionSubtitle, SectionTextBox };
