/* eslint-disable react/no-array-index-key */
import { Stack, HStack, Text, Box } from '@chakra-ui/react';
import { Fragment } from 'react';
import { isEmpty } from 'lodash';
import { HOURS_KIND } from 'components/Schedule/constants';
import { DAYS } from 'components/Schedule/ScheduleFieldset';

type HoursPreviewProps = {
  schedulesAttributes: Schedule[];
};

function TimeSlotsPreview({ schedulesAttributes }: HoursPreviewProps) {
  return (
    <Stack spacing={4}>
      {schedulesAttributes?.map((day, index) => (
        <Fragment key={`${day}_${index}`}>
          <Text fontSize={14} w="100%">
            {DAYS[index]}
          </Text>
          <HStack
            bg="#fbfbfb"
            w="fit-content"
            px={2}
            py={3}
            spacing={0}
            gap={3}
            borderRadius="4px"
            wrap="wrap"
          >
            {day?.closed ? (
              <Text fontWeight={600}>nieczynne</Text>
            ) : (
              day?.slots?.map((slot, slotIndex) => (
                <Text key={`${day}_${index}_${slotIndex}`} fontWeight={600}>
                  {slot || 'HH:MM'}
                </Text>
              ))
            )}
          </HStack>
        </Fragment>
      ))}
    </Stack>
  );
}

function TimeRangePreview({ schedulesAttributes }: HoursPreviewProps) {
  return (
    <Stack spacing={1}>
      {schedulesAttributes?.map((day, index) => {
        const hours = day.hours || 'HH:MM - HH:MM';
        return (
          <HStack justify="space-between" key={`${day}_${index}`} py={1}>
            <Text fontSize={14} w="100%">
              {DAYS[index]}
            </Text>
            <Text fontSize={14} w="100%">
              {day.closed ? 'Nieczynne' : hours}
            </Text>
          </HStack>
        );
      })}
    </Stack>
  );
}

type SchedulePreviewProps = {
  label: string;
  hoursKind: string;
  schedules?: Schedule[];
};

function SchedulePreview({
  label,
  hoursKind,
  schedules = [],
}: SchedulePreviewProps) {
  const Preview =
    hoursKind === HOURS_KIND.MULTIPLE_SLOTS
      ? TimeSlotsPreview
      : TimeRangePreview;

  return (
    <Box w="100%">
      <Text variant="h2" w="100%" mb={4}>
        {label}
      </Text>
      {isEmpty(schedules) ? (
        <Text w="100%" fontSize="17px" lineHeight="24px">
          Tu pojawią się godziny otwarcia
        </Text>
      ) : (
        <Preview schedulesAttributes={schedules} />
      )}
    </Box>
  );
}

SchedulePreview.defaultProps = {
  schedules: [],
};

export default SchedulePreview;
