import { flexRender, Row } from '@tanstack/react-table';
import { toast } from 'react-toastify';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { generatePath, Link } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import CustomModal from 'components/CustomModal';
import Td from 'pages/Venues/Plans/Listing/components/Table/components/Td';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';

interface TableRowProps {
  row: Row<Plan>;
  venueId: number;
}

function TableRow({ row, venueId }: TableRowProps) {
  const { id } = row.original;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const queryClient = useQueryClient();

  const { mutate: removeVenuePlan } = useMutation(
    () => API.delete(APIRoutes.venues.plans.byId(venueId, id)),
    {
      mutationKey: 'venuePlansMutation',
      onSuccess: () => {
        toast.success('Pomyślnie usunięto plan');
        queryClient.invalidateQueries('venuePlans');
      },
      onError: () => {
        toast.error('Wystąpił problem podczas usuwania planu');
      },
    },
  );

  return (
    <tr key={`row_${id}`}>
      {row.getVisibleCells().map((cell, idx, { length }) => {
        if (idx + 1 === length) return null;
        return (
          <Td key={`row_cell_${cell.id}_${cell}`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
      <Td>
        <>
          <CustomModal
            confirmationText="Usuń"
            onAccept={removeVenuePlan}
            cancellationText="Anuluj"
            onClose={onClose}
            isOpen={isOpen}
            title="Czy na pewno chcesz trwale usunąć ten plan?"
          />
          <Tooltip label="Edytuj" openDelay={1000}>
            <IconButton
              width="72px"
              height="56px"
              icon={<IconPencil size={20} />}
              aria-label="visiblity"
              variant="ghost"
              as={Link}
              to={generatePath(ROUTES.venues.plans.edit, {
                venueId,
                id: row.original.id,
              })}
            />
          </Tooltip>
          <Tooltip label="Usuń" openDelay={1000}>
            <IconButton
              width="72px"
              height="56px"
              icon={<IconTrash size={20} />}
              aria-label="visiblity"
              variant="ghost"
              onClick={onOpen}
            />
          </Tooltip>
        </>
      </Td>
    </tr>
  );
}

export default TableRow;
