import { Box, Stack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import Textarea from 'components/Form/Textarea';
import Input from 'components/Form/Input';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';

interface PushSectionProps {
  prepareFieldName: (name: string) => string;
}

function PushSection({ prepareFieldName }: PushSectionProps) {
  const { control, setValue } = useFormContext();
  const pushEnabled = useWatch({
    control,
    name: prepareFieldName('pushEnabled'),
  });

  useEffect(() => {
    if (!pushEnabled) {
      setValue(prepareFieldName('pushTitle'), null);
      setValue(prepareFieldName('pushContent'), null);
    }
  }, [prepareFieldName, pushEnabled, setValue]);

  return (
    <Box w="100%">
      <ToggleCheckbox
        label="Powiadomienia PUSH"
        name={prepareFieldName('pushEnabled')}
      />
      {pushEnabled && (
        <Stack spacing={8} pt={6}>
          <Input
            name={prepareFieldName('pushTitle')}
            label="Tytuł"
            placeholder="Wpisz tytuł"
          />
          <Textarea
            name={prepareFieldName('pushContent')}
            label="Treść"
            placeholder="Wpisz treść"
          />
        </Stack>
      )}
    </Box>
  );
}

export default PushSection;
