/* eslint-disable react/no-array-index-key */
import { VStack, HStack, Box } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { reject } from 'lodash';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import { SectionTitle } from 'components/Form/SectionText';
import ScheduleFieldset from 'components/Schedule/ScheduleFieldset';
import { HOURS_KIND } from 'components/Schedule/constants';

const HOURS_KINDS_OPTIONS = [
  {
    label: 'takie same jak godziny otwarcia obiektu',
    value: HOURS_KIND.OBJECT,
  },
  {
    label: 'zakres godzin',
    value: HOURS_KIND.RANGE,
  },
  {
    label: 'kilka slotów w ramach 1 dnia',
    value: HOURS_KIND.MULTIPLE_SLOTS,
  },
];

type HoursKindSectionProps = {
  prepareFieldName: (name: string) => string;
};

function HoursKindSection({ prepareFieldName }: HoursKindSectionProps) {
  const { control, setValue } = useFormContext();
  const [hoursKind, venueIds] = useWatch({
    control,
    name: [prepareFieldName('hoursKind'), prepareFieldName('venueIds')],
  });

  useEffect(() => {
    if (hoursKind === HOURS_KIND.OBJECT && venueIds?.length > 1) {
      setValue(prepareFieldName('hoursKind'), HOURS_KIND.RANGE);
    }
  }, [hoursKind, venueIds, prepareFieldName, setValue]);

  const options = useMemo(
    () =>
      venueIds?.length > 1
        ? reject(HOURS_KINDS_OPTIONS, { value: HOURS_KIND.OBJECT })
        : HOURS_KINDS_OPTIONS,
    [venueIds],
  );

  return (
    <Box>
      <SectionTitle as="legend">Godziny wydarzenia</SectionTitle>
      <VStack align="start" spacing={6}>
        <HStack spacing={0} gap={1} wrap="wrap">
          {options.map((kind) => (
            <RadioBoxControl
              id={`hours-kind-${kind.value}`}
              key={kind.value}
              label={kind.label}
              name={prepareFieldName('hoursKind')}
              value={kind.value}
            />
          ))}
        </HStack>
        {hoursKind !== HOURS_KIND.OBJECT && (
          <ScheduleFieldset
            prepareFieldName={prepareFieldName}
            hasTimeSlots={hoursKind === HOURS_KIND.MULTIPLE_SLOTS}
          />
        )}
      </VStack>
    </Box>
  );
}

export default HoursKindSection;
