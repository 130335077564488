/* eslint-disable react/no-array-index-key */
import {
  Box,
  AspectRatio,
  Center,
  VStack,
  Text,
  HStack,
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import {
  IconUserCircle,
  IconMapPin,
  IconCalendarEvent,
} from '@tabler/icons-react';
import { isNumber } from 'lodash';
import Previews from 'components/Content/Previews/constants';
import useImage from 'utils/useImage';
import MediaPlaceholder from 'components/MediaPlaceholder';
import { datePrettierNoTime } from 'utils/date';
import { useGetVenue } from 'api/venues';
import { NEWSABLE_TYPE } from 'pages/News/Form/constants';
import EventSlide from 'components/Content/Previews/CarouselPreview/EventSlide';
import { useGetEvent } from 'api/events';
import AttractionListItemCard from 'components/Cards/AttractionListItem';
import { useGetAttraction } from 'api/attractions';
import LoadingSpinner from 'components/FormSpinner';
import WYSIWYGContent from 'components/WYSIWYGContent';
import { PreviewComponentProps } from 'types/common';

function NewsPreview({ formValues }: PreviewComponentProps) {
  const imagePreview = useImage(formValues?.imagesAttributes?.[0]?.image);
  const { data: { data: venue } = {} } = useGetVenue(formValues?.venueId, true);
  const logoPreview = useImage(venue?.logo);
  const newsableType = formValues?.newsableType;
  const newsableId = formValues?.newsableId;
  const isValidNewsableId = isNumber(newsableId);

  const { isFetching: isFetchingEvent, data: { data: event } = {} } =
    useGetEvent(
      newsableId,
      newsableType === NEWSABLE_TYPE.EVENT && isValidNewsableId,
      true,
    );

  const { isFetching: isFetchingAttraction, data: { data: attraction } = {} } =
    useGetAttraction(
      newsableId,
      newsableType === NEWSABLE_TYPE.ATTRACTION && isValidNewsableId,
      true,
    );

  return (
    <Box>
      {(isFetchingEvent || isFetchingAttraction) && <LoadingSpinner />}
      <AspectRatio width="100%" ratio={1.37}>
        <Center
          bgColor="complementary.gray"
          bgImage={imagePreview}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition="50% 50%"
          position="relative"
        >
          {!imagePreview && (
            <MediaPlaceholder text="Dodaj zdjęcie, aby wygenerować podgląd" />
          )}
          <Center
            position="absolute"
            left="-1px"
            top="50px"
            width="88px"
            height="88px"
            bgImage={logoPreview}
            bgSize="cover"
            bgRepeat="no-repeat"
            bgPosition="50% 50%"
            border={logoPreview ? 'none' : 'solid 1px #000'}
          >
            {!logoPreview && (
              <Text fontSize={13} textAlign="center" lineHeight="16px">
                Logo budynku
              </Text>
            )}
          </Center>
        </Center>
      </AspectRatio>
      <Box padding="24px 16px 32px 16px">
        <Text variant="h2" w="100%" mb={4}>
          {formValues?.title || 'tu pojawi się tytuł'}
        </Text>
        <VStack spacing={6}>
          <VStack spacing={4} w="100%">
            <HStack spacing="12px" w="100%">
              <IconUserCircle />
              <Text fontSize={17} lineHeight="24px" w="100%">
                {formValues?.author || 'Imię i nazwisko autora tekstu'}
              </Text>
            </HStack>
            <HStack spacing="12px" w="100%">
              <IconMapPin />
              <Text fontSize={17} lineHeight="24px" w="100%">
                {venue?.name || 'Budynek powiązany (opcjonalnie)'}
              </Text>
            </HStack>
            <HStack spacing="12px" w="100%">
              <IconCalendarEvent />
              <Text fontSize={17} lineHeight="24px" w="100%">
                {formValues?.date
                  ? datePrettierNoTime(formValues?.date)
                  : 'Data publikacji (domyślnie: bieżąca)'}
              </Text>
            </HStack>
            <WYSIWYGContent content={formValues?.description} />
          </VStack>
          {formValues?.contentContainersAttributes?.map(
            (container: any, index: number) => {
              const PreviewComponent: FunctionComponent<PreviewComponentProps> =
                Previews[container.kind];
              if (PreviewComponent) {
                return (
                  <PreviewComponent
                    formValues={container}
                    key={`$container_${index}`}
                  />
                );
              }
              return null;
            },
          )}
          {newsableId && (
            <>
              <Text variant="h2" w="100%" mb={4}>
                Powiązane
              </Text>
              {newsableType === NEWSABLE_TYPE.EVENT && (
                <EventSlide element={event!} />
              )}
              {newsableType === NEWSABLE_TYPE.ATTRACTION && (
                <AttractionListItemCard
                  name={attraction?.name!}
                  image={attraction?.image!}
                  activeFrom={attraction?.activeFrom}
                  activeTo={attraction?.activeTo}
                  ticketsTeaser={attraction?.ticketsTeaser}
                />
              )}
            </>
          )}
        </VStack>
      </Box>
    </Box>
  );
}

export default NewsPreview;
