import { useIsMutating } from 'react-query';
import { Link } from 'react-router-dom';
import useQueryParams from 'utils/useQueryParams';
import ListingLayout from 'components/Layout/ListingLayout';
import ROUTES from 'app/routes';
import Table from 'pages/OnboardScreens/Listing/components/Table';
import Footer from 'components/Footer';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetOnboardScreens } from 'api/onboardScreens';

function OnboardScreens() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });

  const isMutating = !!useIsMutating({
    mutationKey: ['onboardScreensMutation'],
  });

  const {
    isFetching,
    data: { data: onboardScreens, totals } = { data: [], totals: 0 },
  } = useGetOnboardScreens(queryString);

  const showLoading = isMutating || isFetching;

  return (
    <ListingLayout
      title="On-boarding"
      headerButtons={[
        {
          label: 'NOWY EKRAN',
          as: Link,
          to: ROUTES.onboardScreens.create,
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={onboardScreens} />}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default OnboardScreens;
