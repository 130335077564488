import {
  Box,
  Button,
  ButtonProps,
  Flex,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { map } from 'lodash';
import { FOOTER_HEIGHT } from 'components/Footer';
import ListingTabs, { ListingTabsProps } from 'components/ListingTabs';
import Search from 'components/Search';

type ListingActionButton = ButtonProps & {
  label: string;
  to?: string;
};

interface ListingLayoutProps {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  headerButtons: ListingActionButton[];
  tabs?: ListingTabsProps;
  searchKey?: string;
}

function ListingLayout({
  title,
  subtitle,
  headerButtons,
  children,
  tabs,
  searchKey,
}: ListingLayoutProps) {
  return (
    <Box mb={FOOTER_HEIGHT}>
      <Flex justify="space-between" mb="32px" align="center">
        <Stack spacing={2}>
          <Text variant="h1" textTransform="uppercase">
            {title}
          </Text>
          {subtitle && (
            <Text fontSize="17px" lineHeight="24px">
              {subtitle}
            </Text>
          )}
        </Stack>
        <HStack spacing="8px">
          {map(headerButtons, ({ label, ...buttonProps }, idx) => (
            <Button key={`button-${label}-${idx}`} {...buttonProps}>
              {label}
            </Button>
          ))}
        </HStack>
      </Flex>
      <Stack spacing={4}>
        {tabs && <ListingTabs {...tabs} />}
        {searchKey && <Search searchKey={searchKey} />}
        {children}
      </Stack>
    </Box>
  );
}

ListingLayout.defaultProps = {
  subtitle: '',
  tabs: null,
  searchKey: '',
};

export default ListingLayout;
