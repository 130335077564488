import { useIsMutating } from 'react-query';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import useQueryParams from 'utils/useQueryParams';
import Table from 'pages/Venues/Plans/Listing/components/Table';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetVenue, useGetVenuePlans } from 'api/venues';
import HeaderNavigation from 'pages/Venues/Plans/Listing/components/HeaderNavigation';

function VenuePlans() {
  const { id = '' } = useParams();
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });

  const isMutating = !!useIsMutating({
    mutationKey: ['venuePlansMutation'],
  });

  const { data: { data: venue } = {}, isFetching: isFetchingVenue } =
    useGetVenue(+id);

  const {
    isFetching: isFetchingVenuePlans,
    data: { data: venuePlans, totals } = { data: [], totals: 0 },
  } = useGetVenuePlans(+id, queryString);

  const showLoading = isMutating || isFetchingVenue || isFetchingVenuePlans;

  return (
    <Box mb={FOOTER_HEIGHT}>
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <>
          <HeaderNavigation name={venue?.name!} id={id} />
          <Table data={venuePlans} venueId={+id} />
        </>
      )}
      <Footer totals={totals} footerButtons={null} />
    </Box>
  );
}

export default VenuePlans;
