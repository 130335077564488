import { useCallback, useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex } from '@chakra-ui/react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { serialize } from 'object-to-formdata';
import { concat, differenceBy } from 'lodash';
import HeaderNavigation from 'components/HeaderNavigation';
import { FORM_MAX_WIDTH } from 'utils/constants';
import FooterButtons, { FOOTER_HEIGHT } from 'components/FooterButtons';
import FormBody from 'pages/Attractions/Form/components/FormBody';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
  preparePayload,
} from 'pages/Attractions/Form/constants';
import ROUTES from 'app/routes';
import { API, APIRoutes } from 'api';
import transformErrors from 'utils/api';
import LoadingIndicator from 'components/LoadingIndicator';
import ComponentPreview from 'components/PreviewWrapper';
import { SERIALIZER_OPTIONS } from 'app/constants';
import {
  convertKeysToSnakeCase,
  transformPosition,
  transformRemoved,
} from 'utils/helpers';
import parseDefaultValues from 'pages/Attractions/Form/Edit/parseDefaultValues';
import DndWrapper from 'components/DndWrapper';
import ContentComponentsForm from 'components/Content/ContentComponentsForm';
import AddButton from 'components/AddButton';
import { useGetAttraction } from 'api/attractions';
import FormWrapper from 'components/FormWrapper';
import useQueryParams from 'utils/useQueryParams';

function AttractionEdit() {
  const { id = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { queryString } = useQueryParams();
  let debounce: ReturnType<typeof setTimeout>;

  const backPath = useMemo(
    () => ROUTES.attractions.base + queryString,
    [queryString],
  );

  const methods = useForm<Attraction>({
    mode: 'onChange',
    resolver: yupResolver(BODY_SCHEMA),
  });

  const { handleSubmit, setError, reset, control } = methods;

  const {
    fields: containers,
    append,
    remove,
    move,
  } = useFieldArray({
    control,
    name: 'contentContainersAttributes',
    keyName: 'fieldId',
    shouldUnregister: true,
  });

  const addContainerHandler = useCallback(() => {
    append({ kind: '' });
  }, [append]);

  const reorderContainers = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      clearTimeout(debounce);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      debounce = setTimeout(() => move(dragIndex, hoverIndex), 500);
    },
    [move],
  );

  const { data: { data: attraction } = {}, isFetching } = useGetAttraction(+id);

  const { mutate: editAttraction, isLoading: isEditing } = useMutation({
    mutationKey: 'attractionsMutation',
    mutationFn: (data: Attraction) => {
      const removedContainers = differenceBy(
        attraction?.contentContainers,
        data?.contentContainersAttributes,
        'id',
      );

      data.contentContainersAttributes = concat(
        ...transformPosition(data?.contentContainersAttributes),
        ...transformRemoved(removedContainers),
      );

      const snakePayload = convertKeysToSnakeCase(preparePayload(data));
      const formData = serialize(
        snakePayload,
        SERIALIZER_OPTIONS,
        new FormData(),
        'attraction',
      );
      return API.put(APIRoutes.attractions.byId(+id), formData);
    },
    onSuccess: () => {
      toast.success('Zmiany w atrakcji zostały zastosowane');
      navigate(backPath);
      queryClient.invalidateQueries('attractions');
    },
    onError: ({ errors }) => {
      const transformedErrors = transformErrors(errors, null);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      toast.error('Wystąpił problem podczas edycji atrakcji');
    },
  });

  const onSubmit = handleSubmit(async (data: Attraction) =>
    editAttraction(data),
  );

  useEffect(() => {
    if (attraction) {
      reset({
        ...DEFAULT_VALUES[attraction?.kind],
        ...parseDefaultValues(attraction),
      });
    }
  }, [attraction, reset]);

  const isLoading = isFetching || isEditing;

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: 'Atrakcje',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj atrakcję' }]}
      />
      {isLoading && <LoadingIndicator />}
      <FormWrapper {...methods}>
        <Flex gap={4} mb={4} alignItems="flex-start" pb={FOOTER_HEIGHT}>
          <Box
            w="100%"
            as="form"
            id="attraction-editor"
            onSubmit={onSubmit}
            maxW={FORM_MAX_WIDTH}
          >
            <FormBody isLoading={isLoading} />
            {containers.map((container, index) => (
              <DndWrapper
                key={`attraction-editor-${container.fieldId}`}
                id={container.fieldId}
                isLoading={isLoading}
                index={index}
                reorderContainers={reorderContainers}
                removeContainer={remove}
                ContainerWrapper={ContentComponentsForm}
                multipleContainers
              />
            ))}
            <AddButton addHandler={addContainerHandler} mt={2} />
          </Box>
          <ComponentPreview kind="attraction" isEditMode />
        </Flex>
      </FormWrapper>
      <FooterButtons
        isLoading={isLoading}
        formId="attraction-editor"
        onCancel={() => navigate(-2)}
      />
    </Box>
  );
}

export default AttractionEdit;
