import { useIsMutating } from 'react-query';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Table from 'pages/Events/Pathways/Listing/components/Table';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import LoadingIndicator from 'components/LoadingIndicator';
import HeaderNavigation from 'pages/Events/Pathways/Listing/components/HeaderNavigation';
import useQueryParams from 'utils/useQueryParams';
import { useGetEvent, useGetEventPathways } from 'api/events';

function EventPathways() {
  const { id = '' } = useParams();
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });

  const isMutating = !!useIsMutating({
    mutationKey: ['eventPathwaysMutation'],
  });

  const { data: { data: event } = {}, isFetching: isFetchingEvent } =
    useGetEvent(+id);

  const {
    isFetching: isFetchingPathways,
    data: { data: pathways, totals } = { data: [], totals: 0 },
  } = useGetEventPathways(+id, queryString);

  const showLoading = isMutating || isFetchingEvent || isFetchingPathways;

  return (
    <Box mb={FOOTER_HEIGHT}>
      <HeaderNavigation name={event?.name!} id={id} />
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={pathways} eventId={+id} />}
      <Footer totals={totals} footerButtons={null} />
    </Box>
  );
}

export default EventPathways;
