import * as yup from 'yup';
import { ERROR_MESSAGES } from 'utils/form';

export const BODY_SCHEMA = yup.object({
  title: yup
    .string()
    .trim()
    .required(ERROR_MESSAGES.required)
    .validateMaxStringLength(),
  description: yup.string().trim().required(ERROR_MESSAGES.required),
  image: yup.mixed().nullable().required(ERROR_MESSAGES.required),
});

export const DEFAULT_VALUES = {
  title: '',
  description: '',
  image: null,
};
