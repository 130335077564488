import { Box, BoxProps, Center, Flex, Icon, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { IconSearch } from '@tabler/icons-react';

const Tr = styled.tr`
  border-color: black;
  border-inline-width: 1px;
  border-bottom-width: 1px;
`;

type EmptyBodyProps = BoxProps & {
  columnAmount: number;
};

function EmptyBody({ columnAmount, ...props }: EmptyBodyProps) {
  return (
    <Tr>
      <td colSpan={columnAmount}>
        <Box {...props}>
          <Center h="100%" p={6}>
            <Flex direction="column" align="center">
              <Icon as={IconSearch} mb={6} fontSize={24} />
              <Text fontSize="14px" fontWeight={600} mb={4}>
                Brak danych
              </Text>
              <Text>Wciśnij przycisk powyżej, aby utworzyć nowy komponent</Text>
            </Flex>
          </Center>
        </Box>
      </td>
    </Tr>
  );
}

export default EmptyBody;
