import { ColumnDef } from '@tanstack/react-table';
import { dateRangePrettier } from 'utils/date';

function GetColumns(): ColumnDef<AttractionFacility, string>[] {
  return [
    {
      header: 'Id',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Nazwa wydarzenia',
      accessorKey: 'attraction.name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Data wydarzenia',
      cell: ({
        row: {
          original: {
            attraction: { activeFrom, activeTo },
          },
        },
      }) => dateRangePrettier(activeFrom?.toString(), activeTo?.toString()),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
