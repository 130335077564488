/* eslint-disable react/no-array-index-key */
import 'swiper/css';
import { Box, Text } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { isEmpty } from 'lodash';
import AttractionCard from 'components/Cards/AttractionCard';
import Button from 'components/Previews/Button';
import EmptySection from 'components/EmptySection';

interface AttractionsCarouselProps {
  label: string;
  attractions: Attraction[];
}

function AttractionsCarousel({ label, attractions }: AttractionsCarouselProps) {
  return (
    <Box w="100%">
      <Text w="100%" variant="h2" mb={4}>
        {label}
      </Text>
      {isEmpty(attractions) ? (
        <EmptySection text={`Tu pojawią się wybrane ${label.toLowerCase()}`} />
      ) : (
        <>
          <Box width="100%" mb={6}>
            <Swiper slidesPerView="auto" spaceBetween={16}>
              {attractions?.map((attraction, index) => (
                <SwiperSlide
                  key={`image_${index}`}
                  style={{
                    width: '280px',
                    height: 'auto',
                  }}
                >
                  <AttractionCard {...attraction} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          <Button label="ZOBACZ WSZYSTKIE" />
        </>
      )}
    </Box>
  );
}

export default AttractionsCarousel;
