import { get } from 'lodash';
import ImageGallery from 'components/Content/Previews/ImageGallery';

function ImageGalleryPreview({
  formValues,
  fieldName = 'imagesAttributes',
  hideHeader = false,
}: any) {
  const slides = get(formValues, fieldName);

  return (
    <ImageGallery
      header={hideHeader ? null : formValues?.galleryHeader}
      slides={slides}
    />
  );
}

export default ImageGalleryPreview;
