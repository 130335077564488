/* eslint-disable react-hooks/exhaustive-deps, react/no-array-index-key */
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  AspectRatio,
  Center,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
} from '@chakra-ui/react';
import qs from 'query-string';
import { debounce, isEmpty } from 'lodash';
import DetailsTab from 'components/Previews/EventPreview/DetailsTab';
import useImage from 'utils/useImage';
import MediaPlaceholder from 'components/MediaPlaceholder';
import InformationsTab from 'components/Previews/EventPreview/InformationsTab';
import AttractionFacilitiesTab from 'components/Previews/EventPreview/AttractionFacilitiesTab';
import { useGetVenues } from 'api/venues';
import LoadingSpinner from 'components/FormSpinner';
import { PreviewComponentProps } from 'types/common';

const TAB_LIST = ['INFORMACJE', 'ZOBACZ', 'AGENDA'];

function EventPreview({ formValues }: PreviewComponentProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [venueIds, setVenueIds] = useState<number[]>([]);
  const imagePreview = useImage(formValues?.imagesAttributes?.[0]?.image);
  const eventId = formValues?.id;

  const updateVenueIds = useCallback(
    debounce((ids) => {
      setVenueIds(ids);
    }, 700),
    [],
  );

  useEffect(() => {
    updateVenueIds(formValues?.venueIds);
  }, [formValues?.venueIds, updateVenueIds]);

  const queryString = useMemo(
    () =>
      qs.stringify(
        {
          page_size: 'all',
          'q[id_in]': venueIds,
        },
        { arrayFormat: 'bracket' },
      ),
    [venueIds],
  );

  const {
    isFetching: isFetchingVenues,
    data: { data: venues } = { data: [] },
  } = useGetVenues(`?${queryString}`, !isEmpty(venueIds));

  return (
    <Box>
      {isFetchingVenues && <LoadingSpinner />}
      <AspectRatio width="100%" ratio={1.37}>
        <Center
          bgColor="complementary.gray"
          bgImage={imagePreview}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition="50% 50%"
          position="relative"
        >
          {!imagePreview && (
            <MediaPlaceholder text="Dodaj zdjęcie, aby wygenerować podgląd" />
          )}
        </Center>
      </AspectRatio>
      <Box padding="24px 16px 32px 16px">
        <Text variant="h2" w="100%" mb={4}>
          {formValues?.name || 'tu pojawi się nazwa imprezy'}
        </Text>
        <Tabs
          width="100%"
          isFitted
          index={currentIndex}
          onChange={setCurrentIndex}
        >
          <TabList>
            {TAB_LIST.map((tab, index) => (
              <Tab key={`tab_${index}`}>{tab}</Tab>
            ))}
          </TabList>

          <TabPanels>
            <InformationsTab formValues={formValues} venues={venues} />
            <DetailsTab
              eventId={eventId}
              formValues={formValues}
              isActive={currentIndex === 1}
            />
            <AttractionFacilitiesTab
              eventId={eventId}
              isActive={currentIndex === 2}
              venues={venues}
            />
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}

export default EventPreview;
