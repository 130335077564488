import {
  Box,
  Flex,
  HStack,
  IconButton,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import Checkbox from 'components/Form/Checkbox';
import MaskedInput from 'components/Form/MaskedInput';

export const DAYS = [
  'Poniedziałek',
  'Wtorek',
  'Środa',
  'Czwartek',
  'Piątek',
  'Sobota',
  'Niedziela',
];

interface ScheduleFieldProps {
  label: string;
  index: number;
  prepareFieldName: (name: string) => string;
}

function TimeField({ label, index, prepareFieldName }: ScheduleFieldProps) {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();
  const parseFieldName = useCallback(
    (name: string) => prepareFieldName(`schedulesAttributes[${index}].${name}`),
    [index, prepareFieldName],
  );

  const isClosed = useWatch({
    control,
    name: parseFieldName('closed'),
  });

  useEffect(() => {
    if (!isEmpty(errors)) {
      trigger(parseFieldName('hours'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClosed]);

  return (
    <Box>
      <HStack spacing={8}>
        <Box w="100%">
          <MaskedInput
            name={parseFieldName('hours')}
            placeholder="HH:MM - HH:MM"
            isDisabled={isClosed}
            format="##:## - ##:##"
            label={label}
          />
        </Box>
        <Box>
          <Checkbox label="Nieczynne" name={parseFieldName('closed')} />
        </Box>
      </HStack>
    </Box>
  );
}

function TimeSlotField({ label, index, prepareFieldName }: ScheduleFieldProps) {
  const parseFieldName = useCallback(
    (name: string) => prepareFieldName(`schedulesAttributes[${index}].${name}`),
    [index, prepareFieldName],
  );

  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  const {
    fields: slots,
    append,
    remove,
  } = useFieldArray({
    control,
    name: parseFieldName('slots'),
    keyName: 'fieldId',
    shouldUnregister: true,
  });

  const isClosed = useWatch({
    control,
    name: parseFieldName('closed'),
  });

  useEffect(() => {
    if (isEmpty(slots)) {
      append('');
    }
  }, [slots, append]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      trigger(parseFieldName('slots'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClosed]);

  return (
    <Box>
      <Text mb={1}>{label}</Text>
      <Flex align="center" gap={4} wrap="wrap">
        {slots.map((slot, slotIndex) => (
          <InputGroup key={slot.fieldId} minWidth="120px" width="120px">
            <MaskedInput
              name={parseFieldName(`slots.[${slotIndex}]`)}
              placeholder="HH:MM"
              isDisabled={isClosed}
              format="##:##"
            />
            {slotIndex > 0 && (
              <InputRightElement>
                <IconButton
                  icon={<IconTrash />}
                  variant="ghost"
                  aria-label="Remove slot"
                  onClick={() => remove(slotIndex)}
                  isDisabled={isClosed}
                  isRound
                />
              </InputRightElement>
            )}
          </InputGroup>
        ))}
        <IconButton
          icon={<IconPlus />}
          variant="solidSecondary"
          aria-label="Add slot"
          onClick={() => append('')}
          isDisabled={isClosed}
          isRound
        />
        <Box>
          <Checkbox label="Nieczynne" name={parseFieldName('closed')} />
        </Box>
      </Flex>
    </Box>
  );
}

interface ScheduleFieldsetProps {
  prepareFieldName: (name: string) => string;
  hasTimeSlots?: boolean;
}

function ScheduleFieldset({
  prepareFieldName,
  hasTimeSlots,
}: ScheduleFieldsetProps) {
  const Fieldset = hasTimeSlots ? TimeSlotField : TimeField;
  return (
    <Stack spacing={8}>
      {DAYS.map((day, index) => {
        return (
          <Fieldset
            key={day}
            label={day}
            index={index}
            prepareFieldName={prepareFieldName}
          />
        );
      })}
    </Stack>
  );
}

ScheduleFieldset.defaultProps = {
  hasTimeSlots: false,
};

export default ScheduleFieldset;
