import { useQuery } from 'react-query';
import { API, APIRoutes, DEFAULT_STALE_TIME, EXTENDED_STALE_TIME } from 'api';
import { OptionType } from 'types/common';

const fetchAttractions = async (queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.attractions.index(queryString),
  );
  return {
    data: response?.data,
    totals: response?.meta?.pagination?.objects,
  };
};

const fetchAttractionsList = async (queryString: string) => {
  const {
    data: { data: attractions },
  } = await API.get(APIRoutes.attractions.index(`?${queryString}`));

  return attractions?.map((attraction: Attraction) => ({
    label: attraction.name,
    value: attraction.id,
  }));
};

const fetchAttraction = async (id: number) => {
  const { data } = await API.get(APIRoutes.attractions.byId(id));
  return data;
};

const useGetAttractions = (queryString: string, enabled = true) =>
  useQuery<AttractionsResponse>(
    ['attractions', queryString],
    () => fetchAttractions(queryString),
    {
      staleTime: DEFAULT_STALE_TIME,
      enabled,
    },
  );

const useGetAttraction = (id: number, enabled = true, isPreview = false) =>
  useQuery<AttractionResponse>(
    ['attraction', id, isPreview],
    () => fetchAttraction(id),
    {
      enabled,
      staleTime: isPreview ? EXTENDED_STALE_TIME : 0,
    },
  );

const useGetAttractionsList = (queryString: string, enabled = true) =>
  useQuery<OptionType[]>(
    ['attractionsList', queryString],
    () => fetchAttractionsList(queryString),
    {
      staleTime: EXTENDED_STALE_TIME,
      enabled,
    },
  );

export { useGetAttractions, useGetAttraction, useGetAttractionsList };
