/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import qs from 'query-string';
import { API, APIRoutes, EXTENDED_STALE_TIME } from 'api';
import { ContentComponentResponse } from 'types/contentComponent';

const fetchContentComponents = async (isDashboard: boolean) => {
  const queryString = qs.stringify({
    'q[placement_eq]': isDashboard ? 'dashboard' : 'common',
  });
  const { data: contentComponents } = await API.get(
    APIRoutes.contentComponents(queryString),
  );
  return contentComponents;
};

const useGetContentComponents = (isDashboard: boolean) =>
  useQuery<ContentComponentResponse>(
    ['contentComponents', isDashboard],
    () => fetchContentComponents(isDashboard),
    {
      staleTime: EXTENDED_STALE_TIME,
    },
  );

export { useGetContentComponents };
